// tslint:disable:max-line-length
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const PHONE_REGEX = /^(\(?[0-9]{2}\)? ?[0-9]{4}-?[0-9]{4})|(\(?[0-9]{2}\)? ?[0-9]{5}-?[0-9]{4})$/i
const CPF_REGEX = /^([0-9]{3}\.[0-9]{3}\.[0-9]{3}\-[0-9]{2})$/i
const ZIP_CODE_REGEX = /^\d{5}[-]\d{3}$/
const CNPJ_REGEX = /^([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})$/i
const DATE_REGEX = /^((0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/](19|20)[0-9]{2})*$/

// At least 1 char and 1 number regex: https://stackoverflow.com/a/7684859/3670829
// Security recommendation: Failing to enforce passwords of at least 7 characters, a complexity
// of at least alpha and numeric characters increases the risk of a brute force attack.
export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9])$/

export function isEmailValid(email): boolean {
  return EMAIL_REGEX.test(email)
}

export function isPhoneValid(phone): boolean {
  return PHONE_REGEX.test(phone)
}

export function isDateValid(date): boolean {
  if (date instanceof Date) {
    return true
  }
  return DATE_REGEX.test(date)
}

export function isPasswordValid(password): boolean {
  return PASSWORD_REGEX.test(password)
}

// src: https://www.devmedia.com.br/validar-cpf-com-javascript/23916
export function isCpfValid(str) {
  if (str !== undefined && str !== null && str.trim().length === 0) {
    return true
  }

  if (str === undefined) {
    return true
  }

  const onlyNumbers = str.replace(/\D/g, '')
  let Soma
  let Resto
  Soma = 0
  if (onlyNumbers === '00000000000') {
    return false
  }

  for (let i = 1; i <= 9; i++) {
    Soma = Soma + parseInt(onlyNumbers.substring(i - 1, i), 10) * (11 - i)
  }
  Resto = (Soma * 10) % 11

  if (Resto === 10 || Resto === 11) {
    Resto = 0
  }
  if (Resto !== parseInt(onlyNumbers.substring(9, 10), 10)) {
    return false
  }

  Soma = 0
  for (let i = 1; i <= 10; i++) {
    Soma = Soma + parseInt(onlyNumbers.substring(i - 1, i), 10) * (12 - i)
  }
  Resto = (Soma * 10) % 11

  if (Resto === 10 || Resto === 11) {
    Resto = 0
  }
  if (Resto !== parseInt(onlyNumbers.substring(10, 11), 10)) {
    return false
  }
  return true
}

export function isZipCodeValid(value): boolean {
  return ZIP_CODE_REGEX.test(value)
}

export function isCpfPatternValid(value): boolean {
  return CPF_REGEX.test(value)
}

export function isCnpjPatternValid(value): boolean {
  return CNPJ_REGEX.test(value)
}
