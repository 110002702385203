import styled, { keyframes } from 'styled-components'
import { TransitionDuration } from 'atomic/legacy/obj.constants/constants'
import { isNullOrUndefined } from 'util'

export interface FadeProps {
  show: boolean
  duration?: number
}

/**
 * This fade animates only when `opacity` changes
 */
export const Fade = styled.div<FadeProps>`
  border: 0 solid transparent;
  pointer-events: ${(props) => (props.show ? 'inherit' : 'none')};
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity ${(props) => (isNullOrUndefined(props.duration) ? TransitionDuration : props.duration)};

  &.searchTablet{
    width:90%;

    svg{
      color: ${props => props.theme.color.primary};
    }
  }
`

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

interface ForcedFadeProps {
  show: boolean
  duration?: string
}

/**
 * This fade will happen everytime, which is different from `Fade` (component) which animates only when `opacity` changes
 */
export const ForcedFade = styled.div<ForcedFadeProps>`
  border: 0;
  pointer-events: ${(props) => (props.show ? 'inherit' : 'none')};
  animation: ${(props) => (props.show ? fadeIn : fadeOut)}
    ${(props) => props.duration || TransitionDuration} linear forwards;
`
