import { appPaths, getPathUrl } from 'utils/path'

export function insertOtherLinksOnAbout(
         relatedPages: { title: string; url: string; target?: string }[],
         isDoctorPage: boolean
       ) {
         relatedPages.push(
           {
             url: getPathUrl(appPaths.faq.path, isDoctorPage) as string,
             title: 'Perguntas Frequentes'
           },
           {
             url: 'http://www.grupofleury.com.br/SitePages/Home.aspx',
             title: 'Grupo Fleury'
           },
           {
             url: 'https://trabalheconosco.vagas.com.br/grupo-fleury',
             //url: 'https://career8.successfactors.com/career?company=FLEURY',
             title: 'Trabalhe Conosco'
           },
           {
             url: 'https://maisservicos.fmattoso.com.br/ouvidoria',
             title: 'Ouvidoria',
             target: '_self'
           }
         )
       }
