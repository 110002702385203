import { Border, Color, mobileGutterStyle, Spacing } from 'atomic'
import ColorFunc from 'color'
import { ReactStyledFlexboxgrid } from 'react-styled-flexboxgrid'
import styled, { css } from 'styled-components'

export const backgroundWithFoldColStyle = css`
  background: ${Color.GrayXDark};
  height: 100%;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;

    /** https://css-tricks.com/the-shapes-of-css/ */
    width: 0;
    height: 0;
    border-bottom: 2rem solid transparent;
    border-left: 2rem solid ${Color.White};
  }
`

export const ColorBackground = styled.div`
  background-color: ${props => props.color};
`

export const WhiteBackground = styled.div`
  background-color: ${Color.White};
`

export const BlackBackground = styled.div`
  background-color: ${Color.Black};
`

export const NeutralBackground = styled.div`
  background-color: ${Color.Neutral};
`
export const GrayLightBackground = styled.div`
  background-color: ${Color.GrayLight};
`

export const GrayXDarkBackground = styled.div`
  background-color: ${Color.GrayXDark};
`

export const PrimaryBackgroundRound = styled.div`
  padding: ${Spacing.Large};
  background-color: ${props => props.theme.color.primary};
  border-radius: ${Border.Radius};
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
`

export const GrayBackgroundRound = styled.div`
  padding: ${Spacing.Large};
  background-color: ${Color.GrayXDark};
  border-radius: ${Border.Radius};
  overflow: hidden;
  height: 100%;
`

export const LightGrayBackground = styled.div`
  padding: ${Spacing.Large};
  background-color: ${Color.GrayXLight};
  border-radius: ${Border.Radius};
  overflow: hidden;
  height: 100%;
`

export const LightPinkBackground = styled.div`
  padding: ${Spacing.Large};
  background-color: ${props =>
    ColorFunc(props.theme.color.primary)
      .alpha(0.1)
      .hsl()
      .string()};
  border-radius: ${Border.Radius};
  overflow: hidden;
  height: 100%;
`

export const BackgroundWithFoldStyled = styled.div<ReactStyledFlexboxgrid.IColProps>`
  position: relative;
  width: 100%;
  top: 0;
  left: 0;

  ${mobileGutterStyle}

  ${backgroundWithFoldColStyle}
`

export const BackgroundWithFoldHalfWidthStyled = styled.div`
  position: absolute;
  width: 50vw;
  top: 0;
  left: 50%;

  ${backgroundWithFoldColStyle}

  display: none;
  @media all and (min-width: 48em) {
    display: block;
  }
`
