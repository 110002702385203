// tslint:disable:max-line-length
import * as React from 'react'
import { useTimestamp } from '../../obj.custom-hooks/timestamp.hook'
// tslint:enable:max-line-length

interface EventTimestampContainerProps {
  children: (props: EventTimestampContainerChildrenProps) => JSX.Element
}
interface EventTimestampContainerChildrenProps {
  timestamp?: number
  setTimestamp: () => void
}

export const EventTimestampContainer: React.FunctionComponent<EventTimestampContainerProps> = props => {
  const [timestamp, setTimestamp] = useTimestamp() as [number, () => void]
  return props.children({
    timestamp,
    setTimestamp
  })
}
