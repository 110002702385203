import React from 'react'

const SvgIcChat = props => (
  <svg viewBox="0 0 24 20" {...props}>
    <defs>
      <path
        d="M19 16v3.066a.5.5 0 01-.777.416L13 16H2a2 2 0 01-2-2V2a2 2 0 012-2h20a2 2 0 012 2v12a2 2 0 01-2 2h-3zm-1.5 0a1.5 1.5 0 011.5-1.5h3a.5.5 0 00.5-.5V2a.5.5 0 00-.5-.5H2a.5.5 0 00-.5.5v12a.5.5 0 00.5.5h11a1.5 1.5 0 01.832.252l3.668 2.445V16zM5.829 6.75c-.32 0-.579-.336-.579-.75s.259-.75.579-.75H18.17c.32 0 .579.336.579.75s-.259.75-.579.75H5.83zm-.03 4c-.303 0-.549-.336-.549-.75s.246-.75.55-.75h7.4c.304 0 .55.336.55.75s-.246.75-.55.75H5.8z"
        id="ic-chat_svg__a"
      />
    </defs>
    <g fillRule="evenodd">
      <mask id="ic-chat_svg__b" fill="#fff">
        <use xlinkHref="#ic-chat_svg__a" />
      </mask>
      <use fill={props.fill} fillRule="nonzero" xlinkHref="#ic-chat_svg__a" />
    </g>
  </svg>
)

export default SvgIcChat
