import { Spacing } from 'atomic/legacy/obj.constants'
import ColorFunc from 'color'
import styled from 'styled-components'

export interface IconLinkTextCellStyledProps {
  /** color to be used on :hover and :active */
  actionColor: string
}

export const IconLinkTextCellStyled = styled.div`
  transition: all 0.2s ease-in-out;
  margin-top: ${Spacing.Small};
  height: 18.5px;
  svg {
    fill: ${(props: IconLinkTextCellStyledProps) => props.actionColor};
  }

  &:hover {
    color: ${(props: IconLinkTextCellStyledProps) => props.actionColor};

    transition: all 0.2s ease-in-out;
    svg {
      transition: all 0.2s ease-in-out;
      fill: ${(props: IconLinkTextCellStyledProps) =>
        ColorFunc(props.actionColor)
          .darken(0.2)
          .hsl()
          .string()};
      color: ${(props: IconLinkTextCellStyledProps) =>
        ColorFunc(props.actionColor)
          .darken(0.2)
          .hsl()
          .string()};
    }
  }

  &:active {
    color: ${(props: IconLinkTextCellStyledProps) => props.actionColor};
    svg {
      fill: ${(props: IconLinkTextCellStyledProps) =>
        ColorFunc(props.actionColor)
          .darken(0.4)
          .hsl()
          .string()};
      color: ${(props: IconLinkTextCellStyledProps) =>
        ColorFunc(props.actionColor)
          .darken(0.4)
          .hsl()
          .string()};
    }
  }
`
export const IconLinkTextCellStyledWeinmann = styled.div`
  transition: all 0.2s ease-in-out;
  height: 18.5px;
  svg {
    fill: ${(props: IconLinkTextCellStyledProps) => props.actionColor};
  }

  &:hover {
    color: ${(props: IconLinkTextCellStyledProps) => props.actionColor};

    transition: all 0.2s ease-in-out;
    svg {
      transition: all 0.2s ease-in-out;
      fill: ${(props: IconLinkTextCellStyledProps) =>
        ColorFunc(props.actionColor)
          .darken(0.2)
          .hsl()
          .string()};
      color: ${(props: IconLinkTextCellStyledProps) =>
        ColorFunc(props.actionColor)
          .darken(0.2)
          .hsl()
          .string()};
    }
  }

  &:active {
    color: ${(props: IconLinkTextCellStyledProps) => props.actionColor};
    svg {
      fill: ${(props: IconLinkTextCellStyledProps) =>
        ColorFunc(props.actionColor)
          .darken(0.4)
          .hsl()
          .string()};
      color: ${(props: IconLinkTextCellStyledProps) =>
        ColorFunc(props.actionColor)
          .darken(0.4)
          .hsl()
          .string()};
    }
  }
`