import styled from 'styled-components'
import { Spacing, Color } from 'atomic'

export const NewHeaderTabletContainer = styled.div`
  display: none;
  height: 64px;
  width: 100%;
  position: fixed;
  z-index: 100;
  background: white;
  @media (min-width: 767px) {
    display: flex;
  }

  @media (min-width: 1023px) {
    display: none;
  }
`
export const HeaderTablet = styled.div`
  height: 64px;
  width: 100%;

  p {
    font-weight: unset;
    margin-right: 8px;
  }

  .MuiGrid-container {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0;

    .LogoClientContainer {
      display: flex;
      align-items: center;
      height: 100%;
      background: ${props => props.theme.color.primary};
      padding: ${Spacing.Large};
    }

    .LogoDoctorContainer {
      display: flex;
      align-items: center;
      height: 100%;
      background: ${props => props.theme.color.tertiary};
      padding: ${Spacing.Large};
    }
  }
`

export const ButtonExamResults = styled.div`
  button {
    min-width: 154px;
    min-height: 47px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
  }

  &.doctorStyled {
    a {
      width: 90%;
      background: ${props => props.theme.color.tertiary};
      min-width: 154px;
      min-height: 47px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
    }
  }
`
export const SearchContainerTablet = styled.div`
  background: #e6f3f5;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 64px;
  z-index: 90;
  width: 100%;
  padding: 8px 0;

  #form-search-everything {
    display: flex;
    flex-direction: row-reverse;
    padding: 0 22px;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    background-color: white;
    border: 1px solid #77787b;
    border-radius: 8px;

    input {
      color: #77787b;
      padding: 0;
      border: none;

      &:hover {
        box-shadow: unset;
        border: none;
      }
      ::placeholder {
        color: #77787b;
      }
    }

    svg {
      position: relative;
      width: 100%;
      display: flex;
      top: unset;
      left: unset;
      color: ${props => props.theme.color.tertiary};
    }
  }
`
export const SegmentContainer = styled.div`
  button {
    text-transform: uppercase;
  }
`
export const NewHamburguerButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  span {
    height: auto;
  }

  .hamburguerInner {
    &,
    &:before,
    &:after {
      display: block;
      position: absolute;
      width: 18px;
      height: 2px;
      border-radius: 4px;
      background-color: ${Color.GrayDark};
    }
  }
`
export const DesktopTopHeaderLogoDoctorStyled = styled.div`
  background: ${props => props.theme.color.tertiary};
  height:100%;
  & > svg {
    overflow: visible;
    max-width: 20vw;
  }
`

export const DesktopTopHeaderLogoClientStyled = styled.div`
  background: ${props => props.theme.color.primary};
  height:100%;
  & > svg {
    overflow: visible;
    max-width: 20vw;
  }
`
