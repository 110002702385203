// tslint:disable:max-line-length
import * as React from 'react'
// tslint:enable:max-line-length

interface BooleanContainerStateProps {
  defaultValue?: boolean
  children: (props: BooleanContainerChildrenProps) => JSX.Element
}

interface BooleanContainerState {
  value?: boolean
}

export interface BooleanContainerChildrenProps {
  value?: boolean
  setTrue: () => void
  setFalse: () => void
  toggle: () => void
  setValue: (value) => void
}

export class BooleanContainer extends React.Component<BooleanContainerStateProps, BooleanContainerState> {
  constructor(props: any) {
    super(props)
    this.state = { value: this.props.defaultValue }
  }

  render() {
    return this.props.children({
      value: this.state.value,
      setTrue: this.setTrue,
      setFalse: this.setFalse,
      setValue: this.setValue,
      toggle: this.toggle
    })
  }

  private setValue = (value: boolean) => {
    this.setState({ value })
  }

  private toggle = () => {
    this.setValue(!this.state.value)
  }

  private setTrue = () => {
    this.setValue(true)
  }

  private setFalse = () => {
    this.setValue(false)
  }
}
