import { TextField, TextFieldProps } from 'atomic/legacy/atm.text-field'
import React from 'react'

export const SearchField = (props: TextFieldProps) => {
  return (
    <TextField
      id="text-field-search-field"
      aria-label="busca"
      placeholder="Busque em todo o site"
      {...props}
      icon={'search'}
    />
  )
}
