import { ElasticSearchIndex } from '../utils/elastic-search-index'

export interface GlobalAutocompleteResponse {
  results: {
    title: string
    slug: string
    /** exam, unit etc */
    name: string
    id: string
    index: ElasticSearchIndex
  }[]
  suggestions: string[]
}
export interface Suggestion {
  title: string
  subtitle: string
  to: string
}

export interface GlobalElasticSearchAutocompleteResponse {
  data: Data
}

export const mapToGlobalSearchResponse = (input: GlobalElasticSearchAutocompleteResponse) => {
  return {
    results: input.data.hits.hits.map(hit => ({
      title: (hit.highlight && hit.highlight.title[0]) || hit._source.title || hit._source.name,
      slug: hit._source.slug ? hit._source.slug : hit._source.id,
      index: hit._index,
      id: hit._id
    })),
    suggestions:
      input.data.suggest &&
      input.data.suggest.suggestion &&
      input.data.suggest.suggestion.length &&
      input.data.suggest.suggestion[0].options.map(option => option.text)
  } as GlobalAutocompleteResponse
}

//////////////////////////////////////////////////

//////////////////////////////////////////////////
// generated using: http://json2ts.com/
interface Data {
  took: number
  timed_out: boolean
  _shards: Shards
  hits: Hits
  suggest: Suggest
}

interface Shards {
  total: number
  successful: number
  skipped: number
  failed: number
}

interface Source {
  type_slug: string
  title: string
  slug: string
  id: string
  searchableTerms: string[]
  name: string
}

interface Hit {
  _index: ElasticSearchIndex
  _type: string
  _id: string
  _score: number
  _source: Source
  highlight: {
    title: string[]
  }
}

interface Hits {
  total: number
  max_score: number
  hits: Hit[]
}

interface Option {
  text: string
  score: number
  freq: number
}

interface MySuggestion {
  text: string
  offset: number
  length: number
  options: Option[]
}

interface Suggest {
  suggestion: MySuggestion[]
}
