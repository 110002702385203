import { BooleanContainer } from 'atomic/legacy/obj.abstract-container'
import React from 'react'
import { Header } from '../legacy/org.header'
import { ContactUsModal } from '../mol.contact-us-modal/contact-us-modal.component'
import { ClientDoctorSelect } from 'utils/model/client-doctor-select'
import { DesktopBottomHeader } from './components/desktop-bottom-header.component'
import { DesktopTopHeader } from './components/desktop-top-header.component'
import { DoctorMenuContentRows } from './components/doctor-menu-content-rows.component'
import { getDoctorMenuItems } from './components/menu-items'
import { MobileHeader } from './components/mobile-header.component'
export interface DoctorHeaderProps {
  showScheduleButton?: boolean
}

export const DoctorHeader: React.FunctionComponent<DoctorHeaderProps> = React.memo(props => {
  const area = ClientDoctorSelect.doctor
  return (
    <Header>
      <DesktopTopHeader area={area} showScheduleButton={props.showScheduleButton} />
      <BooleanContainer>
        {contactModal => (
          <>
            <DesktopBottomHeader {...getDoctorMenuItems(contactModal.setTrue)} />
            <ContactUsModal
              open={contactModal.value}
              onClose={contactModal.setFalse}
              isDoctor={true}
            />
          </>
        )}
      </BooleanContainer>
      <MobileHeader area={area} showScheduleButton={props.showScheduleButton}>
        <DoctorMenuContentRows />
      </MobileHeader>
    </Header>
  )
})
