import sanitizeRegex from '@root/src/utils/sanitize-regex-elastic'
import { FetchDataSourceInput } from '../../fetch.datasource'
import { getIndexes } from './brand-indexes'

export const buildGlobalElasticAutocompleteRequest = (search: string) => {
  const request: FetchDataSourceInput = {
    method: 'post',
    url: `${process.env.GATSBY_FLEURY_BASE_URL}/elasticsearch/digital/free-search`,
    data: buildGlobalElasticAutocompleteRequestData(search)
  }

  const requestEmpty: FetchDataSourceInput = {
    method: 'post',
    url: ``,
    data: {}
  }

  return search === '' ? requestEmpty : request
}

/**
 * `search as you type`: https://www.elastic.co/guide/en/elasticsearch/reference/7.x/search-as-you-type.html
 * `suggester`: https://www.elastic.co/guide/en/elasticsearch/reference/current/search-suggesters-completion.html
 * `highlight`: https://www.elastic.co/guide/en/elasticsearch/reference/6.7/search-request-highlighting.html
 */
const buildGlobalElasticAutocompleteRequestData = (search: string) => {
  if (!search) {
    return null
  }

  return {
    search: {
      _source: ['title', 'id', 'slug', 'type_slug', 'thumbnail', 'name'],
      query: {
        multi_match: {
          query: `${search?.replace(sanitizeRegex, '')}`,
          type: 'phrase_prefix'
        }
      },
      suggest: {
        text: search?.replace(sanitizeRegex, ''),
        suggestion: {
          term: {
            field: 'title'
          }
        }
      },
      highlight: {
        fields: {
          title: {}
        },
        pre_tags: ['<strong>'],
        post_tags: ['</strong>'],
        boundary_scanner_locale: 'pt-BR'
      },
      size: 5
    },
    index: getIndexes()
  }
}
