import { Query } from '@root/src/data/graphql/graphql-types'
import { parseDateToDDMMYYYY, parseToDate } from '@root/src/utils/date-parser/date-parser'
import { Col, Grid, Row, Separator } from 'atomic'
import SvgFooterBackground from 'atomic/atm.svg-icon/footer-background.component'
import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'
import LargeSeparatorRow from '../../../../atomic/atm.large-separator-row/large-separator-row.component'
import BottomTextRow from './components/bottom-text-row.component'
import { FooterContentRow } from './components/footer-content-row.component'
import GoToTopButton from './components/go-to-top-button.component'
import iconWhatsapp from '../../assets/img/iconWhatsappNew.png'
import {
  BackgroundWrapper,
  ContentWrapperStyled,
  FooterLink,
  FooterStyled,
  FooterInformation,
  WhatsAppFloatButton,
  
} from './footer.component.style'

export interface FooterProps {
  isDoctor: boolean
}

export const Footer: React.FunctionComponent<FooterProps> = React.memo(props => {
  return (    
    <FooterStyled className={`${process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() === 'weinmann' ? 'weinmann' : ''}`} id={'test-footer'}>
      <BackgroundWrapper>
        <SvgFooterBackground height="400px" />
      </BackgroundWrapper>
      <ContentWrapperStyled>
        <Grid>
          <Col>
            <Row center="xs" mb>
            {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() === 'weinmann' ? (<></>) : (
            <>
              <Col>
                <GoToTopButton />
              </Col>
            </>
            )}
              <Separator />
            </Row>
            <Separator />
            <FooterContentRow isDoctor={props.isDoctor} />
            <LargeSeparatorRow />
            <StaticQuery
              query={graphql`
                {
                  site {
                    siteMetadata {
                      buildDate
                      brandName
                    }
                  }

                  cosmicjsInformacoesEstaticas(slug: { eq: "footer" }) {
                    metadata {
                      responsavel_tecnico
                      botao_flutuante_whatsapp
                      politicas {
                        titulo
                        arquivo {
                          url
                        }
                      }
                    }
                  }
                }
              `}
              render={(data: Query) => {
                const date = parseToDate(data.site.siteMetadata.buildDate)
                const whatsAppLink = data.cosmicjsInformacoesEstaticas.metadata?.botao_flutuante_whatsapp
                return (
                  <>
                  { whatsAppLink.length > 1 && !props.isDoctor &&
                    <WhatsAppFloatButton >
                      <a
                        id="whatsapp-float-button"
                        href={whatsAppLink}
                        rel="noopener noreferrer"
                        role="button"
                        target="_blank"
                      >
                        {/* <SvgIconWhatsApp/> */}
                        <img src={iconWhatsapp} alt='Logo Whatsapp'/>
                      </a>
                    </WhatsAppFloatButton>  }
                  <BottomTextRow>
                    <FooterInformation>
                      <span>Fleury S.A. | CNPJ: 60.840.055/0001-31</span>
                      <span>Av. Santo Amaro, 4584, CEP 04701-200 - São Paulo, SP</span>
                      {data.cosmicjsInformacoesEstaticas.metadata?.responsavel_tecnico &&
                        <span>Responsável técnico: {data.cosmicjsInformacoesEstaticas.metadata?.responsavel_tecnico} </span>
                      }
                    </FooterInformation>
                    {`© ${date.getFullYear()} ${
                      data.site.siteMetadata.brandName
                    } - Todos os Direitos Reservados | `}
                    {data.cosmicjsInformacoesEstaticas.metadata.politicas.map((item, index) => {
                      return (
                        <>
                          <FooterLink
                            id={`footer-link-file-${index}`}
                            target="_blank"
                            href={item.arquivo.url}
                          >
                            {item.titulo}
                          </FooterLink>
                          {' | '}
                        </>
                      )
                    })}
                    {date && `Atualizado em ${parseDateToDDMMYYYY(date)}`}
                  </BottomTextRow>
                  </>
                )
              }}
            />
          </Col>
        </Grid>
      </ContentWrapperStyled>
    </FooterStyled>
  )
})
