import React from 'react'

const SvgCloseWindow = props => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.6001 7.59961L18.4001 18.3996" stroke="#757575" stroke-width="2" stroke-linecap="round" />
    <path d="M18.4001 7.59961L7.6001 18.3996" stroke="#757575" stroke-width="2" stroke-linecap="round" />
  </svg>
)

export default SvgCloseWindow
