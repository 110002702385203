import { Body } from 'atomic'
import { Link } from 'gatsby'
import * as React from 'react'
import { SearchCellStyled, SearchCellSubtitleStyled } from './search-cell.component.style'

export interface SearchCellProps {
  /** search word: used to make part of the title BOLD */
  search: string
  title: string
  subtitle?: string
  to: string
}

export const SearchCell: React.FunctionComponent<SearchCellProps> = React.memo(props => {
  return (
    <Link to={props.to}>
      <SearchCellStyled>
        <Body>
          <div dangerouslySetInnerHTML={{ __html: props.title }} />
          <SearchCellSubtitleStyled>{props.subtitle}</SearchCellSubtitleStyled>
        </Body>
      </SearchCellStyled>
    </Link>
  )
})
