import React from 'react'

const SvgExamesAgendados = props => (
  <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_459_1686)">
      <path d="M25.2471 14.625C25.2471 14.3266 25.3656 14.0405 25.5766 13.8295C25.7876 13.6185 26.0737 13.5 26.3721 13.5H28.6221C28.9204 13.5 29.2066 13.6185 29.4176 13.8295C29.6285 14.0405 29.7471 14.3266 29.7471 14.625V16.875C29.7471 17.1734 29.6285 17.4595 29.4176 17.6705C29.2066 17.8815 28.9204 18 28.6221 18H26.3721C26.0737 18 25.7876 17.8815 25.5766 17.6705C25.3656 17.4595 25.2471 17.1734 25.2471 16.875V14.625Z" fill="#047380" />
      <path d="M8.37207 0C8.67044 0 8.95659 0.118526 9.16757 0.329505C9.37854 0.540483 9.49707 0.826631 9.49707 1.125V2.25H27.4971V1.125C27.4971 0.826631 27.6156 0.540483 27.8266 0.329505C28.0376 0.118526 28.3237 0 28.6221 0C28.9204 0 29.2066 0.118526 29.4176 0.329505C29.6285 0.540483 29.7471 0.826631 29.7471 1.125V2.25H31.9971C33.1905 2.25 34.3351 2.72411 35.1791 3.56802C36.023 4.41193 36.4971 5.55653 36.4971 6.75V31.5C36.4971 32.6935 36.023 33.8381 35.1791 34.682C34.3351 35.5259 33.1905 36 31.9971 36H4.99707C3.8036 36 2.659 35.5259 1.81509 34.682C0.971176 33.8381 0.49707 32.6935 0.49707 31.5V6.75C0.49707 5.55653 0.971176 4.41193 1.81509 3.56802C2.659 2.72411 3.8036 2.25 4.99707 2.25H7.24707V1.125C7.24707 0.826631 7.3656 0.540483 7.57658 0.329505C7.78755 0.118526 8.0737 0 8.37207 0V0ZM2.74707 9V31.5C2.74707 32.0967 2.98412 32.669 3.40608 33.091C3.82804 33.5129 4.40033 33.75 4.99707 33.75H31.9971C32.5938 33.75 33.1661 33.5129 33.5881 33.091C34.01 32.669 34.2471 32.0967 34.2471 31.5V9H2.74707Z" fill="#047380" />
    </g>
    <defs>
      <clipPath id="clip0_459_1686">
        <rect width="36" height="36" fill="white" transform="translate(0.49707)" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgExamesAgendados
