import VisitCountDataSource from '@root/src/data/visit-count.datasource'
import { Body, Button, FaIcon, Hbox, Row, SemiBold } from 'atomic'
import { HamburgerButton } from 'atomic/legacy/atm.hamburger-button'
import { BooleanContainer } from 'atomic/legacy/obj.abstract-container'
import { ForcedFade } from 'atomic/legacy/obj.animation/animation.component.style'
import { useBooleanState } from 'atomic/obj.boolean-state/boolean-state.hook'
import { useDraw } from 'atomic/obj.custom-hooks/draw.hook'
import { RelativeWrapper } from 'atomic/obj.wrappers'
import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import Observer from 'react-intersection-observer'
import { Drawer } from '../../atm.drawer'
import SvgIcMobileLogo from 'atomic/atm.svg-icon/ic-mobile-logo.component'
import SvgMobileWhiteLogo from 'atomic/atm.svg-icon/ic-mobile-white-logo.component'
import { FlashMessage } from '../../legacy/mol.flash-message'
import { Header } from '../../legacy/org.header'
import { SchedulingModal } from '../../mol.scheduling-modal/scheduling-modal.component'
import { DesktopAutocomplete } from './autocomplete.component'
import { getHomePathForClientDoctorSelect } from './client-doctor-segmented-control.component'
import { ClientDoctorSelect } from 'utils/model/client-doctor-select'
import {
  DrawerWrapperSyled,
  FixedSearchWrapperStyled,
  FlashMessageWrapperStyled,
  SearchWrapperStyled,
  DrawerLogoWrapperSyled,
  ButtonContainer
} from './mobile-header.component.style'
import { SearchEverythingField } from './search-everything-field.component'
import { SearchContainer } from './search.container'
import { MySchedules } from '../../mySchedules/MySchedules'
import { useMediaQuery, useTheme } from '@material-ui/core'

interface MobileHeaderProps {
  area: ClientDoctorSelect
  showScheduleButton: boolean

  /** send the drawer content here */
  children: JSX.Element
}

export const MobileHeader: React.FunctionComponent<MobileHeaderProps> = props => {
  const { value, setFalse, setTrue } = useBooleanState(false)
  const draw = useDraw()
  const [visitCount, setVisitCount] = useState(0)  
  const theme = useTheme()

  const downOfMd = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    setVisitCount(VisitCountDataSource.visitCount)
  }, [])
  
  let handleFlashClick
  return (
    <Observer>
      {({ inView, ref }) => (
        <>
          <Header.Mobile style={{ height: 100 }}>

            <Hbox>
              <BooleanContainer>
                {bool => {
                  /** due to performance improvement, handleFlashClick is set this way */
                  handleFlashClick = bool.setTrue
                  return (
                    <>
                      <Hbox.Separator />
                      <Hbox.Item vAlign="center">
                        <HamburgerButton active={false} onClick={bool.setTrue} />
                      </Hbox.Item>
                      <Drawer active={bool.value} area={props.area}>
                        <DrawerTemplateContent onCloseClick={bool.setFalse} area={props.area}>
                          {props.children}
                        </DrawerTemplateContent>
                      </Drawer>
                    </>
                  )
                }}
              </BooleanContainer>
              <Hbox.Separator />
              <Hbox.Separator />
              <Hbox.Separator />
              <Hbox.Item>
                <Link
                  id="logo-mobile"
                  to={getHomePathForClientDoctorSelect(props.area)}
                  aria-label="home"
                >
                  <DrawerLogoWrapperSyled>
                    <SvgIcMobileLogo height={32} />
                  </DrawerLogoWrapperSyled>
                </Link>
              </Hbox.Item>
            </Hbox>
            <BooleanContainer>
              {schedulingBool => (
                <>
                  <Hbox>
                    <Hbox.Item vAlign="center">
                      <ForcedFade show={true} key={inView + String(value)}>
                        {draw && !inView && !value && (
                          <Button id="button-mobile-header-icon" kind="link" onClick={setTrue}>
                            <FaIcon.Search size={'1x'} />
                          </Button>
                        )}
                      </ForcedFade>
                    </Hbox.Item>
                    {props.showScheduleButton && props.area === ClientDoctorSelect.client && (
                      <>
                        <Hbox.Separator />
                        <Hbox.Item vAlign="center">
                          <Button
                            id="button-mobile-header-scheduling"
                            kind="primary"
                            onClick={schedulingBool.setTrue}
                            small
                          >
                            <ButtonContainer>
                              Agendar
                            </ButtonContainer>
                          </Button>
                        </Hbox.Item>
                        <Hbox.Separator />
                      </>
                    )}
                  </Hbox>
                  <SchedulingModal open={schedulingBool.value} onClose={schedulingBool.setFalse} />
                </>
              )}
            </BooleanContainer>
          </Header.Mobile>

          {(downOfMd && process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'fleury' || 
            downOfMd && process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'felippe-mattoso' ) && (
            <MySchedules />
          )}

          <SearchContainer isDoctor={props.area === ClientDoctorSelect.doctor}>
            {(searchProps, searchState) => (
              <RelativeWrapper>
                {value && (
                  <FixedSearchWrapperStyled area={props.area}>
                    <ForcedFade show={value}>
                      <SearchEverythingField
                        id="mobile-fix"
                        value={searchState.searchValue}
                        onValueChange={searchProps.handleTextChange}
                        onSubmit={searchProps.handleSubmit}
                        onFocus={searchProps.handleFocus}
                        autoFocus={value}
                        onBlur={setFalse}
                        light
                      />
                    </ForcedFade>
                  </FixedSearchWrapperStyled>
                )}
                <SearchWrapperStyled ref={ref} area={props.area}>
                  <SearchEverythingField
                    id="mobile"
                    value={searchState.searchValue}
                    onValueChange={searchProps.handleTextChange}
                    onSubmit={searchProps.handleSubmit}
                    onFocus={setTrue}
                    light
                  />
                </SearchWrapperStyled>
                <DesktopAutocomplete
                  search={searchState.searchValue}
                  opened={searchState.autocompleteOpen}
                  onCellClick={searchProps.handleSubmit}
                  onOverlayClick={searchProps.handleOverlayClick}
                  suggestions={searchProps.suggestions}
                />
              </RelativeWrapper>
            )}
          </SearchContainer>

          {visitCount === 1 && props.area === ClientDoctorSelect.doctor && (
            <FlashMessageWrapperStyled onClick={handleFlashClick}>
              <FlashMessage type={'info-secondary'} dismissible={true}>
                <Body>
                  <SemiBold>Cliente?</SemiBold> Clique aqui para trocar de perfil.
                </Body>
              </FlashMessage>
            </FlashMessageWrapperStyled>
          )}

          {visitCount === 1 && props.area === ClientDoctorSelect.client && (
            <FlashMessageWrapperStyled onClick={handleFlashClick}>
              <FlashMessage type={'info'} dismissible={true}>
                <Body>
                  <SemiBold>Médico?</SemiBold> Clique aqui para trocar de perfil.
                </Body>
              </FlashMessage>
            </FlashMessageWrapperStyled>
          )}
        </>
      )}
    </Observer>
  )
}

////////////////////////////////////////////////////////////////////////////////////////////////////
interface DrawerTemplateContentProps {
  onCloseClick: () => void
  area: ClientDoctorSelect
}

/**
 * send a list of `Row` as a children
 */
const DrawerTemplateContent: React.FunctionComponent<DrawerTemplateContentProps> = React.memo(
  props => {
    return (
      <>
        <Hbox>
          <Hbox.Item wrap vAlign="center">
            <HamburgerButton active={true} onClick={props.onCloseClick} />
          </Hbox.Item>
          <Hbox.Separator />
          <Hbox.Separator />
          <Hbox.Separator />
          <Hbox.Item wrap vAlign="center">
            <Link
              id="logo-mobile-menu"
              to={getHomePathForClientDoctorSelect(props.area)}
              aria-label="home"
            >
              <DrawerLogoWrapperSyled>
                <SvgMobileWhiteLogo height={32} />
              </DrawerLogoWrapperSyled>
            </Link>
          </Hbox.Item>
        </Hbox>

        <DrawerWrapperSyled>{props.children}</DrawerWrapperSyled>
      </>
    )
  }
)
