import { isNullOrUndefined } from 'util'

export const getTimeString = () => {
  return new Date().getTime().toString()
}

/** Expected input:
 * YYYY-MM-DD HH:MM (ex: 2018-10-05 13:45 )
 * YYYY-MM-DD (ex: 2018-10-05 )
 * YYYY-MM-DDTHH:MM:SS.Z (ex: 2018-11-07T18:08:54.526Z )
 * DD-MM-YYYY HH:MM (ex: 05-10-2018 )
 * DD/MM/YYYY (ex: 05/10/2018)
 */
export const parseToDate = (stringDate: string): Date => {
  if (isNullOrUndefined(stringDate) || stringDate.length === 0) {
    return null
  }
  let date

  const normalizedDate = stringDate.replace(new RegExp('-', 'g'), '/')
  const dateComponents = normalizedDate.split('/')
  if (dateComponents && dateComponents[0].length !== 4) {
    date = parseDDMMYYYYToDate(stringDate)
  }
  if (!isValidDate(date)) {
    date = new Date(normalizedDate)
  }
  if (!isValidDate(date)) {
    date = new Date(stringDate)
  }

  return date
}

/** Expected input:
 * DD-MM-YYYY HH:MM (ex: 05-10-2018 )
 * DD/MM/YYYY (ex: 05/10/2018)
 * DD/MM/YY (ex: 05/10/18)
 */
export const parseDDMMYYYYToDate = (stringDate: string) => {
  if (isNullOrUndefined(stringDate) || stringDate.length === 0) {
    return null
  }

  const normalizedDate = stringDate.replace(new RegExp('-', 'g'), '/')
  const dateComponents = normalizedDate.split('/')

  if (
    dateComponents.length < 3 ||
    parseInt(dateComponents[0], 0) > 31 ||
    parseInt(dateComponents[1], 0) > 12
  ) {
    return null
  }

  if (dateComponents[2].length === 2) {
    const yearPreffix = new Date()
      .getFullYear()
      .toString()
      .slice(0, 2)
    dateComponents[2] = `${yearPreffix}${dateComponents[2]}`
  }
  if (dateComponents[2].length !== 4) {
    return null
  }

  return parseToDate(`${dateComponents[2]}/${dateComponents[1]}/${dateComponents[0]}`)
}

const isValidDate = d => {
  return d instanceof Date && !isNaN(d as any)
}

const twoDigit = num => `0${num}`.slice(-2)
/** parse date to string in the format dd de month de yyyy */
export const parseDateToString = (date: Date) => {
  const day = twoDigit(date.getDate())
  const month = twoDigit(date.getMonth() + 1)

  return `${day} de ${monthToString(parseInt(month, 10))}  de ${date.getFullYear()}`
}
/** parse date to string in the format dd/mm/yyyy */
export const parseDateToDDMMYYYY = (date: Date) => {
  const day = twoDigit(date.getDate())
  const month = twoDigit(date.getMonth() + 1)
  const year = date.getFullYear()

  return `${day}/${month}/${year}`
}

export const compareMMYYYYDesc = (aYear: number, bYear: number, aMonth: number, bMonth: number) => {
  if (aYear > bYear) {
    return -1
  }
  if (aYear === bYear) {
    if (aMonth > bMonth) {
      return -1
    }
    return 1
  }
  return 1
}

export const monthToString = (month: number | string) => {
  switch (typeof month === 'string' ? parseInt(month, 10) : month) {
    case 1:
      return 'Janeiro'
    case 2:
      return 'Fevereiro'
    case 3:
      return 'Março'
    case 4:
      return 'Abril'
    case 5:
      return 'Maio'
    case 6:
      return 'Junho'
    case 7:
      return 'Julho'
    case 8:
      return 'Agosto'
    case 9:
      return 'Setembro'
    case 10:
      return 'Outubro'
    case 11:
      return 'Novembro'
    case 12:
      return 'Dezembro'

    default:
      return 'Mês Inválido'
  }
}
