import React from 'react';

const SvgIcWatch = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg" {...props}
  >
    <path
      d="M12 21.6C17.28 21.6 21.6 17.28 21.6 12C21.6 6.72 17.28 2.4 12 2.4C6.72 2.4 2.4 6.72 2.4 12C2.4 17.28 6.72 21.6 12 21.6ZM12 0C18.6 0 24 5.4 24 12C24 18.6 18.6 24 12 24C5.4 24 0 18.6 0 12C0 5.4 5.4 0 12 0ZM15.96 17.04L14.4 18L10.8 11.76V6H12.6V11.28L15.96 17.04Z"
      fill="#047380"
    />
  </svg>
);

export default SvgIcWatch;
