import { Col, H1, Row, Separator } from 'atomic'
import * as React from 'react'
import { Breadcrumb } from '../atm.breadcrumb/breadcrumb.component'
import Title from '../Title'
interface TitleWithBreadcrumbRowProps {
  title?: string
  addtionalDictionary?: { [id: string]: string }
}

const TitleWithBreadcrumbRow: React.FunctionComponent<TitleWithBreadcrumbRowProps> = props => {
  return (
    <>
      <Row>
        <Col xs={12}>
          <Separator />
          <Breadcrumb addtionalDictionary={props.addtionalDictionary} />
        </Col>
      </Row>
      <Row mb mt>
        <Col xs={12} md={7}>
          {process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'weinmann' && props.title && (
            <Title variant="h5">{props.title}</Title>
          )}

          {process.env.GATSBY_COSMIC_BUCKET.toLowerCase() !== 'weinmann' && 
            <H1>{props.title}</H1>}
          {props.children}
        </Col>
      </Row>
    </>
  )
}

export default TitleWithBreadcrumbRow
