import { Color, FontSize, FontWeight, Spacing } from 'atomic/legacy/obj.constants'
import styled from 'styled-components'
import { SegmentedKind, SegmentedProps } from './segmented.component'

export const SegmentedStyled = styled.span`
  display: block;
  position: relative;
  & ~ * {
    margin-top: ${Spacing.Medium};
  }
`

interface SegmentedItemStyledProps {
  selected?: boolean;
  kind: SegmentedKind;
}

export const SegmentedItemStyled = styled.button<SegmentedItemStyledProps>`
  background: none;
  position: relative;
  color: ${(props) =>
    props.kind === 'light' ? Color.White : props.selected ? props.theme.color.primary : Color.GrayDark};
  border-color: ${Color.Gray};
  border-style: solid;
  border-right-width: 0;
  border-left-width: 0;
  border-top-width: 0;
  border-bottom-width: 1px;  
  width: auto;
  min-height: 32px;
  padding: ${Spacing.Medium} ${Spacing.Small} ${'12px'} ${Spacing.Small};
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${(props) => (props.selected ? FontWeight.Bold : FontWeight.Normal)};
  font-size: ${FontSize.XSmall};
  cursor: pointer;

/*::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: -2px;
    left: 0;
    opacity: ${(props) => (props.selected ? 1 : 0)};
    border-color: ${(props) => (props.kind === 'light' ? Color.White : props.theme.color.primary)};
    border-style: solid;
    border-right-width: 0;
    border-left-width: 0;
    border-top-width: 0;
    border-bottom-width: 3px;
    ${(props: SegmentedProps) => (props.animated ? `transition: all 0.3s ease-in-out 0.1s` : '')};
  } */

  ::before {
    position: absolute;
    opacity: ${(props) => (props.selected ? 1 : 0)};
    left: 50%;
    bottom: 3px;
    transform: translateX(-50%);
    display: block;
    border: 4px solid transparent;
    border-bottom-color: ${(props) => (props.theme.color.primary)};
    content: '';
  }

  ::after {
    position: absolute;
    opacity: ${(props) => (props.selected ? 1 : 0)};
    bottom: -1px;
    left: 0;
    display: block;
    width: 100%;
    height: 4px;
    background-color: ${(props) => (props.theme.color.primary)};
    content: '';
  }

  &:focus {
    outline: 0;
  }
`
