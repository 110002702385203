import React from 'react'

const SvgIcVacinas = props => (  
  <svg 
    width="22" 
    height="22" 
    viewBox="0 0 22 22" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    >
  <path d="M11 4.61L8.24 7.35L11.55 10.66L10.49 11.72L7.18 8.41L5.41 10.18L8.72 13.49L7.66 14.55L4.35 11.24L2.35 13.24C2.03219 13.5478 1.82544 13.9524 1.7621 14.3903C1.69877 14.8282 1.78243 15.2747 2 15.66L3 17.55L0.75 19.84L2.16 21.25L4.45 19L6.34 20C6.64481 20.1728 6.98965 20.2624 7.34 20.26C7.60391 20.2602 7.86526 20.2082 8.10897 20.1069C8.35268 20.0057 8.57394 19.8572 8.76 19.67L17.39 11L11 4.61ZM18.8 8.2L17.01 6.4L18.43 4.99L19.84 6.4L21.25 4.99L17.01 0.75L15.6 2.16L17.01 3.58L15.6 4.99L13.8 3.2L12.06 1.45L10.65 2.87L11.68 3.9V3.91L18.09 10.32H18.1L19.13 11.35L20.55 9.94L18.81 8.2H18.8Z"
   //fill="#047380"
   />
  </svg>
  

)

export default SvgIcVacinas
