// tslint:disable

import styled from 'styled-components'
import * as TextInputMask from 'react-masked-text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  fieldBorderCss,
  fieldCss,
  fieldHeight,
  fieldBorder,
  fieldPadding
} from 'atomic/legacy/obj.form/field.component.styled'
import { InputPlaceholderCss } from 'atomic/legacy/atm.typography'
import { TextFieldProps } from './text-field.component'
import { Color, Spacing } from 'atomic/legacy/obj.constants'

const FIELD_WITH_ICON_LEFT_PADDING = 'calc(' + Spacing.Medium + ' * 3)'
const ICON_HEIGHT = '18px'

export const TextFieldStyled = styled.input<TextFieldProps>`
  ${fieldBorderCss}
  ${fieldCss}
  height: 48px;
  color: ${props => (props.light ? Color.White : Color.GrayXDark)};
  ${props => (props.icon ? 'padding-left: ' + FIELD_WITH_ICON_LEFT_PADDING : null)};

  /* https://css-tricks.com/webkit-html5-search-inputs/ */
  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
    width: 14px;
    height: 10px;
    background-image: ${props =>
      props.light
        ? `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAJFJREFUOBGtU0EKwCAMk73Bd+z/F1+0R3TJUOgkrcJWKHU2SdeAxcwqsiHPshnEdk4t/YBiF3IpQkzHolijgL9IRUJs2HArLTEZIOu5GUWus00eSoJAXxipP4P/VIC9sSn5eDH/+Jim87f3VxBkruLXiX3IgFlPmSanhCJhQxgqsbjkS2TIybMOcN6T5zF9es43OjLXKFdybbEAAAAASUVORK5CYII=')`
        : `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAOJJREFUOBGdk8ERgjAQRTcrBwqgDovQIqzDK6ecuFqHRWgR1kEBHBD0kYlDQgKMOQRm97+/m4UYa23Vdf1dtbg2jX3JjlXX9jgM/a0si4sCjzKe3kP/ILHFo0EL4wp/K4uYVmSstkw8jBaGrg0V48RBi3N8nJxmMtgyycFwP4OcCXGO5tuOuwsMUibEcjCZhQHBsOVJ1saVibLUPf7fFx2E1fm8LPfZUl0EBjEMAL5riCnY/wtruamDNQEdsHIak0s4LNxTWuVWrQ1pbsGR3Fzc3YFVrqQR80xNeA77d28CA/sB1IvvTN6ddPsAAAAASUVORK5CYII=')`};
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: top left;
  }

  ::placeholder {
    ${InputPlaceholderCss}
    opacity: 0.5;
  }
`

const DismissButtonSize = 16

export const TextFieldDismissButtonStyled = styled.div`
  position: absolute;
  top: ${Spacing.Large};
  right: ${Spacing.Small};
  width: ${DismissButtonSize}px;
  height: ${DismissButtonSize}px;
  border-radius: ${DismissButtonSize / 2}px;
  background-color: ${Color.Gray};
  text-align: center;

  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 10px;
    top: 50%;
    left: 3px;
    background: ${Color.White};
  }
  &:before {
    transform: rotate(45deg);
    margin-top: -1px;
  }
  &::after {
    transform: rotate(-45deg);
    margin-top: -1px;
  }
`

export const TextFieldWrapperStyled = styled.div`
  position: relative;
`

export const TextFieldMaskedStyled: TextInputMask = TextFieldStyled.withComponent(TextInputMask)

export const TextFieldIconWrapperStyled = styled.div`
  position: relative;
`

export const TextFieldIconStyled = styled(FontAwesomeIcon).attrs((props: { icon }) => ({
  icon: props.icon ? props.icon : null
}))<{ icon; light: number }>`
  position: absolute;
  z-index: 1;
  color: ${props => (props.light ? Color.White : Color.Gray)};
  top: ${`calc((${fieldHeight} + ${fieldBorder} + 2 * ${fieldPadding} - ${ICON_HEIGHT}) / 2)`};
  left: ${Spacing.Medium};
`
