import { BooleanContainer } from 'atomic/legacy/obj.abstract-container'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Header } from '../legacy/org.header'
import { ContactUsModal } from '../mol.contact-us-modal/contact-us-modal.component'
import { ClientDoctorSelect } from 'utils/model/client-doctor-select'
import { ClientMenuContentRows } from './components/client-menu-content-rows.component'
import { DesktopBottomHeader } from './components/desktop-bottom-header.component'
import { DesktopTopHeader } from './components/desktop-top-header.component'
import { getClientMenuItems } from './components/menu-items'
import { MobileHeader } from './components/mobile-header.component'

export interface ClientHeaderProps {
  showScheduleButton: boolean
}

export const ClientHeader: React.FunctionComponent<ClientHeaderProps> = React.memo(props => {
  const area = ClientDoctorSelect.client
  const themeContext = useContext(ThemeContext)
  return (
    <Header>
      <DesktopTopHeader area={area} showScheduleButton={props.showScheduleButton} />
      <BooleanContainer>
        {schedulingModal => (
          <>
            <DesktopBottomHeader {...getClientMenuItems(themeContext, schedulingModal.setTrue)} />
            <ContactUsModal
              open={schedulingModal.value}
              onClose={schedulingModal.setFalse}
              isDoctor={false}
            />
          </>
        )}
      </BooleanContainer>
      <MobileHeader area={area} showScheduleButton={props.showScheduleButton}>
        <ClientMenuContentRows />
      </MobileHeader>
    </Header>
  )
})
