import styled from 'styled-components'
import { ClientDoctorSelect } from 'utils/model/client-doctor-select'
import { Spacing } from 'atomic'

const DesktopTopHeaderHeight = '72px'
export const DesktopTopHeaderStyled = styled.div`
  padding: 12px 0;
`

export const DesktopTopHeaderLogoStyled = styled.div`
  height: 100%;
  & > svg {
    overflow: visible;
    max-width: 20vw;
  }
`

export const DesktopBottomHeaderStyled = styled.div<{ area: ClientDoctorSelect }>`
  display: none;
  min-height: ${Spacing.XLarge};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  align-items: center;
  background: #373a3a;
  position: sticky;
  top: ${DesktopTopHeaderHeight};
  z-index: 89;
  width: 100%;

  @media all and (min-width: 1023px) {
    display: flex;

    .MuiGrid-container {
      padding-left:16px;
    }

    .MuiGrid-item {
      padding: 0!important;
    }
  }
`

export const MenuMobile = styled.div`
  padding-left: ${Spacing.XLarge};

  @media (min-width: 1023px) {
    padding-left:0;

    .MuiGrid-item {
      padding: 0;
    }
  }
`
