import * as React from 'react'
import {
  HeaderDeskStyled,
  HeaderMobilePlaceholderStyled,
  HeaderMobileStyled,
  HeaderTabletStyled
} from './header.component.style'

const HeaderMobile = (props: any) => (
  <>
    <HeaderMobileStyled>{props.children}</HeaderMobileStyled>
    <HeaderMobilePlaceholderStyled />
  </>
)
const HeaderDesk = (props: any) => <HeaderDeskStyled>{props.children}</HeaderDeskStyled>
const HeaderTablet = (props: any) => <HeaderTabletStyled>{props.children}</HeaderTabletStyled>
export class Header extends React.PureComponent<{}> {
  static Mobile = HeaderMobile
  static Tablet = HeaderTablet
  static Desk = HeaderDesk

  render() {
    return this.props.children
  }
}
