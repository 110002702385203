import { Button } from 'atomic'
import SvgIcLogo from 'atomic/atm.svg-icon/ic-logo.component'
import { Fade } from 'atomic/legacy/obj.animation/animation.component.style'
import { useDraw } from 'atomic/obj.custom-hooks/draw.hook'
import Observer from 'react-intersection-observer'
import { BooleanContainer } from 'atomic/legacy/obj.abstract-container'
import { RelativeWrapper } from 'atomic/obj.wrappers'
import { SearchContainer } from './search.container'
import { SearchEverythingField } from './search-everything-field.component'
import { DesktopAutocomplete } from './autocomplete.component'
import { SchedulingModal } from '../../mol.scheduling-modal/scheduling-modal.component'
import { Link } from 'gatsby'
import React from 'react'
import { ClientDoctorSelect } from 'utils/model/client-doctor-select'
import {
  DesktopTopHeaderLogoStyled,
  DesktopHeaderContainer,
  SegmentContainer,
  SearchContainerDesktop,
  ButtonDoctorStyled,
  ButtonClientStyled,
  ButtonContainer
} from './desktop-header.component.style'

import {
  ClientDoctorSegmentedControl,
  getHomePathForClientDoctorSelect
} from './client-doctor-segmented-control.component'

import { externalPaths } from 'utils/path'
import { Grid, Container, Box } from '@material-ui/core'
import { MySchedules } from '../../mySchedules/MySchedules'

interface DesktopTopMenuHeaderPartialProps {
  area: ClientDoctorSelect
  showScheduleButton: boolean
}

export const DesktopTopHeader: React.FunctionComponent<DesktopTopMenuHeaderPartialProps> = React.memo(
  props => {
    const isLoaded = useDraw()
    return (
      <Observer>
        <>
          <DesktopHeaderContainer>
            <Container maxWidth="lg">
              <Grid container spacing={2}>
                {props.showScheduleButton && props.area === ClientDoctorSelect.client ? (
                  <Grid item sm md>
                    <Link
                      id="logo"
                      to={getHomePathForClientDoctorSelect(props.area)}
                      aria-label="home"
                    >
                      <DesktopTopHeaderLogoStyled className="LogoClientContainer">
                        <SvgIcLogo height={32} />
                      </DesktopTopHeaderLogoStyled>
                    </Link>
                  </Grid>
                ) : (
                  <Grid item sm md>
                    <Link
                      id="logo"
                      to={getHomePathForClientDoctorSelect(props.area)}
                      aria-label="home"
                    >
                      <DesktopTopHeaderLogoStyled className="LogoDoctorContainer">
                        <SvgIcLogo height={32} />
                      </DesktopTopHeaderLogoStyled>
                    </Link>
                  </Grid>
                )}

                <Grid item md={2}>
                  <Fade show={isLoaded}>
                    <SegmentContainer>
                      <ClientDoctorSegmentedControl id="desktop" kind="default" area={props.area} />
                    </SegmentContainer>
                  </Fade>
                </Grid>

                <Grid item md>
                  <SearchContainerDesktop>
                    <Fade show={isLoaded}>
                      <SearchContainer isDoctor={props.area === ClientDoctorSelect.doctor}>
                        {(searchProps, searchState) => (
                          <RelativeWrapper>
                            <SearchEverythingField
                              id="desktop"
                              value={searchState.searchValue}
                              onValueChange={searchProps.handleTextChange}
                              onSubmit={searchProps.handleSubmit}
                              onFocus={searchProps.handleFocus}
                            />
                            <DesktopAutocomplete
                              search={searchState.searchValue}
                              opened={searchState.autocompleteOpen}
                              onCellClick={searchProps.handleSubmit}
                              onOverlayClick={searchProps.handleOverlayClick}
                              suggestions={searchProps.suggestions}
                            />
                          </RelativeWrapper>
                        )}
                      </SearchContainer>
                    </Fade>
                  </SearchContainerDesktop>
                </Grid>

                <Grid item md={2}>
                  {props.area === ClientDoctorSelect.doctor ? (
                    <ButtonDoctorStyled>
                      <Button external to={externalPaths.weinmannExamResultsDoctor.path}>
                        Resultado de exames
                      </Button>
                    </ButtonDoctorStyled>
                  ) : (
                    <ButtonClientStyled>
                      <Fade show={isLoaded}>
                        <BooleanContainer>
                          {bool => (
                            <>
                              <Button
                                kind="primary"
                                onClick={bool.setTrue}
                                id={'test-schedule-modal-button'}
                                
                              >
                                <ButtonContainer>                                  
                                  {process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'fleury' ?
                                    'Agendar exames e vacinas' :
                                    'Agendar'
                                  }
                                </ButtonContainer>
                              </Button>
                              <SchedulingModal open={bool.value} onClose={bool.setFalse} />
                            </>
                          )}
                        </BooleanContainer>
                      </Fade>
                    </ButtonClientStyled>
                  )}                  
                </Grid>
                {process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'weinmann' && (
                  <Box display='flex' justifyContent='center'>&nbsp;
                    <MySchedules />
                  </Box>                                  
                )}
              </Grid>
            </Container>
          </DesktopHeaderContainer>
        </>
      </Observer>
    )
  }
)
