import { appPaths, getPathUrl, getUnitDetailPageUrl, pagesPaths } from 'utils/path'
import { getDetailPageUrl, getSearchQueryParam } from 'utils/url'
import { ElasticSearchIndex } from '../data/http/utils/elastic-search-index'

// TODO: OUTROS_SITES - mover essa lógica somente para fleury
export const getPathForElasticSearchIndex = (
  isDoctor: boolean,
  slug: string,
  index: ElasticSearchIndex
) => {
  let inputWithoutBrand = index.replace(/(fleury|weinmann|felippe-mattoso|felippe_mattoso)-/, '')
  inputWithoutBrand = inputWithoutBrand.replace(
    /(fleury|weinmann|felippe_mattoso|felippe_mattoso)_/,
    ''
  )
  inputWithoutBrand = inputWithoutBrand.replace(
    inputWithoutBrand.substring(inputWithoutBrand.length - 14),
    ''
  )

  switch (inputWithoutBrand) {
    case 'agendamentos':
      // TODO: SEARCH - verificar regra para esse tipo
      return isDoctor ? appPaths.contact.path.doctorUrl : appPaths.contact.path.clientUrl
    case 'algoritmos-diagnsticos':
      return slug
    case 'artigos':
      return getDetailPageUrl(`${appPaths.scientificArticles.path.doctorUrl}`, slug)
    case 'convenios':
      // TODO: SEARCH - verificar regra para esse tipo
      return isDoctor ? appPaths.convenio.path.doctorUrl : appPaths.convenio.path.clientUrl
    case 'corpo-clinico':
      return getDetailPageUrl(
        isDoctor ? appPaths.ourSpecialists.path.doctorUrl : appPaths.ourSpecialists.path.clientUrl,
        slug
      )
    case 'detalhe-de-convenios':
      return getDetailPageUrl(
        isDoctor ? appPaths.convenio.path.doctorUrl : appPaths.convenio.path.clientUrl,
        slug
      )
    case 'diferenciais':
      // TODO: SEARCH - verificar regra para esse tipo
      return `${getPathUrl(appPaths.search.path, isDoctor)}${getSearchQueryParam(slug)}`
    case 'educacao-medica':
      return appPaths.medicalEducation.path.doctorUrl
    case 'especialidades':
      // TODO: SEARCH - verificar regra para esse tipo
      return `${getPathUrl(appPaths.search.path, isDoctor)}${getSearchQueryParam(slug)}`
    case 'exames':
      return getDetailPageUrl(
        isDoctor ? appPaths.examManual.path.doctorUrl : appPaths.exams.path.clientUrl,
        slug
      )
    case 'exames-services':
      return getDetailPageUrl(
        isDoctor ? appPaths.examManual.path.doctorUrl : appPaths.exams.path.clientUrl,
        slug
      )
    case 'facilidades':
      // TODO: SEARCH - verificar regra para esse tipo
      return `${getPathUrl(appPaths.search.path, isDoctor)}${getSearchQueryParam(slug)}`
    case 'fale-conosco':
      // TODO: SEARCH - verificar regra para esse tipo
      return isDoctor ? appPaths.contact.path.doctorUrl : appPaths.contact.path.clientUrl
    case 'grupo-de-exames':
      // TODO: SEARCH - verificar regra para esse tipo
      return `${getPathUrl(appPaths.search.path, isDoctor)}${getSearchQueryParam(slug)}`
    case 'home-cliente':
      return pagesPaths.homeClient.path
    case 'home-medico':
      return pagesPaths.homeDoctor.path
    case 'horarios':
      return `${isDoctor ? appPaths.units.path.doctorUrl : appPaths.units.path.clientUrl}`
    case 'informacoes-estaticas':
      // TODO: SEARCH - verificar regra para esse tipo
      return `${getPathUrl(appPaths.search.path, isDoctor)}${getSearchQueryParam(slug)}`
    case 'institucional':
      return getDetailPageUrl(appPaths.institutional.path.doctorUrl, slug)
    case 'manuais-diagnosticos':
      return getDetailPageUrl(appPaths.diagnosticManual.path.doctorUrl, slug)
    case 'manual-de-doencas':
      return getDetailPageUrl(
        isDoctor ? appPaths.diseaseManual.path.doctorUrl : appPaths.diseaseManual.path.clientUrl,
        slug
      )
    case 'manual-de-doencas2':
      return getDetailPageUrl(
        isDoctor ? appPaths.diseaseManual.path.doctorUrl : appPaths.diseaseManual.path.clientUrl,
        slug
      )
    case 'manual-de-exames':
      return getDetailPageUrl(
        isDoctor ? appPaths.examManual.path.doctorUrl : appPaths.exams.path.clientUrl,
        slug
      )
    case 'noticias':
      return getDetailPageUrl(
        isDoctor ? appPaths.news.path.doctorUrl : appPaths.news.path.clientUrl,
        slug
      )
    case 'novos-servicos':
      return appPaths.otherServices.path.clientUrl
    case 'palavras-chave':
      // TODO: SEARCH - verificar regra para esse tipo
      return `${getPathUrl(appPaths.search.path, isDoctor)}${getSearchQueryParam(slug)}`
    case 'perguntas-frequentes':
      return getDetailPageUrl(
        isDoctor ? appPaths.faq.path.doctorUrl : appPaths.faq.path.clientUrl,
        slug
      )
    case 'receptivos-de-campanhas':
      return getDetailPageUrl(
        isDoctor
          ? appPaths.campaignReceptive.path.doctorUrl
          : appPaths.campaignReceptive.path.clientUrl,
        slug
      )
    case 'revistas-fleury':
      return appPaths.fleuryMagazines.path.clientUrl
    case 'revistas-medicas':
      return appPaths.medicalMagazines.path.doctorUrl
    case 'roteiros-diagnosticos':
      return slug
    case 'servicos-diferenciados':
      return getDetailPageUrl(
        isDoctor
          ? appPaths.differentiatedServices.path.doctorUrl
          : appPaths.differentiatedServices.path.clientUrl,
        slug
      )
    case 'sobre-fleury-historia':
      return isDoctor ? appPaths.history.path.doctorUrl : appPaths.history.path.clientUrl
    case 'unidades':
      return getUnitDetailPageUrl(
        isDoctor ? appPaths.units.path.doctorUrl : appPaths.units.path.clientUrl,
        slug
      )
    case 'pages':
      return `/${slug}`
    default:
      console.error('ERROR: pathForElasticSearchIndex: Not mapped elastic search index: ', index)
      return '/'
  }
}
