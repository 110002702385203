import * as React from 'react'
import { BodySecondary, Button, SemiBold } from 'atomic'
import { hasWindow } from 'utils/browser'
import { appPathsDictionary, pagesPaths, pagesPathsDictionary } from 'utils/path'
import { graphql, StaticQuery } from 'gatsby'
import { useMediaQuery, useTheme } from '@material-ui/core'

interface BreadcrumbProps {
  addtionalDictionary?: { [id: string]: string }
}

export const Breadcrumb: React.FunctionComponent<BreadcrumbProps> = props => {
  const theme = useTheme()
  const downOfSm = useMediaQuery(theme.breakpoints.down('xs'))

  const pathname = hasWindow() ? window.location.pathname : ''
  const normalizedPathname = pathname.slice(
    0,
    pathname[pathname.length - 1] === '/' ? -1 : undefined
  )
  const pathComponents = normalizedPathname
    .split('/')
    .filter(comp => comp)
    .map(comp => `/${comp}`)

  if (!pathComponents.length) {
    return null
  }

  const isDoctorContext = pathComponents[0] === pagesPaths.homeDoctor.path
  const finalPathComponents = isDoctorContext
    ? [pathComponents[0].concat(pathComponents[1])].concat(pathComponents.slice(2))
    : pathComponents

  const firstComponent = isDoctorContext ? pathComponents[0] : pagesPaths.homeClient.path
  const lastComponent = finalPathComponents[finalPathComponents.length - 1]
  const fixedPathComponents = finalPathComponents.slice(0, -1)
  if (firstComponent === lastComponent) {
    return null
  }

  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              objectTypes {
                path
                name
              }
            }
          }
        }
      `}
      render={data => {
        const dictionary = data.site.siteMetadata.objectTypes.reduce((acc, curr) => {
          acc[`/${curr.path}`] = curr.name
          return acc
        }, {})
        const finalDictionary = Object.assign(
          appPathsDictionary,
          pagesPathsDictionary,
          dictionary,
          props.addtionalDictionary
        )

        return (
          <>
            {!downOfSm && (
              <BodySecondary>
                <SemiBold>Você está em:&nbsp;</SemiBold>
                <Button id="button-dictionary-first-breaccrumb" kind="link" to={firstComponent}>
                  {finalDictionary[firstComponent]}
                </Button>
                {fixedPathComponents.length > 0 &&
                  fixedPathComponents.map((comp, index) => {
                    return (
                      comp !== '/lps' && (
                        <React.Fragment key={index}>
                          &nbsp;>&nbsp;
                          <Button
                            id={`button-dictionary-breaccrumb-${index}`}
                            kind="link"
                            to={`${fixedPathComponents.slice(0, index + 1).join('')}`}
                          >
                            {finalDictionary[comp]}
                          </Button>
                        </React.Fragment>
                      )
                    )
                  })}
                &nbsp;>&nbsp; {finalDictionary[lastComponent]}
              </BodySecondary>
            )}
          </>
        )
      }}
    />
  )
}

Breadcrumb.defaultProps = {
  addtionalDictionary: {}
}
