import { Box } from '@material-ui/core'
import { Button, Col, Grid, Hbox, Row } from 'atomic'
import SvgIcLogo from 'atomic/atm.svg-icon/ic-logo.component'
import { BooleanContainer } from 'atomic/legacy/obj.abstract-container'
import { Fade } from 'atomic/legacy/obj.animation/animation.component.style'
import { useDraw } from 'atomic/obj.custom-hooks/draw.hook'
import { RelativeWrapper } from 'atomic/obj.wrappers'
import { Link } from 'gatsby'
import React from 'react'
import { ClientDoctorSelect } from 'utils/model/client-doctor-select'
import { Header } from '../../legacy/org.header'
import { SchedulingModal } from '../../mol.scheduling-modal/scheduling-modal.component'
import { MySchedules } from '../../mySchedules/MySchedules'
import {
  ButtonContainer,
  DesktopTopHeaderLogoStyled,
  DesktopTopHeaderStyled
} from '../client-doctor-header.component.style'
import { DesktopAutocomplete } from './autocomplete.component'
import {
  ClientDoctorSegmentedControl,
  getHomePathForClientDoctorSelect
} from './client-doctor-segmented-control.component'
import { SearchEverythingField } from './search-everything-field.component'
import { SearchContainer } from './search.container'

interface DesktopTopMenuHeaderPartialProps {
  area: ClientDoctorSelect
  showScheduleButton: boolean
}

const MenuItemSeparator = () => (
  <>
    <Hbox.Separator />
    <Hbox.Separator />
    <Hbox.Separator />
  </>
)

export const DesktopTopHeader: React.FunctionComponent<DesktopTopMenuHeaderPartialProps> = React.memo(
  props => {
    const isLoaded = useDraw()
    return (
      <Header.Desk>
        <Grid>
          <Row>
            <Col xs={12}>
              <DesktopTopHeaderStyled>
                <Hbox>
                  <Hbox.Item wrap vAlign={'center'}>
                    <Link
                      id="logo"
                      to={getHomePathForClientDoctorSelect(props.area)}
                      aria-label="home"
                    >
                      <DesktopTopHeaderLogoStyled>
                        <SvgIcLogo height={32} />
                      </DesktopTopHeaderLogoStyled>
                    </Link>
                  </Hbox.Item>
                  <MenuItemSeparator />
                  <Hbox.Item wrap vAlign={'flex-end'}>
                    <Fade show={isLoaded}>
                      <ClientDoctorSegmentedControl id="desktop" kind="default" area={props.area} />
                    </Fade>
                  </Hbox.Item>
                  <MenuItemSeparator />
                  <Hbox.Item>
                    <Fade show={isLoaded}>
                      <SearchContainer isDoctor={props.area === ClientDoctorSelect.doctor}>
                        {(searchProps, searchState) => (
                          <RelativeWrapper>
                            <SearchEverythingField
                              id="desktop"
                              value={searchState.searchValue}
                              onValueChange={searchProps.handleTextChange}
                              onSubmit={searchProps.handleSubmit}
                              onFocus={searchProps.handleFocus}
                            />
                            <DesktopAutocomplete
                              search={searchState.searchValue}
                              opened={searchState.autocompleteOpen}
                              onCellClick={searchProps.handleSubmit}
                              onOverlayClick={searchProps.handleOverlayClick}
                              suggestions={searchProps.suggestions}
                            />
                          </RelativeWrapper>
                        )}
                      </SearchContainer>
                    </Fade>
                  </Hbox.Item>
                  {props.showScheduleButton && props.area !== ClientDoctorSelect.doctor && (
                    <>
                      <MenuItemSeparator />
                      <Hbox.Item wrap>
                        <Fade show={isLoaded}>
                          <BooleanContainer>
                            {bool => (
                              <>
                                <Button
                                  kind="primary"
                                  onClick={bool.setTrue}
                                  id={'test-schedule-modal-button'}                               
                                >
                                  <ButtonContainer style={{ paddingLeft: '16px', paddingRight: '16px' }}>                                  
                                    {process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'fleury' ?
                                      'Agendar exames e vacinas' :
                                      'Agendar'
                                    }
                                  </ButtonContainer>
                                  
                                </Button>
                                <SchedulingModal open={bool.value} onClose={bool.setFalse} />
                              </>
                            )}
                          </BooleanContainer>
                        </Fade>
                      </Hbox.Item>                      
                    </>
                  )}
                  {(process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'fleury' || 
                    process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'weinmann' || 
                    process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'felippe-mattoso') && (
                    <>
                      <MenuItemSeparator />
                      <Box display='flex' justifyContent='center'>
                        <MySchedules />
                      </Box>
                    </>
                  )}               
                </Hbox>                
              </DesktopTopHeaderStyled>
            </Col>
          </Row>
        </Grid>
      </Header.Desk>
    )
  }
)
