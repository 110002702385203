import { FetchDataSource, FetchDataSourceInput } from '@root/src/data/fetch.datasource'
import * as React from 'react'
import { isNullOrUndefined } from 'util'

interface FetchContainerProps {
  children: (state: FetchContainerState) => JSX.Element
  input?: FetchDataSourceInput<any, any>
  mapper?: (response: any) => any
  defaultBaseUrl: string
  onError?: (error: Error) => void
}

interface FetchContainerState {
  data: any
  loading: boolean
  error: any
}

/**
 * Inspired by QueryContainer from react-apollo
 * It performs a GET request and handles `loading`, `error` and `data` (the response)
 *
 * Check here an example of how to use it: https://github.com/indigotech/br-accera-key_accounts-react/blob/63a2adf548b2174ab554d8870aebc7c9c08e8af3/web/src/app/modules/distribution-center-details/distribution-center-details.page.tsx#L25-L51
 *
 */
export class FetchContainer extends React.Component<FetchContainerProps, FetchContainerState> {
  private dataSource: FetchDataSource

  constructor(props: FetchContainerProps) {
    super(props)
    this.state = {
      data: null,
      loading: true,
      error: null
    }

    this.dataSource = new FetchDataSource(this.props.defaultBaseUrl)
  }

  componentDidMount() {
    this.fetchData()
  }
  componentDidUpdate(prevProps: FetchContainerProps) {
    if (JSON.stringify(this.props.input) !== JSON.stringify(prevProps.input)) {
      this.fetchData()
    }
  }

  render() {
    return this.props.children(this.state)
  }

  private fetchData = async () => {
    if (!this.props.input || !this.props.input.url || this.props.input.url.length === 0) {
      this.setState({
        data: null,
        loading: false,
        error: null
      })
      return
    }

    try {
      this.setState({ loading: true })
      const rawData = await this.dataSource.fetch(this.props.input)
      const mappedData = this && this.props.mapper && this.props.mapper(rawData)
      if (this) {
        this.setState({
          loading: false,
          data: isNullOrUndefined(mappedData) ? rawData : mappedData
        })
      }
    } catch (error) {
      console.error('FetchContainer -> privatefetchData -> error', error)
      if (!this) {
        return
      }

      if (this.props.onError) {
        this.props.onError(error)
      }
      this.setState({ error, loading: false })
    }
  }
}
