// tslint:disable
import {
  isCnpjPatternValid,
  isCpfPatternValid,
  isCpfValid,
  isDateValid,
  isEmailValid,
  isPasswordValid,
  isPhoneValid,
  isZipCodeValid
} from './regex-validator'

export interface Validator {
  error: ValidationError
  validationFn: any
}

export interface ValidationError {
  name: string
  message: string
}

export class Validators {
  static MaxLength(length: number, message: string): Validator {
    return {
      error: {
        name: 'MaxLength',
        message
      },
      validationFn: (value: any) => {
        return value && value.toLocaleString().length <= length
      }
    }
  }

  static MinLength(length: number, message: string): Validator {
    return {
      error: {
        name: 'MinLength',
        message
      },
      validationFn: (value: any) => {
        return value && value.toLocaleString().length >= length
      }
    }
  }

  static Required(message?: string): Validator {
    return {
      error: {
        name: 'Required',
        message: message || 'Required'
      },
      validationFn: (value: any) => {
        return (value && Object.keys(value).length > 0) || value instanceof Date || !isNaN(value)
      }
    }
  }

  static IsFullName(message?: string): Validator {
    return {
      error: {
        name: 'IsFullName',
        message: message
      },
      validationFn: (value: any) => {
        value = value !== undefined ? value.trim() : ''

        if (!value.includes(' ')) return false
        return true
      }
    }
  }

  static IsNotOnlySpaces(message?: string): Validator {
    return {
      error: {
        name: 'IsNotOnlySpaces',
        message: message
      },
      validationFn: (value: any) => {
        value = value !== undefined ? value.trim() : ''

        if (value === '') return false
        return true
      }
    }
  }

  static EmailRegex(message: string): Validator {
    return {
      error: {
        name: 'EmailRegex',
        message
      },
      validationFn: (value: any) => {
        return isEmailValid(value)
      }
    }
  }

  static CpfPatternRegex(message: string): Validator {
    return {
      error: {
        name: 'Cpf pattern regex',
        message
      },
      validationFn: (value: any) => {
        return isCpfPatternValid(value)
      }
    }
  }

  static CpfRegex(message: string): Validator {
    return {
      error: {
        name: 'Cpf regex',
        message
      },
      validationFn: (value: any) => {
        return isCpfValid(value)
      }
    }
  }

  static CnpjPatternRegex(message: string): Validator {
    return {
      error: {
        name: 'Cnpj pattern regex',
        message
      },
      validationFn: (value: any) => {
        return isCnpjPatternValid(value)
      }
    }
  }

  static ZipCodeRegex(message: string): Validator {
    return {
      error: {
        name: 'Zip code regex',
        message
      },
      validationFn: (value: any) => {
        return isZipCodeValid(value)
      }
    }
  }
  static PhoneRegex(message: string): Validator {
    return {
      error: {
        name: 'PhoneRegex',
        message
      },
      validationFn: (value: any) => {
        return isPhoneValid(value)
      }
    }
  }

  static PasswordRegex(message: string): Validator {
    return {
      error: {
        name: 'PasswordRegex',
        message
      },
      validationFn: (value: any) => {
        return isPasswordValid(value)
      }
    }
  }

  static DateRegex(message: string): Validator {
    return {
      error: {
        name: 'DateRegex',
        message
      },
      validationFn: (value: any) => {
        return value !== undefined ? isDateValid(value) : true
      }
    }
  }

  static IsNotEqualToField(valueToCompare: any, message: string): Validator {
    return {
      error: {
        name: 'IsNotEqualToField',
        message
      },
      validationFn: (value: any) => {
        return value === valueToCompare
      }
    }
  }

  static IsValidCrmRj(state: string, message: string): Validator {
    return {
      error: {
        name: 'IsValidCrm',
        message
      },
      validationFn: (value: any) => {
        return state === 'RJ' ? value.substring(0, 1) !== '0' : true
      }
    }
  }

  // Validates Brazilian social security number
  static SocialSecurityNumberRegex(message?: string): Validator {
    return {
      error: {
        name: 'SocialSecurityNumberRegex',
        message
      },
      validationFn: (value: any) => {
        return isSocialSecurityNumberValid(value)
      }
    }
  }
}
