import { TextFieldProps } from 'atomic/legacy/atm.text-field'
import React from 'react'
import { SearchField } from './search-field.component'

export interface SearchEverythingFieldProps extends TextFieldProps {
  onSubmit: (ev) => void
}

/**
 * This search field is used on the site header (to search on the whole site)
 * @param props
 */
export const SearchEverythingField = (props: SearchEverythingFieldProps) => {
  const { onSubmit, id, ...other } = props
  return (
    <form id="form-search-everything" onSubmit={onSubmit}>
      <SearchField {...other} id={`${id}-global-search`} />
    </form>
  )
}
