import { WhiteBackground } from '@root/src/components/atm.background/background.styled'
import SvgIcClose from 'atomic/atm.svg-icon/ic-close.component'
import SvgIcSuccess from 'atomic/atm.svg-icon/ic-success.component'
import { Col, Grid, Hbox, Row, Separator } from 'atomic'
import { FaIcon } from 'atomic/legacy/atm.fa-icon'
import { Fade } from 'atomic/legacy/obj.animation/animation.component.style'
import * as React from 'react'
import { withTheme, DefaultTheme } from 'styled-components'
import {
  FlashIconStyled,
  FlashMessageCloseStyled,
  FlashMessageContentStyled,
  FlashMessageStyled,
  getFlashMessageTypesColors
} from './flash-message.component.style'

export type FlashMessageType = 'alert' | 'success' | 'info-secondary' | 'info' | 'warning'

export interface FlashMessageProps {
  type?: FlashMessageType
  outlined?: boolean
  dismissible?: boolean
  autoClose?: boolean
  onClose?: () => any
  onClick?: () => any

  children: JSX.Element

  // injected by withTheme
  theme?: DefaultTheme
}

// tslint:disable-next-line:no-empty-interface
interface FlashMessageState {
  hidden: boolean
  remove: boolean
}

const timeToHide = 10 * 1000

/**
 * This is an uncontrolled flash message.
 * It starts NOT hidden and after "timeToHide" seconds it is removed from DOM.
 * The user can also close it manually
 */
class InternalFlashMessage extends React.PureComponent<FlashMessageProps, FlashMessageState> {
  public static defaultProps: Partial<FlashMessageProps> = {
    type: 'info',
    dismissible: true,
    outlined: false,
    autoClose: true
  }

  private timeoutList: any[] = []

  constructor(props: FlashMessageProps) {
    super(props)

    this.state = {
      hidden: false,
      remove: false
    }
  }

  componentDidMount() {
    this.startCloseTimer()
  }

  componentWillUnmount() {
    this.clearTimeoutList()
  }

  render() {
    const { children, dismissible, ...other } = this.props
    return this.state.remove ? null : (
      <Fade show={!this.state.hidden}>
        <Separator />
        <Grid>
          <Row between={'xs'} center="xs">
            <Col xs={12} md={7}>
              <WhiteBackground>
                <FlashMessageStyled {...other}>
                  <Hbox hAlign="center">
                    <Hbox.Item wrap>
                      <FlashIconStyled onClick={this.handleCloseClick} {...other}>
                        <FlashIcon messageKind={this.props.type} />
                      </FlashIconStyled>
                    </Hbox.Item>
                    <Hbox.Item>
                      <FlashMessageContentStyled>{children}</FlashMessageContentStyled>
                    </Hbox.Item>
                    <Hbox.Item wrap>
                      {dismissible ? (
                        <FlashMessageCloseStyled onClick={this.handleCloseClick} {...other}>
                          <SvgIcClose
                            height="14px"
                            color={getFlashMessageTypesColors(this.props.type, this.props.theme)}
                          />
                        </FlashMessageCloseStyled>
                      ) : null}
                    </Hbox.Item>
                  </Hbox>
                </FlashMessageStyled>
              </WhiteBackground>
            </Col>
          </Row>
        </Grid>
      </Fade>
    )
  }

  private handleCloseClick = (ev?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (ev) ev.stopPropagation()
    this.startRemoveFromDomAnimation()
  }

  private startCloseTimer = () => {
    if (this.props.autoClose) {
      const timeout = setTimeout(() => this.startRemoveFromDomAnimation(), timeToHide)
      this.timeoutList.push(timeout)
    }
  }

  private startRemoveFromDomAnimation = () => {
    this.setState({ hidden: true }, () => {
      const timeout = setTimeout(() => this.removeFromDom(), 300)
      this.timeoutList.push(timeout)
    })
  }

  private removeFromDom = () => {
    this.setState({ remove: true }, () => {
      this.clearTimeoutList()
      if (this.props.onClose) {
        this.props.onClose()
      }
    })
  }

  private clearTimeoutList = () => {
    this.timeoutList.forEach(element => clearTimeout(element))
  }
}

interface FlashIconProps {
  messageKind: FlashMessageType
}

const FlashIcon: React.FunctionComponent<FlashIconProps> = props => {
  switch (props.messageKind) {
    case 'alert':
      return <FaIcon.FlashAlert />

    case 'info':
      return <FaIcon.FlashInfo />

    case 'info-secondary':
      return <FaIcon.FlashInfo />

    case 'success':
      return <SvgIcSuccess height="14px" />

    case 'warning':
      return <FaIcon.FlashWarning />

    default:
      return null
  }
}

export const FlashMessage = withTheme(InternalFlashMessage)
