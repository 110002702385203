// tslint:disable

import { Color, HeaderHeight } from 'atomic/legacy/obj.constants'
import styled, { css } from 'styled-components'
import { GridSettings } from 'atomic'

export const headerBreakpoint = `${GridSettings.flexboxgrid.breakpoints.md}em`
export const headerTabletBreakpoint = `${GridSettings.flexboxgrid.breakpoints.lg}em`

export const HeaderDeskStyled = styled.div`
  display: none;
  position: sticky;
  top: 0;
  z-index: 99;
  height: ${HeaderHeight.Desk};
  background: ${Color.White};

  @media all and (min-width: ${process.env.GATSBY_MARCA === 'WEINMANN'
      ? headerTabletBreakpoint
      : headerBreakpoint}) {
    display: flex;
    align-items: center;
  }
`

export const HeaderTabletStyled = styled.div`
  display: none;
  position: fixed;
  top: 0;
  z-index: 99;
  height: ${HeaderHeight.Desk};
  width: 100%;
  background: ${Color.White};

  @media all and (min-width: ${headerBreakpoint}) {
    display: flex;
    align-items: center;
  }
`

const headerMobileCommonStyle = css`
  height: ${HeaderHeight.Mobile}px;
  @media all and (min-width: ${headerBreakpoint}) {
    display: none;
  }
`
export const HeaderMobilePlaceholderStyled = styled.div`
  ${headerMobileCommonStyle}
`

export const HeaderMobileStyled = styled.div`
  ${headerMobileCommonStyle}
  position: fixed;
  background: ${Color.White};
  width: 100%;
  box-shadow: 0px -2px 7px ${Color.GrayXDark};
  z-index: 99;

  display: flex;
  align-items: center;
  justify-content: space-between;
`
