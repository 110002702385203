let SvgMobileWhiteLogo
if (process.env.GATSBY_MARCA === 'FLEURY') {
  SvgMobileWhiteLogo = require('fleury/components/atm.svg-icon/ic-white-logo.component').default
} else if (process.env.GATSBY_MARCA === 'WEINMANN') {
  SvgMobileWhiteLogo = require('weinmann/components/atm.svg-icon/ic-white-logo.component').default
} else if (process.env.GATSBY_MARCA === 'FM') {
  SvgMobileWhiteLogo = require('felippe-mattoso/components/atm.svg-icon/ic-mobile-white-logo.component').default
}

export default SvgMobileWhiteLogo
