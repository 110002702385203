import { FlashMessageType } from '@root/src/components/legacy/mol.flash-message'

export interface FlashMessageData {
  message: string
  type: FlashMessageType
  time: Date
}

export interface FlashDispatcherListener {
  onReceiveMessage: (data: FlashMessageData) => void
}

/**
 * This class is used to dispatch a message to notify its listener that something
 * wants to show a flash message.
 * The dispatched messaged is caught by FlashWrapper (@see FlashWrapper)
 */
class FlashDispatcherService {
  private listener: FlashDispatcherListener

  setListener(listener: FlashDispatcherListener) {
    this.listener = listener
  }

  dispatchMessage(message: string, type: FlashMessageType) {
    if (this.listener) {
      this.listener.onReceiveMessage({ message, type, time: new Date() })
    }
  }
}

export default new FlashDispatcherService()
