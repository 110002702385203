import * as S from './styles'
import * as React from 'react'

const Title = ({ children, ...props }) => (
  <S.Wrapper className={`${props.noMargin ? 'noMargin' : ''}`}>
    <S.Content {...props}>{children}</S.Content>
    {!props.noLine && <S.Line />}
  </S.Wrapper>
)

export default Title
