import * as React from 'react'

import { HamburgerBoxStyled, HamburgerButtonStyled, HamburgerInnerStyled } from './hamburger-button.component.style'

export interface HamburgerButtonProps {
  active?: boolean
  onClick?: () => void
}

export class HamburgerButton extends React.Component<HamburgerButtonProps, undefined> {
  static defaultProps = {
    active: false
  }

  render() {
    return (
      <HamburgerButtonStyled role="button" onClick={this.props.onClick}>
        <HamburgerBoxStyled>
          <HamburgerInnerStyled className="hamburguerInner" active={this.props.active} />
        </HamburgerBoxStyled>
      </HamburgerButtonStyled>
    )
  }

  shouldComponentUpdate(nextProps: HamburgerButtonProps) {
    return nextProps.active !== this.props.active
  }
}
