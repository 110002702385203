import { BooleanContainer } from 'atomic/legacy/obj.abstract-container'
import React from 'react'

import { ContactUsModal } from '../mol.contact-us-modal/contact-us-modal.component'
import { ClientDoctorSelect } from 'utils/model/client-doctor-select'

import { DesktopTopHeader } from './components/desktop-top-header.component'
import { DoctorMenuContentRows } from './components/doctor-menu-content-rows.component'
import { getDoctorMenuItems } from './components/menu-items'

import { MobileHeader } from './components/mobile-header.component'
import { TabletHeader } from './components/tablet-header.component'
import { DesktopBottomHeaderNew } from './components/desktop-bottom-header-new.component'

export interface DoctorHeaderProps {
  showScheduleButton?: boolean
}

export const NewDoctorHeader: React.FunctionComponent<DoctorHeaderProps> = React.memo(props => {
  const area = ClientDoctorSelect.doctor

  return (
    <>
      <DesktopTopHeader area={area} showScheduleButton={props.showScheduleButton} />
      <BooleanContainer>
        {contactModal => (
          <>
            <DesktopBottomHeaderNew {...getDoctorMenuItems(contactModal.setTrue)} />
            <ContactUsModal
              open={contactModal.value}
              onClose={contactModal.setFalse}
              isDoctor={true}
            />
          </>
        )}
      </BooleanContainer>
      <MobileHeader area={area} showScheduleButton={props.showScheduleButton}>
        <DoctorMenuContentRows />
      </MobileHeader>
      <TabletHeader area={area} showScheduleButton={props.showScheduleButton}>
        <DoctorMenuContentRows />
      </TabletHeader>
    </>
  )
})
