import React, { useEffect, useState } from 'react'
import { Button, Body, SemiBold } from 'atomic'
import VisitCountDataSource from '@root/src/data/visit-count.datasource'
import SvgIcLogo from 'atomic/atm.svg-icon/ic-logo.component'
import Observer from 'react-intersection-observer'
import { BooleanContainer } from 'atomic/legacy/obj.abstract-container'
import { Drawer } from '../../atm.drawer'
import { HamburgerButton } from 'atomic/legacy/atm.hamburger-button'
import { Fade } from 'atomic/legacy/obj.animation/animation.component.style'
import { useDraw } from 'atomic/obj.custom-hooks/draw.hook'
import { Link } from 'gatsby'
import { ClientDoctorSelect } from 'utils/model/client-doctor-select'
import { DesktopTopHeaderLogoStyled } from '../client-doctor-header.component.style'
import { FlashMessage } from '../../legacy/mol.flash-message'
import { SchedulingModal } from '../../mol.scheduling-modal/scheduling-modal.component'

import {
  ButtonContainer,
  DrawerWrapperSyled,
  FlashMessageWrapperStyled,
  MenuMobileOpen
} from './mobile-header.component.style'
import {
  ClientDoctorSegmentedControl,
  getHomePathForClientDoctorSelect
} from './client-doctor-segmented-control.component'
import { SearchEverythingField } from './search-everything-field.component'
import { SearchContainer } from './search.container'
import { RelativeWrapper } from 'atomic/obj.wrappers'
import { DesktopAutocomplete } from './autocomplete.component'
import {
  HeaderTablet,
  ButtonExamResults,
  SearchContainerTablet,
  SegmentContainer,
  NewHeaderTabletContainer,
  NewHamburguerButtonContainer
} from './tablet-header.component.style'
import { externalPaths } from 'utils/path'
import { Grid } from '@material-ui/core'
import { MySchedules } from '../../mySchedules/MySchedules'

interface DesktopTopMenuHeaderPartialProps {
  area: ClientDoctorSelect
  showScheduleButton: boolean
}

export const TabletHeader: React.FunctionComponent<DesktopTopMenuHeaderPartialProps> = React.memo(
  props => {
    const isLoaded = useDraw()
    const [visitCount, setVisitCount] = useState(0)

    useEffect(() => {
      setVisitCount(VisitCountDataSource.visitCount)
    }, [])

    let handleFlashClick

    return (
      <Observer>
        <>
          <NewHeaderTabletContainer>
            <HeaderTablet>
              <Grid container spacing={1}>
                {props.showScheduleButton && props.area === ClientDoctorSelect.client ? (
                  <Grid className="LogoClientContainer" item sm>
                    <Link
                      id="logo"
                      to={getHomePathForClientDoctorSelect(props.area)}
                      aria-label="home"
                    >
                      <DesktopTopHeaderLogoStyled>
                        <SvgIcLogo height={32} />
                      </DesktopTopHeaderLogoStyled>
                    </Link>
                  </Grid>
                ) : (
                  <Grid className="LogoDoctorContainer" item sm>
                    <Link
                      id="logo"
                      to={getHomePathForClientDoctorSelect(props.area)}
                      aria-label="home"
                    >
                      <DesktopTopHeaderLogoStyled>
                        <SvgIcLogo height={32} />
                      </DesktopTopHeaderLogoStyled>
                    </Link>
                  </Grid>
                )}

                <Grid item sm>
                  <Fade show={isLoaded}>
                    <SegmentContainer>
                      <ClientDoctorSegmentedControl id="desktop" kind="default" area={props.area} />
                    </SegmentContainer>
                  </Fade>
                </Grid>

                <Grid item sm={2}>
                  <BooleanContainer>
                    {schedulingBool => (
                      <ButtonExamResults className="buttonfloat">
                        {props.showScheduleButton && props.area === ClientDoctorSelect.client ? (
                          <>
                            <Button
                              id="button-mobile-header-scheduling"
                              kind="primary"
                              onClick={schedulingBool.setTrue}
                              small
                            >
                              <ButtonContainer>
                              {process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'fleury' ?
                                'Agendar exames e vacinas' :
                                'Agendar'
                              }
                              </ButtonContainer>
                            </Button>
                          </>
                        ) : (
                          <ButtonExamResults className="buttonfloat doctorStyled">
                            <Button to={externalPaths.weinmannExamResultsDoctor.path}>
                              Resultado de exames
                            </Button>
                          </ButtonExamResults>
                        )}
                        <SchedulingModal
                          open={schedulingBool.value}
                          onClose={schedulingBool.setFalse}
                        />
                      </ButtonExamResults>
                    )}                  
                  </BooleanContainer>
                  
                </Grid>
                <Grid item sm={2}>
                  {process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'weinmann' && (                  
                    <MySchedules  />                  
                  )}
                </Grid>
                <Grid item sm={2}>
                  <BooleanContainer>
                    {bool => {
                      handleFlashClick = bool.setTrue
                      return (
                        <NewHamburguerButtonContainer>
                          <Body>Menu</Body>
                          <HamburgerButton active={false} onClick={bool.setTrue} />
                          <Drawer active={bool.value} area={props.area}>
                            <DrawerTemplateContent onCloseClick={bool.setFalse} area={props.area}>
                              {props.children}
                            </DrawerTemplateContent>
                          </Drawer>
                        </NewHamburguerButtonContainer>
                      )
                    }}
                  </BooleanContainer>
                </Grid>
              </Grid>
            </HeaderTablet>
            <SearchContainerTablet>
              <Fade className="searchTablet" show={isLoaded}>
                <SearchContainer isDoctor={props.area === ClientDoctorSelect.doctor}>
                  {(searchProps, searchState) => (
                    <RelativeWrapper>
                      <SearchEverythingField
                        id="desktop"
                        value={searchState.searchValue}
                        onValueChange={searchProps.handleTextChange}
                        onSubmit={searchProps.handleSubmit}
                        onFocus={searchProps.handleFocus}
                      />
                      <DesktopAutocomplete
                        search={searchState.searchValue}
                        opened={searchState.autocompleteOpen}
                        onCellClick={searchProps.handleSubmit}
                        onOverlayClick={searchProps.handleOverlayClick}
                        suggestions={searchProps.suggestions}
                      />
                    </RelativeWrapper>
                  )}
                </SearchContainer>
              </Fade>
            </SearchContainerTablet>
          </NewHeaderTabletContainer>
          {visitCount === 1 && props.area === ClientDoctorSelect.doctor && (
            <FlashMessageWrapperStyled onClick={handleFlashClick}>
              <FlashMessage type={'info-secondary'} dismissible={true}>
                <Body>
                  <SemiBold>Cliente?</SemiBold> Clique aqui para trocar de perfil.
                </Body>
              </FlashMessage>
            </FlashMessageWrapperStyled>
          )}

          {visitCount === 1 && props.area === ClientDoctorSelect.client && (
            <FlashMessageWrapperStyled onClick={handleFlashClick}>
              <FlashMessage type={'info'} dismissible={true}>
                <Body>
                  <SemiBold>Médico?</SemiBold> Clique aqui para trocar de perfil.
                </Body>
              </FlashMessage>
            </FlashMessageWrapperStyled>
          )}
        </>
      </Observer>
    )
  }
)

interface DrawerTemplateContentProps {
  onCloseClick: () => void
  area: ClientDoctorSelect
}
const DrawerTemplateContent: React.FunctionComponent<DrawerTemplateContentProps> = React.memo(
  props => {
    return (
      <MenuMobileOpen>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <HamburgerButton active={true} onClick={props.onCloseClick} />
          </Grid>
          <Grid item xs={12}>
            <DrawerWrapperSyled>{props.children}</DrawerWrapperSyled>
          </Grid>
        </Grid>
      </MenuMobileOpen>
    )
  }
)
