import styled, { keyframes } from 'styled-components'
import { ModalProps } from './modal.component'
import { Border, Color, Spacing } from 'atomic/legacy/obj.constants'

export const ModalStyled = styled.div`
  position: absolute;
  z-index: 99;
  visibility: ${(props: ModalProps) => (props.opened ? 'visible' : 'hidden')};
`

const slideDown = keyframes`
  0% {
    transform: translate(-50%, -100%);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const ModalOpacityStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 8;
  background-color: ${Color.Black};
  opacity: ${(props: ModalProps) => (props.opened ? '0.5' : '0')};
  transition: all 0.2s ease-out;
  visibility: ${(props: ModalProps) => (props.opened ? 'visible' : 'hidden')};
`

interface ModalBoxStyledProps {
  small?: boolean
  xSmall?: boolean
  noPadding?: boolean
}

export const ModalBoxStyled = styled.div`
  :before {
    content: '';
    position: absolute;
    top: 0;
    height: 90px;
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), transparent);
    pointer-events: none;
    z-index: 2;
    display: ${(props: ModalBoxStyledProps) => (props.noPadding ? 'block' : `none`)};
  }

  position: fixed;
  top: 6%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, 0);

  width: 90%;
  max-width: ${(props: ModalBoxStyledProps) => (props.small ? '550px' : props.xSmall ? '320px' : '90%')};
  max-height: 92%;
  overflow: ${(props: ModalBoxStyledProps) => (props.noPadding ? 'hidden' : `auto`)};
  overscroll-behavior: contain;
  background-color: ${Color.GrayMLight};
  border-radius: ${Border.Radius};
  animation: ${slideDown} 0.3s ease-out;
  padding: ${(props: ModalBoxStyledProps) => (props.noPadding ? '0' : `0 ${Spacing.Medium}`)};
  &.newWidth{
    max-width: ${(props: ModalBoxStyledProps) => (props.small ? '387px' : props.xSmall ? '345px' : '90%')};
    top: 10%;
  }
  @media (max-width: 768px) {
    max-width: 410px;
  }
`

export const ModalCloseStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  padding: ${Spacing.Large};
  color: ${Color.GrayDark};
  cursor: pointer;
`
