import { isNullOrUndefined } from 'util'

export const removeTelAreaCode = (phoneNumber: string) => {
  return phoneNumber.slice(4)
}

export const getTelLink = (phoneNumber: string) => {
  return `tel:${phoneNumber}`
}

export const parseNumber = (value: number, maximumFractionDigits: number = 0) => {
  if (isNullOrUndefined(value)) {
    console.error('parseNumber:invalid value')
    return null
  }

  try {
    const format = { maximumFractionDigits }
    return value.toLocaleString('pt-BR', format)
  } catch (error) {
    console.error('parseNumber:error: ', error)
    return value
  }
}
