import { AxiosInstance, AxiosPromise } from 'axios'
import axios from '@root/src/utils/base-axios.js'

export interface FetchDataSourceInput<TData = any, TParms = any> {
  method: 'get' | 'post'
  url: string
  data?: TData
  params?: TParms
  // headers: any
}
/**
 * Use this component when you need a flexible GET datasource
 * Tradeoffs:
 *  - PRO: flexibility
 *  - CONS: the response and params will NOT be typed (but you can use interfaces to help with this)
 */
export class FetchDataSource {
  private instance: AxiosInstance

  constructor() {
    // constructor(baseURL: string) {
    // this.instance = axios.create({
    //   baseURL
    // })
    this.instance = axios
  }

  fetch<TReturn, TData = undefined, TParams = undefined>(
    input: FetchDataSourceInput<TData, TParams>
  ): AxiosPromise<TReturn> {
    return this.instance(input)
  }
}
