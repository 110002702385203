import { FaIcon } from 'atomic/legacy/atm.fa-icon'
import * as React from 'react'
import { ModalBoxStyled, ModalCloseStyled, ModalOpacityStyled, ModalStyled } from './modal.component.style'
import { useLockBodyScroll } from 'atomic/obj.custom-hooks/lock-body-scroll.hook'

export interface ModalProps {
  small?: boolean
  xSmall?: boolean
  noPadding?: boolean
  opened?: boolean
  onClose?: () => void
}

export const Modal: React.FunctionComponent<ModalProps> = props => {
  useLockBodyScroll(props.opened)
  const handleClose = () => {
    if (props.onClose) {
      props.onClose()
    }
  }
  return (
    <ModalStyled opened={props.opened}>
      <ModalOpacityStyled opened={props.opened} onClick={handleClose} />
      {props.opened && (
        <ModalBoxStyled xSmall={props.xSmall} small={props.small} noPadding={props.noPadding}>
          <ModalCloseStyled>
            <FaIcon.Close onClick={handleClose} />
          </ModalCloseStyled>
          {props.children}
        </ModalBoxStyled>
      )}
    </ModalStyled>
  )
}
