import { BooleanContainer } from 'atomic/legacy/obj.abstract-container'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { ContactUsModal } from '../mol.contact-us-modal/contact-us-modal.component'
import { ClientDoctorSelect } from 'utils/model/client-doctor-select'
import { DesktopTopHeader } from './components/desktop-top-header.component'
import { ClientMenuContentRows } from './components/client-menu-content-rows.component'
import { getClientMenuItems } from './components/menu-items'
import { MobileHeader } from './components/mobile-header.component'
import { TabletHeader } from './components/tablet-header.component'
import { DesktopBottomHeaderNew } from './components/desktop-bottom-header-new.component'

export interface DoctorHeaderProps {
  showScheduleButton?: boolean
  location?: any
}

export const NewClientHeader: React.FunctionComponent<DoctorHeaderProps> = React.memo(props => {
  const area = ClientDoctorSelect.client
  const themeContext = useContext(ThemeContext)

  return (
    <>
      <DesktopTopHeader area={area} showScheduleButton={props.showScheduleButton} />
      <BooleanContainer>
        {schedulingModal => (
          <>
            <DesktopBottomHeaderNew
              {...getClientMenuItems(themeContext, schedulingModal.setTrue)}
            />
            <ContactUsModal
              open={schedulingModal.value}
              onClose={schedulingModal.setFalse}
              isDoctor={false}
            />
          </>
        )}
      </BooleanContainer>
      <MobileHeader area={area} showScheduleButton={props.showScheduleButton}>
        <ClientMenuContentRows />
      </MobileHeader>
      <TabletHeader area={area} showScheduleButton={props.showScheduleButton}>
        <ClientMenuContentRows />
      </TabletHeader>
    </>
  )
})
