import { Body } from 'atomic'
import * as React from 'react'
import { FlashMessage } from '../mol.flash-message'
import FlashDispatcherService, {
  FlashDispatcherListener,
  FlashMessageData
} from './flash-dispatcher.service'
import { FlashWrapperStyled } from './flash-wrapper.component.style'

interface FlashWrapperState {
  messageQueue: FlashMessageData[]
}

const flashDispatcher = FlashDispatcherService
/**
 * It is a wrapper that shows Flash Message on the window.
 * It also manages a queue of messages by listening to flashDispatcher
 */
export class FlashWrapper extends React.Component<any, FlashWrapperState>
  implements FlashDispatcherListener {
  constructor(props) {
    super(props)
    this.state = {
      messageQueue: []
    }
  }

  componentDidMount() {
    flashDispatcher.setListener(this)
  }

  componentWillUnmount() {
    flashDispatcher.setListener(null)
  }

  render() {
    return (
      <FlashWrapperStyled>
        {this.state.messageQueue.map((item, index) => (
          <FlashMessage
            type={item.type}
            dismissible={true}
            onClose={this.handleMessageClose(index)}
            key={`${item.time.getTime().toString()}`}
          >
            <Body>{item.message}</Body>
          </FlashMessage>
        ))}
      </FlashWrapperStyled>
    )
  }

  handleMessageClose = (index: number) => {
    return () => {
      const messageQueue = this.state.messageQueue
      messageQueue.splice(index, 1)
      this.setState({ messageQueue })
    }
  }

  // FlashDispatcherListener
  onReceiveMessage = (data: FlashMessageData) => {
    const messageQueue = this.state.messageQueue
    messageQueue.push(data)

    this.setState({ messageQueue })
  }
}
