import axios from 'axios'
import { updateAccessToken } from './oauth-sensedia'

const BASE_URL = process.env.GATSBY_FLEURY_BASE_URL
const CLIENTE_ID = process.env.GATSBY_FLEURY_CLIENT_ID_SENSEDIA
const CONTENT_TYPE = 'application/json'

const isTokenInSessionStorage = () => {
  if (typeof window !== 'undefined' && sessionStorage.getItem('access_token')) return true
  else return false
}

const getTokenFromSessionStorage = () => {
  if (isTokenInSessionStorage()) return sessionStorage.getItem('access_token')
  else return ''
}

const req = axios.create({
  baseURL: BASE_URL,
  defaultInterceptors: true
})

req.interceptors.request.use(conf => {
  let token = getTokenFromSessionStorage()
  conf.headers['access_token'] = token
  conf.headers['client_id'] = CLIENTE_ID
  conf.headers['Content-Type'] = CONTENT_TYPE
  return conf
})

req.interceptors.response.use(
  res => res,
  err => {
    if (err.config && err.response && err.response.status === 401) {
      return updateAccessToken().then(token => {
        err.config.headers['access_token'] = token
        sessionStorage.setItem('access_token', token.toString())
        return axios.request(err.config)
      })
    }
    return Promise.reject(err)
  }
)

export default req
