import { GridSettings, Spacing } from 'atomic'
import styled from 'styled-components'

export const DesktopSubmenuStyled = styled.div<{ actionColor: string }>`
  position: relative;
  color:white;
  :hover > div {
    background: ${props => props.theme.color.primary};
    color: white;
  }
`

export const DesktopSubmenuOriginStyled = styled.div`
  padding: ${Spacing.Small};

  @media (min-width:1360px) {
    padding: ${Spacing.Small};

  }
`

export const DesktopSubmenuListStyled = styled.div<{ actionColor: string }>`
  position: absolute;
  background: ${props => props.theme.color.accessory};
  min-width: 313px;
  padding: 0 ${Spacing.Medium};
  border-radius: 0 0 8px 8px;
  @media all and (max-width: ${GridSettings.flexboxgrid.breakpoints.lg}em) {
    right: 0;
  }
`
