import { TextField, TextFieldProps } from 'atomic/legacy/atm.text-field'
import { TextFieldNew } from 'atomic/legacy/atm.text-field-new'

import React from 'react'

export const SearchField = (props: TextFieldProps) => {
  return process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() !== 'weinmann' ? (
    //<TextField
    <TextFieldNew
      id="text-field-search-field"
      aria-label="busca"
      placeholder="Busque em todo o site"
      {...props}
      icon={'search'}
    />
  ) : (
    <TextFieldNew
      id="text-field-search-field"
      aria-label="busca"
      placeholder="Busque em todo o site"
      {...props}
      icon={'search'}
    />
  )
}
