import React from 'react'

const SvgIcCalendarioAgendar = props => (
  <svg 
    width="21" 
    height="21" 
    viewBox="0 0 21 21" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M14.083 10.6846C14.1434 10.743 14.1912 10.8124 14.2239 10.8888C14.2565 10.9651 14.2734 11.047 14.2734 11.1297C14.2734 11.2124 14.2565 11.2942 14.2239 11.3706C14.1912 11.447 14.1434 11.5164 14.083 11.5748L10.1963 15.3465C10.1362 15.405 10.0647 15.4515 9.98598 15.4832C9.90728 15.5149 9.82291 15.5312 9.73771 15.5312C9.6525 15.5312 9.56813 15.5149 9.48943 15.4832C9.41073 15.4515 9.33925 15.405 9.27908 15.3465L7.33572 13.4606C7.2755 13.4022 7.22772 13.3328 7.19512 13.2564C7.16253 13.1801 7.14575 13.0982 7.14575 13.0156C7.14575 12.9329 7.16253 12.8511 7.19512 12.7747C7.22772 12.6983 7.2755 12.6289 7.33572 12.5705C7.45736 12.4525 7.62233 12.3861 7.79435 12.3861C7.87953 12.3861 7.96387 12.4024 8.04256 12.4341C8.12126 12.4657 8.19276 12.512 8.25299 12.5705L9.73771 14.0126L13.1658 10.6846C13.226 10.6261 13.2974 10.5796 13.3761 10.5479C13.4548 10.5163 13.5392 10.4999 13.6244 10.4999C13.7096 10.4999 13.794 10.5163 13.8727 10.5479C13.9514 10.5796 14.0229 10.6261 14.083 10.6846V10.6846Z" fill="white"/>
    <path d="M4.83984 0.4375C5.00664 0.4375 5.1666 0.50376 5.28455 0.621702C5.40249 0.739645 5.46875 0.89961 5.46875 1.06641V1.69531H15.5312V1.06641C15.5312 0.89961 15.5975 0.739645 15.7155 0.621702C15.8334 0.50376 15.9934 0.4375 16.1602 0.4375C16.327 0.4375 16.4869 0.50376 16.6049 0.621702C16.7228 0.739645 16.7891 0.89961 16.7891 1.06641V1.69531H18.0469C18.7141 1.69531 19.3539 1.96035 19.8257 2.43212C20.2975 2.90389 20.5625 3.54375 20.5625 4.21094V18.0469C20.5625 18.7141 20.2975 19.3539 19.8257 19.8257C19.3539 20.2975 18.7141 20.5625 18.0469 20.5625H2.95312C2.28594 20.5625 1.64608 20.2975 1.17431 19.8257C0.702538 19.3539 0.4375 18.7141 0.4375 18.0469V4.21094C0.4375 3.54375 0.702538 2.90389 1.17431 2.43212C1.64608 1.96035 2.28594 1.69531 2.95312 1.69531H4.21094V1.06641C4.21094 0.89961 4.2772 0.739645 4.39514 0.621702C4.51308 0.50376 4.67305 0.4375 4.83984 0.4375V0.4375ZM2.95312 2.95312C2.61953 2.95312 2.2996 3.08564 2.06372 3.32153C1.82783 3.55741 1.69531 3.87734 1.69531 4.21094V18.0469C1.69531 18.3805 1.82783 18.7004 2.06372 18.9363C2.2996 19.1722 2.61953 19.3047 2.95312 19.3047H18.0469C18.3805 19.3047 18.7004 19.1722 18.9363 18.9363C19.1722 18.7004 19.3047 18.3805 19.3047 18.0469V4.21094C19.3047 3.87734 19.1722 3.55741 18.9363 3.32153C18.7004 3.08564 18.3805 2.95312 18.0469 2.95312H2.95312Z" fill="white"/>
    <path d="M3.7915 5.5734C3.7915 5.43441 3.85576 5.3011 3.97012 5.20282C4.08449 5.10453 4.23961 5.04932 4.40135 5.04932H16.5983C16.7601 5.04932 16.9152 5.10453 17.0295 5.20282C17.1439 5.3011 17.2082 5.43441 17.2082 5.5734V6.62158C17.2082 6.76058 17.1439 6.89388 17.0295 6.99217C16.9152 7.09045 16.7601 7.14567 16.5983 7.14567H4.40135C4.23961 7.14567 4.08449 7.09045 3.97012 6.99217C3.85576 6.89388 3.7915 6.76058 3.7915 6.62158V5.5734Z" fill="white"/>
  </svg>
)

export default SvgIcCalendarioAgendar
