let SvgFooterBackground
if (process.env.GATSBY_MARCA === 'FLEURY') {
  SvgFooterBackground = require('fleury/components/atm.svg-icon/footer-background.component').default
} else if (process.env.GATSBY_MARCA === 'WEINMANN') {
  SvgFooterBackground = require('weinmann/components/atm.svg-icon/footer-background.component').default
} else if (process.env.GATSBY_MARCA === 'FM') {
  SvgFooterBackground = require('felippe-mattoso/components/atm.svg-icon/footer-background.component').default
}

export default SvgFooterBackground
