import { CosmicjsInformacoesEstaticasMetadata } from '@root/src/data/graphql/graphql-types'
import { normalizeUrl } from 'utils/url'
import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { JsonLd, JsonLdProps } from './json-ld.component'
import { SocialMediaSEO, SocialMediaSEOProps } from './social-media-seo.component'
import useScript from '@root/src/utils/useScript'

/**
 * If your content is an article, you don't need to send jsonLd (just send the article data inside the socialMedia.article)
 */
export interface SEOProps extends JsonLdProps {
  socialMedia: SocialMediaSEOProps
}
export const SEO = (props: SEOProps) => {
  const fixedUrlProps = {
    ...props,
    socialMedia: {
      ...props.socialMedia,
      url: normalizeUrl(props.socialMedia.canonicalUrl)
    }
  }

  useScript({
    insertOnTop: true,
    where: 'head',
    type: 'script',
    children: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.GATSBY_GOOGLE_GTM_ID}');`
  })
  useScript({
    insertOnTop: true,
    where: 'body',
    type: 'noscript',
    children: `<iframe
				title='frame-gtm'
				src='https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GOOGLE_GTM_ID}'
				height='0'
				width='0'
				style='display:none;visibility:hidden'
			></iframe>`
  })

  // useScript({
  //   insertOnTop: true,
  //   where: 'head',
  //   type: 'script',
  //   children: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  //     (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  //     m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  //     })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

  //     ga('create', '${process.env.GATSBY_GOOGLE_ANALYTICS_ID}', 'auto');
  //     ga('send', 'pageview');`
  // })
  // useScript({
  //   insertOnTop: true,
  //   where: 'body',
  //   type: 'noscript',
  //   children: `<script async src="https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GOOGLE_ANALYTICS_ID}"/>
  //   <script>
  //     window.dataLayer = window.dataLayer || [];
  //     function gtag(){dataLayer.push(arguments);}
  //     gtag('js', new Date());
  //     gtag('config', '${process.env.GATSBY_GOOGLE_ANALYTICS_ID});
  //   </script>`
  // })

  const mainInfo = React.useMemo(
    () => (
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="language" content="Portuguese" />
        <title>{fixedUrlProps.socialMedia.title}</title>
        <meta name="image" content={fixedUrlProps.socialMedia.image} />
        {fixedUrlProps.socialMedia.description && (
          <meta name="description" content={fixedUrlProps.socialMedia.description} />
        )}
        <link rel="canonical" href={fixedUrlProps.socialMedia.url} />
      </Helmet>
    ),
    [
      fixedUrlProps.socialMedia.title,
      fixedUrlProps.socialMedia.image,
      fixedUrlProps.socialMedia.description,
      fixedUrlProps.socialMedia.url
    ]
  )

  return (
    <StaticQuery
      query={graphql`
        {
          cosmicjsInformacoesEstaticas(slug: { eq: "seo-geral" }) {
            metadata {
              nomeDaMarca
              logoDaMarca {
                url
              }
            }
          }
        }
      `}
      render={data => {
        const staticInfoSeo = data.cosmicjsInformacoesEstaticas
          .metadata as CosmicjsInformacoesEstaticasMetadata
        return (
          <>
            <SocialMediaSEO {...props.socialMedia} />
            {props.jsonld && <JsonLd jsonld={props.jsonld} />}
            {props.socialMedia.article && (
              <JsonLd
                jsonld={getArticleJsonld(props, {
                  name: staticInfoSeo.nomeDaMarca,
                  logoUrl: staticInfoSeo.logoDaMarca.url
                })}
              />
            )}
            {mainInfo}
          </>
        )
      }}
    />
  )
}

/**
 * https://developers.google.com/search/docs/data-types/article#logo_guidelines
 */
interface PublisherData {
  name: string
  logoUrl: string
}

const getArticleJsonld = (props: SEOProps, publisherData: PublisherData) => ({
  '@context': 'https://schema.org',
  '@type': 'NewsArticle',
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': props.socialMedia.canonicalUrl
  },
  articleSection: props.socialMedia.article.section,
  headline: props.socialMedia.title,
  image: [props.socialMedia.image],
  datePublished:
    props.socialMedia.article.published_time &&
    props.socialMedia.article.published_time.toISOString(),
  dateModified:
    props.socialMedia.article.modified_time &&
    props.socialMedia.article.modified_time.toISOString(),
  author: {
    '@type': 'Person',
    name: props.socialMedia.article.author.join(',')
  },
  publisher: {
    '@type': 'Organization',
    name: publisherData.name,
    logo: {
      '@type': 'ImageObject',
      url: publisherData.logoUrl
    }
  },
  description: props.socialMedia.description
})
