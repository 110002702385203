import React from 'react'

const SvgCheckin = props => (
	<svg width='36' height='36' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M12.6094 24C12.203 24 11.797 24 11.3906 24C11.2992 23.9841 11.2083 23.9648 11.1164 23.9522C10.5919 23.8805 10.0598 23.8425 9.54328 23.7337C7.05 23.2083 4.9275 22.0102 3.19641 20.1398C1.51781 18.3258 0.499687 16.1883 0.137812 13.7414C0.0848438 13.3809 0.0459375 13.0181 0 12.6562C0 12.2189 0 11.7811 0 11.3438C0.0159375 11.2608 0.0351562 11.1788 0.0464063 11.0948C0.117656 10.5778 0.155625 10.0542 0.262969 9.54469C0.788437 7.06031 1.97906 4.9425 3.84141 3.21422C5.66531 1.52156 7.81547 0.495937 10.2797 0.137812C10.6491 0.084375 11.0203 0.0454688 11.3906 0C11.797 0 12.203 0 12.6094 0C12.6928 0.0159375 12.7758 0.035625 12.8597 0.046875C13.3847 0.117188 13.9167 0.151406 14.4333 0.260156C16.9584 0.792656 19.1063 2.00719 20.8477 3.91313C22.508 5.73 23.5148 7.86422 23.8617 10.3045C23.9152 10.6814 23.9541 11.0602 24 11.438C24 11.813 24 12.188 24 12.563C23.9836 12.6619 23.9639 12.7603 23.9508 12.8597C23.8809 13.3842 23.8467 13.9167 23.738 14.4333C23.2092 16.9411 22.0078 19.0786 20.1216 20.8167C18.3052 22.4906 16.1672 23.5064 13.7198 23.8617C13.3505 23.9147 12.9797 23.9541 12.6094 24ZM22.133 12.0084C22.1377 6.40922 17.61 1.87359 12.0112 1.86844C6.41344 1.86328 1.87547 6.39281 1.86984 11.9916C1.86422 17.5884 6.39469 22.1264 11.9939 22.1316C17.5912 22.1372 22.1283 17.6072 22.133 12.0084Z'
			fill='#E60E64'
		/>
		<path
			d='M10.3469 13.8263C10.4242 13.7522 10.4917 13.6903 10.5569 13.6252C12.3306 11.8523 14.1053 10.0805 15.8767 8.30531C16.1664 8.01516 16.4861 7.84641 16.9061 7.97156C17.5141 8.15297 17.7634 8.87625 17.3922 9.39C17.3284 9.47813 17.2511 9.55688 17.1742 9.63422C15.1642 11.6461 13.1537 13.657 11.1433 15.668C10.6 16.2113 10.1078 16.2122 9.56638 15.6713C8.63779 14.7436 7.71107 13.8141 6.7806 12.8883C6.53404 12.6431 6.38216 12.3628 6.44357 12.0084C6.50544 11.6503 6.71216 11.3939 7.05998 11.2767C7.41669 11.1567 7.74388 11.2336 8.01201 11.4952C8.5042 11.9752 8.98654 12.465 9.47263 12.9511C9.75904 13.238 10.0454 13.5244 10.3469 13.8263Z'
			fill='#E60E64'
		/>
	</svg>
)

export default SvgCheckin
