import axios from 'axios'

const qs = require('qs')

const baseUrl = process.env.GATSBY_FLEURY_BASE_URL
const clientId = process.env.GATSBY_FLEURY_CLIENT_ID_SENSEDIA
const authorization = process.env.GATSBY_FLEURY_BASE64_AUTH

const grantCode = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
})

grantCode.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

const accessToken = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    client_id: clientId,
    Authorization: authorization
  }
})

accessToken.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

const callAccessToken = async () => {
  try {
    const response = await accessToken.post(
      '/oauth/access-token',
      qs.stringify({
        grant_type: 'implicit',
        client_id: clientId,
        redirect_uri: 'http://localhost'
      })
    )
    return response
  } catch (err) {
    return err
  }
}

const getExpireAcessTokenDate = seconds => {
  let dateNow = Date.now()
  dateNow += seconds * 1000
  sessionStorage.setItem('dateExpire', dateNow.toString())
}

const updateAccessToken = () =>
  new Promise((resolve, reject) => {
    callAccessToken().then(
      accessTokenResponse => {
        if (accessTokenResponse.status === 201) {
          getExpireAcessTokenDate(accessTokenResponse.data.expires_in)
          resolve(
            accessTokenResponse.data.redirect_uri.replace('http://localhost/?access_token=', '')
          )
        } else {
          reject()
        }
      },
      err => reject(err)
    )
  })
export { updateAccessToken }
