import React from 'react'

const SvgIcTelefone = props => (
  <svg viewBox="0 0 16 17" {...props}>
    <defs>
      <path
        d="M19.943 15.945c-.1-.163-.361-.26-.754-.456-.395-.195-2.33-1.14-2.69-1.27-.361-.13-.624-.196-.886.195-.263.391-1.017 1.27-1.247 1.53-.23.262-.46.295-.853.1-.394-.196-1.662-.609-3.165-1.94-1.17-1.034-1.96-2.312-2.19-2.704-.23-.39-.024-.602.172-.796.178-.175.394-.456.59-.684.198-.229.264-.391.394-.652.132-.26.066-.489-.033-.684-.098-.196-.886-2.118-1.214-2.9-.327-.781-.655-.651-.885-.651-.23 0-.492-.033-.755-.033-.262 0-.689.098-1.05.489C5.017 5.879 4 6.825 4 8.746c0 1.923 1.41 3.78 1.607 4.04.197.26 2.723 4.333 6.725 5.897 4.002 1.563 4.002 1.041 4.724.976.721-.065 2.328-.945 2.658-1.856.327-.913.327-1.695.23-1.858z"
        id="ic-telefone_svg__a"
      />
    </defs>
    <g transform="translate(-4 -4)" fillRule="nonzero">
      <mask id="ic-telefone_svg__b" fill="#fff">
        <use xlinkHref="#ic-telefone_svg__a" />
      </mask>
      <g mask="url(#ic-telefone_svg__b)" fill={props.fill}>
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
)

export default SvgIcTelefone
