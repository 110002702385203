import * as React from 'react'
import { DrawerContentStyled, DrawerMenuStyled, DrawerStyled } from './drawer.component.style'
import { ClientDoctorSelect } from 'utils/model/client-doctor-select'

export interface DrawerProps {
  active?: boolean
  area: ClientDoctorSelect
}

export class Drawer extends React.Component<DrawerProps, undefined> {
  static defaultProps = {
    active: false
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.active !== this.props.active
  }

  render() {
    return (
      <DrawerStyled active={this.props.active}>
        <DrawerMenuStyled active={this.props.active} area={this.props.area}>
          <DrawerContentStyled active={this.props.active}>
            {this.props.children}
          </DrawerContentStyled>
        </DrawerMenuStyled>
      </DrawerStyled>
    )
  }
}
