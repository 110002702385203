import styled from 'styled-components'
import { Color, Spacing, Border } from 'atomic'
import ColorFunc from 'color'

export const GoToTopButtonStyled = styled.div`
  background-color: ${Color.GrayXDark};
  height: 48px;
  padding: ${Spacing.Small};
  border-radius: 0 0 ${Border.Radius} ${Border.Radius};
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: ${ColorFunc(Color.GrayXDark)
      .darken(0.2)
      .hsl()
      .string()};
  }
  &:active {
    background-color: ${ColorFunc(Color.GrayXDark)
      .darken(0.3)
      .hsl()
      .string()};
  }
`
