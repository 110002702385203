import { Col, LargeSeparator, Row } from 'atomic'
import * as React from 'react'
// import styled from 'styled-components'
// import {LargeSeparatorContainer} from './'

interface LargeSeparatorRowProps {}

// Component created to supply the necessity of a single row Large Separator, this way the margin do not collapses or ignore the background
const LargeSeparatorRow: React.FunctionComponent<LargeSeparatorRowProps> = _props => {
  return (
    <Row>
      <Col xs>
        <LargeSeparator />
      </Col>
    </Row>
  )
}

export default LargeSeparatorRow
