import { useEffect, useState } from 'react'

/**
 * Hook to check if a view is drawn
 */
export const useDraw = () => {
  const [draw, setDraw] = useState(false)
  useEffect(() => setDraw(true))

  return draw
}
