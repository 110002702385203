import styled from 'styled-components'
import { headerBreakpoint } from '../legacy/org.header/header.component.style'
import { ClientDoctorSelect } from 'utils/model/client-doctor-select'
import { Spacing } from 'atomic'

const DesktopTopHeaderHeight = '72px'
export const DesktopTopHeaderStyled = styled.div`
  padding: 12px 0;
`

export const DesktopTopHeaderLogoStyled = styled.div`
  & > svg {
    overflow: visible;
    max-width: 20vw;
  }
`
export const ButtonContainer = styled.div`
  min-width: 110px;
`

export const DesktopBottomHeaderStyled = styled.div<{ area: ClientDoctorSelect }>`
  min-height: ${Spacing.XLarge};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  background: ${props =>
    props.area === ClientDoctorSelect.doctor
      ? props.theme.color.doctorAccessory
      : props.theme.color.clientAccessory};
  position: sticky;
  top: ${DesktopTopHeaderHeight};
  z-index: 96;
  width: 100%;

  display: none;
  @media all and (min-width: ${headerBreakpoint}) {
    display: flex;

    .MuiGrid-item {
      padding: 0;
    }
  }
`
