const fleuryIndexes =
  'fleury-unidades,fleury-exames,fleury-noticias,fleury-manuais-diagnosticos,fleury-manual-de-doencas'
const fmIndexes =
  'felippe-mattoso-unidades,felippe-mattoso-exames,felippe-mattoso-noticias,felippe-mattoso-revistas-medicas'
const weinmannIndexes =
  'weinmann-unidades,weinmann-exames,weinmann-noticias,weinmann-revistas-medicas'

export const getIndexes = () => {
  switch (process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase()) {
    case 'fleury':
      return fleuryIndexes
    case 'felippe-mattoso':
      return fmIndexes
    case 'weinmann':
      return weinmannIndexes
    default:
      return fleuryIndexes
  }
}
