import styled from 'styled-components'
import { Spacing } from 'atomic'

export const DesktopHeaderContainer = styled.div`
  display:none;
  width: 100%;
  height:72px;
  position:fixed;
  z-index:90;
  background-color: white;

  .MuiGrid-container{
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  @media (min-width:1023px) {
    display: flex;
  }

`
export const DesktopTopHeaderLogoStyled = styled.div`
  height: 73px;
  width: 100%;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${Spacing.Large};

  & > svg {
    overflow: visible;
    max-width: 20vw;
  }

  &.LogoClientContainer {
    display: flex;
    align-items: center;
    background: ${props => props.theme.color.primary};
    padding: ${Spacing.Large};
  }

  &.LogoDoctorContainer {
    display: flex;
    align-items: center;
    background: ${props => props.theme.color.tertiary};
    padding: ${Spacing.Large};
  }
`

export const SegmentContainer = styled.div`
  button {
    text-transform: uppercase;
  }
`
export const ButtonExamResults = styled.div`
  background: #e6f3f5;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 90;
  width: 100%;
  padding: 8px 0;

  a {
    background: ${props => props.theme.color.tertiary};
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const ButtonContainer = styled.div`
  min-width: 110px;
`

export const SearchContainerDesktop = styled.div`
  width: 100%;
  #form-search-everything {
    display: flex;
    flex-direction: row-reverse;
    padding: 0 22px;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    background-color: white;
    border: 1px solid #77787b;
    border-radius: 8px;

    input {
      color: #77787b;
      padding: 0;
      border: none;

      &:hover {
        box-shadow: unset;
        border: none;
      }
      ::placeholder {
        color: #77787b;
      }
    }

    svg {
      position: relative;
      width: 100%;
      display: flex;
      top: unset;
      left: unset;
      color: ${props => props.theme.color.primary};
    }
  }
`
export const ButtonDoctorStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  margin:0;
  a {
    background-color: #18b3b3;
  }

`
export const ButtonClientStyled = styled.div`
  display: flex;
  justify-content: flex-end;

`
export const DesktopBottomHeaderStyled = styled.div`
  display: none;
  min-height: ${Spacing.XLarge};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  align-items: center;
  background: #373a3a;
  position: sticky;
  top: 72px;
  z-index: 89;
  width: 100%;
  margin-bottom: 72px;

  .MuiGrid-container {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 16px;
  }

  .MuiGrid-item {
    padding: 0 !important;
  }

  @media all and (min-width: 1024px) {
    display: flex;

    .MuiGrid-container {
      grid-gap: 8px;
    }

  }

  @media (min-width: 1360px) {
    .MuiGrid-container {
      grid-gap: 16px;
    }
  }
`
