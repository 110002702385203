import styled from 'styled-components'
import { headerBreakpoint } from '../../legacy/org.header/header.component.style'

interface SearchStyledProps {
  opened: boolean
}

export const AutocompleteOverlayStyled = styled.div`
  display: flex;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${(props: SearchStyledProps) => (props.opened ? '1' : '0')};
  transition: 0.2s ease-in opacity;
  height: ${(props: SearchStyledProps) => (props.opened ? '100%' : '0')};
  overflow: visible;

  top: 128px;
  @media all and (min-width: ${headerBreakpoint}) {
    top: 72px;
  }
`

export const AutocompleteStyled = styled.div`
  position: fixed;
  top: 128px;
  @media all and (min-width: ${headerBreakpoint}) {
    position: absolute;
    top: 50px;
  }

  left: 0;
  width: 100%;
  z-index: 99;
  opacity: ${(props: SearchStyledProps) => (props.opened ? '1' : '0')};
  transition: 0.2s ease-in;
  height: ${(props: SearchStyledProps) => (props.opened ? 'auto' : '0')};
  pointer-events: none;
  overflow: auto;
  overscroll-behavior: contain;
`
