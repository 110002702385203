import React, { useEffect, useState } from 'react'
import { Body, Button } from 'atomic'
import { HamburgerButton } from 'atomic/legacy/atm.hamburger-button'
import { BooleanContainer } from 'atomic/legacy/obj.abstract-container'
import { SchedulingModal } from '../../mol.scheduling-modal/scheduling-modal.component'
import { Link } from 'gatsby'
import Observer from 'react-intersection-observer'
import { Drawer } from '../../atm.drawer'
import SvgIcMobileLogo from 'atomic/atm.svg-icon/ic-mobile-logo.component'
import { getHomePathForClientDoctorSelect } from './client-doctor-segmented-control.component'
import { ClientDoctorSelect } from 'utils/model/client-doctor-select'
import {
  DrawerWrapperSyled,
  SearchWrapperStyled,
  ButtonExamResults,
  NewHeaderMobile,
  NewHamburguerButtonContainer,
  MenuMobileOpen,
  NewHeaderMobileContainer,
  DrawerLogoWrapperDoctorSyled,
  DrawerLogoWrapperClientSyled,
  ButtonContainer
} from './mobile-header.component.style'
import { SearchEverythingField } from './search-everything-field.component'
import { SearchContainer } from './search.container'
import { externalPaths } from 'utils/path'

import { Grid } from '@material-ui/core'
import { MySchedules } from '../../mySchedules/MySchedules'

interface MobileHeaderProps {
  area: ClientDoctorSelect
  showScheduleButton: boolean

  /** send the drawer content here */
  children: JSX.Element
}

export const MobileHeader: React.FunctionComponent<MobileHeaderProps> = props => {
  let handleFlashClick
  const [isIphone, setIsIphone] = useState(false)

  useEffect(() => {
    const IS_IPAD = navigator.userAgent.match(/iPad/i) != null,
      IS_IPHONE = !IS_IPAD && (navigator.userAgent.match(/iPhone/i) != null || navigator.userAgent.match(/iPod/i) != null)
    
      setIsIphone(IS_IPHONE)          
  }, [])      
  
  return (
    <Observer>
      <>
        <NewHeaderMobileContainer style={{marginTop: isIphone ? -12 : 0 }}>
          <NewHeaderMobile>
            <Grid container justifyContent='space-between'>
              <Grid className="ContainerMenuButton" item xs={2}>
                <BooleanContainer>
                  {bool => {
                    /** due to performance improvement, handleFlashClick is set this way */
                    handleFlashClick = bool.setTrue
                    return (
                      <NewHamburguerButtonContainer>

                        <HamburgerButton active={false} onClick={bool.setTrue} />
                        <Drawer active={bool.value} area={props.area}>
                          <DrawerTemplateContent onCloseClick={bool.setFalse} area={props.area}>
                            {props.children}
                          </DrawerTemplateContent>
                        </Drawer>
                      </NewHamburguerButtonContainer>
                    )
                  }}
                </BooleanContainer>
              </Grid>
              <Grid item xs={5}>
                <Link
                  id="logo-mobile"
                  to={getHomePathForClientDoctorSelect(props.area)}
                  aria-label="home"
                >
                  {props.showScheduleButton && props.area === ClientDoctorSelect.client ? (
                    <DrawerLogoWrapperClientSyled>
                      <SvgIcMobileLogo height={32} />
                    </DrawerLogoWrapperClientSyled>
                  ) : (
                    <DrawerLogoWrapperDoctorSyled>
                      <SvgIcMobileLogo height={32} />
                    </DrawerLogoWrapperDoctorSyled>
                  )}
                </Link>
              </Grid>
              <Grid alignItems='flex-end' item xs={5} >
                <BooleanContainer>
                  {schedulingBool => (
                    <>
                      {props.showScheduleButton && props.area === ClientDoctorSelect.client ? (
                        <Button
                          id="button-mobile-header-scheduling"
                          kind="primary"
                          onClick={schedulingBool.setTrue}
                        //small
                        >
                          <ButtonContainer>
                          Agendar
                          </ButtonContainer>
                        </Button>
                      ) : (
                        <ButtonExamResults className="buttonfloat doctorStyled">
                          <Button to={externalPaths.weinmannExamResultsDoctor.path}>
                            Resultado de exames
                          </Button>
                        </ButtonExamResults>
                      )}
                      <SchedulingModal open={schedulingBool.value} onClose={schedulingBool.setFalse} />
                    </>
                  )}
                </BooleanContainer>
              </Grid>
            </Grid>
          </NewHeaderMobile>
          {process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'weinmann' && (
            <ButtonExamResults className="buttonfloat">
              <MySchedules />
            </ButtonExamResults>
          )}
        </NewHeaderMobileContainer>
      </>
    </Observer>
  )
}

interface DrawerTemplateContentProps {
  onCloseClick: () => void
  area: ClientDoctorSelect
}
const DrawerTemplateContent: React.FunctionComponent<DrawerTemplateContentProps> = React.memo(
  props => {
    return (
      <Observer>
        {({ ref }) => (
          <MenuMobileOpen>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <HamburgerButton active={true} onClick={props.onCloseClick} />
              </Grid>
              <Grid item xs={12}>
                <SearchContainer isDoctor={props.area === ClientDoctorSelect.doctor}>
                  {(searchProps, searchState) => (
                    <SearchWrapperStyled ref={ref} area={props.area}>
                      <SearchEverythingField
                        id="mobile"
                        value={searchState.searchValue}
                        onValueChange={searchProps.handleTextChange}
                        onSubmit={searchProps.handleSubmit}
                        // onFocus={setTrue}
                        light
                      />
                    </SearchWrapperStyled>
                  )}
                </SearchContainer>
              </Grid>
              <Grid item xs={12}>
                <DrawerWrapperSyled>{props.children}</DrawerWrapperSyled>
              </Grid>
            </Grid>
          </MenuMobileOpen>
        )}
      </Observer>
    )
  }
)
