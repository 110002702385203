import { HeaderHeight } from 'atomic'
import styled from 'styled-components'

export const FlashWrapperStyled = styled.div`
  z-index: 10;

  position: sticky;
  display: flex;
  flex-direction: column;

  width: 100%;

  top: ${HeaderHeight.Mobile}px;
  @media all and (min-width: 64em) {
    top: ${HeaderHeight.Desk}px;
  }
`
