import React, { useContext, useEffect, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'

import { Box, Button, Menu, MenuItem } from '@material-ui/core'
import { ChevronLeft, WhatsApp, Phone } from '@material-ui/icons'
//import OnboardingMySchedules from '../OnboardingMySchedules/OnboardingMySchedules'
import SvgComoPreparar from './ComoPreparar.js'
//import SvgVerAgendamentos from './VerAgendamentos.js'
import SvgExamesAgendados from './ExamesAgendados.js'

import Dialog from '@material-ui/core/Dialog'
import SvgIcWatch from 'atomic/atm.svg-icon/ic-watch'
import SvgReagendar from './Reagendar'
import SvgCancelar from './Cancelar'
import SvgCheckin from './Checkin'
import SvgIOS from './IOS'
import SvgAndroid from './Android'
import SvgExpandIcon from './ExpandIcon'
import SvgCloseWindow from './CloseWindow.js'
//import { getStorage, StorageType } from '@root/src/data/storage/storage.provider'

export const MySchedules: React.FunctionComponent = () => {
  const themeContext = useContext(ThemeContext)

  //const localStorage = getStorage(StorageType.Local)

  const whatsappLinksFleury = {
    whatsExamesAgendados:
      'https://api.whatsapp.com/send?phone=551131790822&text=Ol%C3%A1!%20Quero%20ver%20meus%20exames%20agendados',
    whatsComoMePreparar:
      'https://api.whatsapp.com/send?phone=551131790822&text=Ol%C3%A1!%20Quero%20saber%20como%20me%20preparar%20para%20os%20meus%20exames',
    whatsReagendarExames:
      'https://api.whatsapp.com/send?phone=551131790822&text=Ol%C3%A1!%20Quero%20reagendar%20um%20exame',
    whatsCancelarExames:
      'https://api.whatsapp.com/send?phone=551131790822&text=Ol%C3%A1!%20Quero%20cancelar%20um%20exame'
  }

  const whatsappLinksWeinmann = {
    whatsExamesAgendados:
      'https://api.whatsapp.com/send?phone=555140043080&text=Ol%C3%A1!%20Quero%20ver%20meus%20exames%20agendados',
    whatsComoMePreparar:
      'https://api.whatsapp.com/send?phone=555140043080&text=Ol%C3%A1!%20Quero%20saber%20como%20me%20preparar%20para%20os%20meus%20exames',
    whatsReagendarExames:
      'https://api.whatsapp.com/send?phone=555140043080&text=Ol%C3%A1!%20Quero%20reagendar%20um%20exame',
    whatsCancelarExames:
      'https://api.whatsapp.com/send?phone=555140043080&text=Ol%C3%A1!%20Quero%20cancelar%20um%20exame'
  }

  const whatsappLinksFelippe = {
    whatsExamesAgendados:
      'https://api.whatsapp.com/send?phone=552122668989&text=Ol%C3%A1!%20Quero%20ver%20meus%20exames%20agendados',
    whatsComoMePreparar:
      'https://api.whatsapp.com/send?phone=552122668989&text=Ol%C3%A1!%20Quero%20saber%20como%20me%20preparar%20para%20os%20meus%20exames',
    whatsReagendarExames:
      'https://api.whatsapp.com/send?phone=552122668989&text=Ol%C3%A1!%20Quero%20reagendar%20um%20exame',
    whatsCancelarExames:
      'https://api.whatsapp.com/send?phone=552122668989&text=Ol%C3%A1!%20Quero%20cancelar%20um%20exame'
  }

  const brandLinks = [
    {
      brand: 'fleury',
      directLink: [
        'https://agendamento.fleury.com.br/agendamentos',
        'https://agendamento.fleury.com.br/agendamentos',
        'https://agendamento.fleury.com.br/agendamentos',
        'https://agendamento.fleury.com.br/agendamentos'
      ],
      action3Link: '',
      action3Text: '',
      link: 'https://grupofleury.page.link/fleury',
      linkStoreIos: 'https://apps.apple.com/us/app/fleury-pacientes/id1465915999?l=pt&ls=1',
      linkStoreAndroid: 'https://play.google.com/store/apps/details?id=br.com.grupofleury.fleury',
      ...whatsappLinksFleury
    },
    {
      brand: 'weinmann',
      directLink: [
        'https://maisservicos.weinmann.com.br',
        'https://maisservicos.weinmann.com.br',
        'https://api.whatsapp.com/send?phone=555140043080&text=Ol%C3%A1!%20Quero%20reagendar%20um%20exame',
        'https://maisservicos.weinmann.com.br'
      ],
      action3Link: '',
      action3Text: '',
      link: 'https://grupofleury.page.link/weinmann',
      linkStoreIos: 'https://apps.apple.com/us/app/meu-weinmann/id1441969335?l=pt&ls=1',
      linkStoreAndroid: 'https://play.google.com/store/apps/details?id=br.com.grupofleury.weinmann',
      ...whatsappLinksWeinmann
    },
    {
      brand: 'felippe-mattoso',
      directLink: [
        'https://maisservicos.fmattoso.com.br',
        'https://maisservicos.fmattoso.com.br',
        'https://api.whatsapp.com/send?phone=552122668989&text=Ol%C3%A1!%20Quero%20reagendar%20um%20exame',
        'https://maisservicos.fmattoso.com.br'
      ],
      action3Link: 'tel:2122668989',
      action3Text: '(21) 2266-8989',
      link: 'https://grupofleury.page.link/felippemattoso',
      linkStoreIos: 'https://apps.apple.com/br/app/felippe-mattoso/id1442329956',
      linkStoreAndroid: 'https://play.google.com/store/apps/details?id=br.com.grupofleury.felippemattoso&hl=pt_BR',
      ...whatsappLinksFelippe
    }
  ]

  const adjustPositionMenu = process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'weinmann' ? 95 : 78

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [optionSelected, setOptionSelected] = useState(0)

  const [open, setOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  const brandOptions = brandLinks.find(
    x => x.brand === process.env.GATSBY_COSMIC_BUCKET.toLowerCase()
  )

  const ImageContent = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: 190px;
    margin-top: -8px;
  `

  const CloseIcon = styled.div`
    cursor: pointer;
    display: block;
    float: right;
    margin: 0;
    width: 14px;
  `

  const IconContainer = styled.div`
    padding: 16px;
    margin-top: -10px;
    svg {
      path {
        fill: ${themeContext.color.primary} !important;
      }
    }
    display: flex;
    justify-content: center;
  `

  const Title = styled.span`
    font-family: ${themeContext.typography.primary} !important;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #373f41;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 8px;
  `

  const TitleDownload = styled.span`
    font-family: ${themeContext.typography.primary} !important;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 8px;
    padding-bottom: 10px;
    color: ${themeContext.color.primary};
    border-bottom: 5px solid ${themeContext.color.primary};
  `

  const SvgOSContainer = styled.span`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    svg {
      margin: 8px;
      border: 0.5px solid ${themeContext.color.primary};
    }
  `

  const Text = styled.span`
    //display: flex;
    font-family: ${themeContext.typography.primary} !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #757575;
    margin: 4px;
    padding-bottom: 16px;
  `

  const InfoLabel = styled.span`
    display: flex;
    font-family: ${themeContext.typography.primary} !important;
    font-style: normal;
    font-size: 16px;
    text-align: center;
    border-radius: 7px !important;
    color: ${themeContext.color.primary} !important;
    background-color: ${themeContext.color.primaryLight} !important;
    margin-top: 10px;
    padding: 6px;
  `

  const CTAButton1 = styled(Button)`
    text-transform: initial !important;
    background-color: ${themeContext.color.primary} !important;
    color: #fff !important;
    width: 180px;
    padding: 1rem 2rem;
    border-radius: 30px !important;
    margin: 8px !important;
    height: 40px !important;
    font-family: ${themeContext.typography.primary} !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 16px !important;
  `

  const CTAButton2 = styled(Button)`
    text-transform: initial !important;
    color: ${themeContext.color.primary} !important;
    width: 180px;
    padding: 1rem 2rem;
    border-radius: 30px !important;
    margin: 8px !important;
    font-family: ${themeContext.typography.primary} !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 16px !important;

    svg {
      padding-right: 6px;
    }
  `

  const CTAButton3 = styled(Button)`
    text-transform: initial !important;
    color: ${themeContext.color.primary} !important;
    width: 200px;
    padding: 1rem 2rem;
    border-radius: 30px !important;
    border: 1px solid ${themeContext.color.primary} !important;
    margin: 8px !important;
    height: 40px !important;
    font-family: ${themeContext.typography.primary} !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 16px !important;
  `

  const BackButton = styled(Button)`
    text-transform: initial !important;
    color: ${themeContext.color.primary} !important;
    font-family: ${themeContext.typography.primary} !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    margin: 16px;

    svg {
      zoom: 0.8;
      padding-right: 6px;
    }
  `

  const MUIButton = styled(Button)`
    text-transform: initial !important;
    height: ${process.env.GATSBY_COSMIC_BUCKET.toLowerCase() !== 'weinmann' ? '50px' : null};
    font-family: ${themeContext.typography.primary} !important;
    svg {
      color: ${themeContext.color.primary};
    }
  `

  const MenuItemStyled = styled(MenuItem)`
    font-family: ${themeContext.typography.primary} !important;
  `

  const Separator = styled.div`
    color: ${props => props.theme.color.primary};
    background-color: ${props => props.theme.color.primary};
    height: 2px;
    width: 70%;
    border: 0;
    margin-left: 15%;
    margin-top: 8px;
  `

  const options: any = [
    {
      icon: <SvgComoPreparar />,
      title: 'Como me preparar',
      text: 'Por onde quer ver as instruções para seus exames?',
      action1Text: 'Ver no app',
      action1Link: brandOptions?.link,
      linkIos: brandOptions?.linkStoreIos,
      linkAndroid: brandOptions?.linkStoreAndroid,
      action2Text: (
        <>
          <WhatsApp /> Ver no WhatsApp
        </>
      ),
      action2Link: brandOptions?.whatsComoMePreparar
    },
    {
      icon: <SvgExamesAgendados />,
      title: 'Exames agendados',
      text: 'Por onde quer consultar seus agendamentos?',
      action1Text: 'Consultar no app',
      action1Link: brandOptions?.link,
      linkIos: brandOptions?.linkStoreIos,
      linkAndroid: brandOptions?.linkStoreAndroid,
      action2Text: (
        <>
          <WhatsApp /> Consultar no WhatsApp
        </>
      ),
      action2Link: brandOptions?.whatsExamesAgendados
    },
    {
      icon: <SvgReagendar />,
      title: 'Reagendar',
      text: 'Por onde quer reagendar?',
      action1Text: '',
      action1Link: '',
      linkIos: '',
      linkAndroid: '',
      action2Text: '',
      action2Link: '',
      action3Text: (
        <>
          <Phone fontSize="small" />
          &nbsp;{brandOptions?.action3Text}
        </>
      ),
      action3Link: brandOptions?.action3Link
    },
    {
      icon: <SvgCancelar />,
      title: 'Cancelar',
      text: 'Por onde quer cancelar?',
      action1Text: '',
      action1Link: '',
      linkIos: '',
      linkAndroid: '',
      action2Text: '',
      action2Link: '',
      action3Text: (
        <>
          <Phone fontSize="small" /> &nbsp; {brandOptions?.action3Text}
        </>
      ),
      action3Link: brandOptions?.action3Link
    },
    {
      icon: <SvgCheckin />,
      title: 'Check-in',
      text: (
        <>
          <p>
            Faça o seu check-in digital para <b>exames de sangue, urina e fezes</b> e agilize seu
            atendimento no dia do exame.
            <br />O check-in deve ser realizado <b>pelo menos 24h antes do exame</b>
          </p>
          <InfoLabel>
            <SvgIcWatch fill={themeContext.color.primary} width={20} />
            &nbsp;Não disponível para exames de imagem
          </InfoLabel>
        </>
      ),
      action3Text: 'Fazer check-in',
      action3Link: 'https://checkinac.weinmann.com.br'
    }
  ]

  const handleSelect = option => {
    if (brandOptions?.directLink[option] !== '' && option !== 4) {
      window.open(brandOptions?.directLink[option], brandOptions?.directLink[option]?.includes('maisservicos') ? '_self' : '_blank')
    } else {
      setOptionSelected(option)
      setOpen(true)
    }
    handleClose()
  }

  // const handleCloseMouseOut = (action: boolean) => {
  //   if (action) {
  //     setAnchorEl(null)
  //   }
  // }

  const handleClick = () => {
    const doc = document.getElementById('simple-menu')
    setAnchorEl(doc)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  //const visualizedOnboarding = localStorage.get('VisualizedOnboarding')

  const SimpleDialog = props => {
    const { onClose, open, optionSelected } = props
    const [openStoresPage, setOpenStoresPage] = useState(false)
    const [downloadDesktop, setDownloadDesktop] = useState(false)

    const handleClose = () => {
      onClose()
    }

    useEffect(() => {
      const IS_IPAD = navigator.userAgent.match(/iPad/i) != null,
        IS_IPHONE =
          !IS_IPAD &&
          (navigator.userAgent.match(/iPhone/i) != null ||
            navigator.userAgent.match(/iPod/i) != null),
        IS_IOS = IS_IPAD || IS_IPHONE,
        IS_ANDROID = !IS_IOS && navigator.userAgent.match(/android/i) != null,
        IS_MOBILE = IS_IOS || IS_ANDROID

      if (IS_MOBILE) {
        setDownloadDesktop(false)
        setIsMobile(true)
      } else {
        setDownloadDesktop(true)
        setIsMobile(false)
      }
    }, [])

    useEffect(() => {
      setOpenStoresPage(false)
    }, [open])

    return (
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        {!openStoresPage ? (
          <Box
            display="flex"
            flexDirection="column"
            flexWrap="wrap"
            width={270}
            alignItems="center"
            padding={2}
          >
            <ImageContent>
              <CloseIcon
                onClick={() => {
                  handleClose()
                }}
              >
                <SvgCloseWindow />
              </CloseIcon>
            </ImageContent>
            <IconContainer>{optionSelected?.icon}</IconContainer>
            <Title>{optionSelected?.title}</Title>
            <Text>{optionSelected?.text}</Text>
            {optionSelected?.action3Link ? (
              <>
                <CTAButton1
                  onClick={() => window.open(optionSelected?.action3Link, '_blank')}
                  variant="contained"
                  size="large"
                >
                  {optionSelected?.action3Text}
                </CTAButton1>
                {optionSelected?.action1Link && (
                  <CTAButton3
                    onClick={() => {
                      if (downloadDesktop) {
                        setOpenStoresPage(true)
                      } else {
                        window.open(optionSelected?.action1Link)
                      }
                    }}
                    variant="outlined"
                    size="large"
                  >
                    {optionSelected?.action1Text}
                  </CTAButton3>
                )}
              </>
            ) : (
              <CTAButton1
                onClick={() => {
                  if (downloadDesktop) {
                    setOpenStoresPage(true)
                  } else {
                    window.open(optionSelected?.action1Link)
                  }
                }}
                variant="contained"
                size="large"
              >
                {optionSelected?.action1Text}
              </CTAButton1>
            )}
            {optionSelected?.action2Text && (
              <CTAButton2
                size="large"
                onClick={() => window.open(optionSelected?.action2Link, '_blank')}
              >
                {optionSelected?.action2Text}
              </CTAButton2>
            )}
            <Box width="100%" display="flex" justifyContent="center">
              <BackButton
                onClick={() => {
                  handleClose()
                }}
              >
                Voltar
              </BackButton>
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            flexWrap="wrap"
            width={isMobile ? 260 : 290}
            alignItems="center"
            padding={3}
          >
            <TitleDownload>Baixe o aplicativo</TitleDownload>
            <SvgOSContainer>
              <a href={optionSelected.linkIos} target="_blank">
                <SvgIOS />
              </a>
              <a href={optionSelected.linkAndroid} target="_blank">
                <SvgAndroid />
              </a>
            </SvgOSContainer>
            <Box width="100%" display="flex" justifyContent="flex-start">
              <BackButton onClick={() => setOpenStoresPage(false)}>
                <ChevronLeft /> Voltar{' '}
              </BackButton>
            </Box>
          </Box>
        )}
      </Dialog>
    )
  }

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <SimpleDialog
          open={open}
          onClose={() => setOpen(false)}
          optionSelected={options[optionSelected]}
        />
        <MUIButton onClick={handleClick}>
          &nbsp;Agendamentos&nbsp;&nbsp;<SvgExpandIcon fill={themeContext.color.primary} />
        </MUIButton>
        <div id="simple-menu"></div>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{
            marginTop: isMobile ? adjustPositionMenu : 35,
            marginLeft: isMobile ? '22vw' : 0
          }}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <MenuItemStyled style={{ justifyContent: 'center' }} onClick={() => handleSelect(1)}>
            Exames agendados
          </MenuItemStyled>
          <MenuItemStyled style={{ justifyContent: 'center' }} onClick={() => handleSelect(0)}>
            Como me preparar
          </MenuItemStyled>
          <MenuItemStyled style={{ justifyContent: 'center' }} onClick={() => handleSelect(2)}>
            Reagendar exame
          </MenuItemStyled>
          <MenuItemStyled style={{ justifyContent: 'center' }} onClick={() => handleSelect(3)}>
            Cancelar exame
          </MenuItemStyled>
          {process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'weinmann' && (
            <>
              <Separator />
              <MenuItemStyled style={{ justifyContent: 'center' }} onClick={() => handleSelect(4)}>
                Check-in
              </MenuItemStyled>
            </>
          )}
        </Menu>
      </Box>
      {/* {!visualizedOnboarding && <OnboardingMySchedules />} */}
    </>
  )
}
