import { Color, Spacing } from 'atomic/legacy/obj.constants'
import { ClientDoctorSelect } from 'utils/model/client-doctor-select'
import styled from 'styled-components'
import { DrawerProps } from './drawer.component'

export const DrawerStyled = styled.div<{ active: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${props => (props.active ? '99' : '-1')};
  transition: ${props => (props.active ? 'none' : 'z-index .3s .3s')};
`

export const DrawerMenuStyled = styled.div<DrawerProps>`
  position: absolute;
  background-color: ${props => props.theme.color.primary};
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transform: ${props => (props.active ? 'translateX(-0)' : 'translateX(100%)')};
  padding: ${Spacing.Medium};
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overscroll-behavior: contain;
  transition: ${props => (props.active ? 'transform .3s' : 'transform .3s, z-index .3s .3s')};
  z-index: ${props => (props.active ? '2' : 'initial')};
`

export const DrawerContentStyled = styled.div<{ active: boolean }>`
  ${props =>
    props.active
      ? `
  transition: all 0.3s ease-in-out 0.1s;
    `
      : `
  opacity: 0;
  transform: translateY(100px);
  `};
`
