import React from 'react';

const SvgIcWhatsapp = props => (
  <svg viewBox="0 0 20 20" {...props}>
    <defs>
      <path
        d="M22 11.742c0 5.381-4.395 9.743-9.818 9.743a9.835 9.835 0 01-4.746-1.212L2 22l1.772-5.227a9.64 9.64 0 01-1.409-5.03C2.363 6.361 6.76 2 12.182 2 17.605 2 22 6.362 22 11.742zm-9.75-8.18c-4.515 0-8.188 3.645-8.188 8.126a8.04 8.04 0 001.56 4.763L4.6 19.47l3.147-1a8.183 8.183 0 004.505 1.343c4.515 0 8.188-3.644 8.188-8.124s-3.673-8.126-8.188-8.126zm4.89 10.424c-.06-.1-.221-.16-.462-.278-.24-.12-1.424-.698-1.644-.777-.22-.08-.382-.12-.542.12-.16.238-.621.776-.762.935-.14.16-.28.18-.522.06-.24-.119-1.015-.371-1.935-1.185-.715-.633-1.198-1.414-1.339-1.653-.14-.24-.014-.368.106-.487.108-.107.24-.279.361-.418.12-.14.16-.24.24-.399.081-.16.041-.298-.02-.418-.06-.12-.541-1.295-.742-1.773-.2-.478-.4-.398-.541-.398-.14 0-.3-.02-.461-.02-.16 0-.422.06-.642.299-.22.239-.842.816-.842 1.991 0 1.175.862 2.31.982 2.47.12.159 1.665 2.649 4.111 3.605 2.447.956 2.447.637 2.889.597.44-.04 1.423-.578 1.625-1.135.2-.558.2-1.036.14-1.136z"
        id="ic-whatsapp_svg__a"
      />
    </defs>
    <g transform="translate(-2 -2)" fillRule="evenodd">
      <mask id="ic-whatsapp_svg__b" fill="#fff">
        <use xlinkHref="#ic-whatsapp_svg__a" />
      </mask>
      <use
        fill={props.fill}
        fillRule="nonzero"
        xlinkHref="#ic-whatsapp_svg__a"
      />
    </g>
  </svg>
);

export default SvgIcWhatsapp;
