import React from 'react'

const SvgIcMessenger = props => (
  <svg viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M5.377 22.881v-4.262A9.287 9.287 0 012 11.526C2 6.273 6.557 2 12.159 2s10.159 4.273 10.159 9.526-4.557 9.527-10.159 9.527c-.89 0-1.775-.11-2.637-.326L5.379 22.88l-.002.001zm6.782-19.14c-4.642 0-8.418 3.492-8.418 7.785a7.55 7.55 0 003.039 5.987l.34.261v2.239l2.209-1.147.333.1c.813.232 1.654.35 2.5.349 4.641 0 8.417-3.492 8.417-7.785s-3.779-7.789-8.42-7.789z"
      fill={props.fill}
    />
    <path d="M6.117 14.464l5.08-5.38 2.493 2.495 4.451-2.495-5.024 5.441-2.43-2.615-4.57 2.554z" fill={props.fill} />
  </svg>
)

export default SvgIcMessenger
