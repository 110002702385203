import { fieldBorderCss, fieldCss } from 'atomic/legacy/obj.form/field.component.styled'

import styled from 'styled-components'

export const TextAreaStyled = styled.textarea`
  ${fieldBorderCss}
  ${fieldCss}
  overflow: auto;
  line-height: 1.5;
  padding: 0;
  padding-bottom: 12px;
  margin-top: 12px;
  min-height: 24px;
  max-width: 100%;
`
