import { graphql, useStaticQuery } from 'gatsby'

let mapToFooterContent
if (process.env.GATSBY_MARCA === 'FLEURY') {
  mapToFooterContent = require('fleury/components/org.footer/components/footer-content.utils')
    .mapToFooterContent
} else if (process.env.GATSBY_MARCA === 'WEINMANN') {
  mapToFooterContent = require('weinmann/components/org.footer/components/footer-content.utils')
    .mapToFooterContent
} else if (process.env.GATSBY_MARCA === 'FM') {
  mapToFooterContent = require('felippe-mattoso/components/org.footer/components/footer-content.utils')
    .mapToFooterContent
}

export const useFooterContent = (isDoctor: boolean) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
      allCosmicjsInstitucional {
        nodes {
          title
          slug
        }
      }
      appLinks: cosmicjsInformacoesEstaticas(slug: { eq: "footer" }) {
        metadata {
          appStoreLink
          playStoreLink
        }
      }
      contact: cosmicjsInformacoesEstaticas(slug: { eq: "informacoes-de-contato" }) {
        metadata {
          telefoneContato
          telefoneContatoMedico
          agendamentoWhatsappLink
          chatOnlineLinkMedico
          chatOnlineLink
          seo {
            imagem {
              url
            }
          }
        }
      }
    }
  `)

  const content = mapToFooterContent(data, isDoctor)

  return content
}
