import styled from 'styled-components'
import { Color } from 'atomic/legacy/obj.constants'
import { HamburgerButtonProps } from './hamburger-button.component'
import { highlightStyle } from 'atomic/legacy/obj.mixin'

export const HamburgerButtonStyled = styled.div`
  ${highlightStyle}
  display: inline-block;
  text-transform: none;
  transition: opacity 0.15s linear, filter 0.15s linear;
  border: 0;
  background-color: transparent;
  margin: 0;

  &:focus {
    outline: 0;
  }
`

export const HamburgerBoxStyled = styled.span`
  position: relative;
  display: inline-block;
  width: 25px;
  height: 14px;
`

export const HamburgerInnerStyled = styled.span`
  &,
  &:before,
  &:after {
    display: block;
    position: absolute;
    width: 25px;
    height: 2px;
    background-color: ${(props) => (props.active ? Color.White : props.theme.color.primary)};
    border-radius: 4px;
  }

  top: 50%;
  margin-top: -1px;
  transform: ${(props: HamburgerButtonProps) => (props.active ? 'rotate(225deg)' : 'none')};
  /* transition-delay: ${(props: HamburgerButtonProps) => (props.active ? '0.12s' : '0')};
  transition-timing-function: ${(props: HamburgerButtonProps) =>
    props.active ? 'cubic-bezier(0.215, 0.61, 0.355, 1)' : 'cubic-bezier(0.55, 0.055, 0.675, 0.19)'};
  transition-duration: 0.22s;
  transition: transform 0.15s ease; */

  &:before {
    content: '';
    top: ${(props: HamburgerButtonProps) => (props.active ? '0' : '-6px')};
    opacity: ${(props: HamburgerButtonProps) => (props.active ? '0' : '1')};
    /* transition: ${(props: HamburgerButtonProps) =>
      props.active
        ? 'top 0.1s ease-out, opacity 0.1s 0.12s ease-out'
        : 'transform .15s ease, top 0.1s 0.25s ease-in, opacity 0.1s ease-in;'}; */
  }

  &:after {
    content: '';
    bottom: ${(props: HamburgerButtonProps) => (props.active ? '0' : '-6px')};
    transform: ${(props: HamburgerButtonProps) => (props.active ? 'rotate(-90deg)' : 'none')};
    /* transition: ${(props: HamburgerButtonProps) =>
      props.active
        ? 'bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1)'
        : 'transform .15s ease, bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19)'}; */
  }
`
