import { Button } from 'atomic'
import React from 'react'
import { DesktopBottomHeaderStyled } from './desktop-header.component.style'
import { ClientDoctorSelect } from 'utils/model/client-doctor-select'
import { DesktopSubmenu, DesktopSubmenuProps } from './desktop-submenu.component'
import { Grid, Container } from '@material-ui/core'

export interface DesktopBottomHeaderProps {
  area: ClientDoctorSelect
  menus: any
  items: {
    title: string
    to?: string
    onClick?: () => any
    external?: boolean
    target?: '_self' | '_parent' | '_blank' | '_top'
  }[]
  otherItems: DesktopSubmenuProps
}

export const DesktopBottomHeaderNew = React.memo(
  (props: DesktopBottomHeaderProps) => {
    return (
      <DesktopBottomHeaderStyled>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            {props.menus.map(item =>
              item.items ? (
                <Grid item>
                  <DesktopSubmenu {...item}>
                    <Button id="button-more-options" kind="link" light to={item.to ? item.to : '#'}>
                      {item.title}
                    </Button>
                  </DesktopSubmenu>
                </Grid>
              ) : (
                <React.Fragment key={0}>
                  <Grid item>
                    <Button
                      id="button-title-desktop-header"
                      kind="link"
                      light
                      to={item.to}
                      onClick={item.onClick}
                      external={item.external}
                      target={item.target}
                      showActiveClass
                    >
                      {item.title}
                    </Button>
                  </Grid>
                </React.Fragment>
              )
            )}
          </Grid>
        </Container>
      </DesktopBottomHeaderStyled>
    )
  },
  // The desktop bottom header props will never change, not needing to be redraw
  () => true
)
