import React from 'react'

const SvgIcCheckin = props => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.49994 10.5L11.5651 3.49412C11.7993 2.95872 12.4408 2.73741 12.9553 3.01448L18.4998 6.00004"
      stroke="#fff"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.5 15.5V6H21.5V20.5H13.5V18.5"
      stroke="#fff"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.5 22C7 22 7.38455 19.3417 8.6651 19.1562C10.1764 18.9374 11.7158 18.8248 13.0517 18.5C14.7485 18.0874 16.1168 17.556 16.6812 17.3224C16.8646 17.2464 17.0172 17.1143 17.1089 16.9382C17.3906 16.3975 17.8951 15.205 16.9604 15.0547C15.7465 14.8594 11.9903 16.2916 10.0638 16.0312C8.1373 15.7709 8.81465 14.4687 9.42165 14.0781C11.07 13.0172 13 13.5 13 13.5V9C13 9 5.66675 10.875 3 14.0781"
      stroke="#fff"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.5 10.5H21.5"
      stroke="#fff"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export default SvgIcCheckin
