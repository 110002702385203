import { DefaultTheme } from 'styled-components'
import { Menu } from 'utils/model/menu'

export let getClientMenuItems: (theme: DefaultTheme, onContactClick: () => void) => Menu
export let getDoctorMenuItems: (onContactClick: () => void) => Menu

if (process.env.GATSBY_MARCA === 'FLEURY') {
  getClientMenuItems = require('fleury/components/org.menu/components/menu-items')
    .getClientMenuItems
  getDoctorMenuItems = require('fleury/components/org.menu/components/menu-items')
    .getDoctorMenuItems
} else if (process.env.GATSBY_MARCA === 'WEINMANN') {
  getClientMenuItems = require('weinmann/components/org.menu/components/menu-items')
    .getClientMenuItems
  getDoctorMenuItems = require('weinmann/components/org.menu/components/menu-items')
    .getDoctorMenuItems
} else if (process.env.GATSBY_MARCA === 'FM') {
  getClientMenuItems = require('felippe-mattoso/components/org.menu/components/menu-items')
    .getClientMenuItems
  getDoctorMenuItems = require('felippe-mattoso/components/org.menu/components/menu-items')
    .getDoctorMenuItems
}
