import { Button, Col, Row, Separator } from 'atomic'
import { useBooleanState } from 'atomic/obj.boolean-state/boolean-state.hook'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { ContactUsModal } from '../../mol.contact-us-modal/contact-us-modal.component'
import { SchedulingModal } from '../../mol.scheduling-modal/scheduling-modal.component'
import { ClientDoctorSegmentedControl } from './client-doctor-segmented-control.component'
import { ClientDoctorSelect } from 'utils/model/client-doctor-select'
import { getClientMenuItems } from './menu-items'

export const ClientMenuContentRows: React.FunctionComponent<any> = React.memo(_props => {
  const {
    value: isSchedulingOpen,
    setFalse: closeScheduling,
    setTrue: openScheduling
  } = useBooleanState(false)
  const { value: isContactOpen, setFalse: closeContact, setTrue: openContact } = useBooleanState(
    false
  )

  const themeContext = useContext(ThemeContext)
  const menuItems = getClientMenuItems(themeContext, openContact)

  return (
    <>
      <Row mb={true} mt>
        <Col>
          <ClientDoctorSegmentedControl id="menu" kind="light" area={ClientDoctorSelect.client} />
        </Col>
      </Row>
      <Separator />
      <Row mb={true} mt={true}>
        <Col xs={12}>
          <Button
            id="button-scheduling-client-menu-content"
            light
            onClick={openScheduling}
            kind="link"
          >
            {process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'fleury' ?
              'Agendar exames e vacinas' :
              'Agendar'
            }
          </Button>
        </Col>
      </Row>
      <SchedulingModal open={isSchedulingOpen} onClose={closeScheduling} />
      <ContactUsModal open={isContactOpen} onClose={closeContact} isDoctor={false} />

      {menuItems.items.map((item, index) => (
        <Row mb={true} key={item.title}>
          <Col xs={12}>
            <Button
              id={`button-title-client-menu-content-${index}`}
              kind="link"
              light
              external={item.external}
              onClick={item.onClick}
              to={item.to}
            >
              {item.title}
            </Button>
          </Col>
        </Row>
      ))}
      {menuItems.otherItems.items.map(item => (
        <Row mb={true} key={item.title}>
          <Col xs={12}>
            <Button
              id="button-title-client-menu"
              kind="link"
              light
              external={item.external}
              to={item.to}
            >
              {item.title}
            </Button>
          </Col>
        </Row>
      ))}
    </>
  )
})
