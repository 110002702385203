import React from 'react'

const SvgIcPhoneMobile = props => (  
  <svg 
    width="22" 
    height="22" 
    viewBox="0 0 22 22" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path 
      d="M20.9999 15.92V18.92C21.0011 19.1985 20.944 19.4741 20.8324 19.7293C20.7209 19.9845 20.5572 20.2136 20.352 20.4018C20.1468 20.5901 19.9045 20.7335 19.6407 20.8227C19.3769 20.9119 19.0973 20.945 18.8199 20.92C15.7428 20.5856 12.7869 19.5341 10.1899 17.85C7.77376 16.3146 5.72527 14.2661 4.18993 11.85C2.49991 9.24118 1.44818 6.27097 1.11993 3.17997C1.09494 2.90344 1.12781 2.62474 1.21643 2.3616C1.30506 2.09846 1.4475 1.85666 1.6347 1.6516C1.82189 1.44653 2.04974 1.28268 2.30372 1.1705C2.55771 1.05831 2.83227 1.00024 3.10993 0.999975H6.10993C6.59524 0.995198 7.06572 1.16705 7.43369 1.48351C7.80166 1.79996 8.04201 2.23942 8.10993 2.71997C8.23656 3.68004 8.47138 4.6227 8.80993 5.52997C8.94448 5.8879 8.9736 6.27689 8.89384 6.65086C8.81408 7.02482 8.6288 7.36809 8.35993 7.63998L7.08993 8.90997C8.51349 11.4135 10.5864 13.4864 13.0899 14.91L14.3599 13.64C14.6318 13.3711 14.9751 13.1858 15.3491 13.1061C15.723 13.0263 16.112 13.0554 16.4699 13.19C17.3772 13.5285 18.3199 13.7634 19.2799 13.89C19.7657 13.9585 20.2093 14.2032 20.5265 14.5775C20.8436 14.9518 21.0121 15.4296 20.9999 15.92Z" 
      stroke="#D31B50" 
      stroke-width="2" 
      stroke-linecap="round" 
      stroke-linejoin="round"
    />
  </svg>
)

export default SvgIcPhoneMobile
