import { Button } from 'atomic'
import * as React from 'react'
import SvgChevronDown from 'atomic/atm.svg-icon/ic-chevron-down.component'
import { GoToTopButtonStyled } from './go-to-top-button.component.styled'
import { hasWindow } from 'utils/browser'

export interface IGoToTopButtonProps {}

const GoToTopButton: React.FunctionComponent<IGoToTopButtonProps> = () => {
  const scrollTop = () => {
    scrollToTopAnimated()
  }
  return (
    <Button id="button-goto-top" kind="link" light>
      <GoToTopButtonStyled onClick={scrollTop}>
        <SvgChevronDown height={24} />
        Ir para o topo
      </GoToTopButtonStyled>
    </Button>
  )
}

export const scrollToTopAnimated = () => {
  if (hasWindow()) {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: 0
    })
  }
}

export default GoToTopButton
