import { InputDisabledCss, InputValueCss } from 'atomic/legacy/atm.typography'
import { Border, Color, FontSize } from 'atomic/legacy/obj.constants'
import { css } from 'styled-components'

export interface FieldProps {
  invalid?: boolean
  disabled?: boolean
  light?: boolean
}

const getBorderDefaultColor = (props: FieldProps) => (props.light ? Color.White : Color.Gray)
const getBorderFocusColor = props => (props.light ? Color.White : props.theme.color.primary)
const getBorderHoverColor = props => (props.light ? Color.White : props.theme.color.primary)

export const fieldBorder = Border.Width
export const fieldBorderCss = css`
  overflow: hidden;
  background: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: ${fieldBorder} solid;
  border-color: ${props =>
    props.invalid ? props.theme.color.alert : getBorderDefaultColor(props)};

  border-color: ${props =>
    props.invalid ? props.theme.color.alert : getBorderDefaultColor(props)};
  :focus {
    border-color: ${(props: FieldProps) => getBorderFocusColor(props)};
    /* Box-shadow instead of border-width, so the text won't dance */
    box-shadow: inset 0 -1px 0 0 ${(props: FieldProps) => getBorderFocusColor(props)};
  }
  @media all and (min-width: 48em) {
    :hover {
      border-color: ${(props: FieldProps) => getBorderHoverColor(props)};
      box-shadow: inset 0 -1px 0 0 ${(props: FieldProps) => getBorderHoverColor(props)};
    }
  }
`

export const fieldBorderCssNew = css`
  overflow: hidden;
  background: transparent;
  border: 0;
  border-radius: 8px;
  border: ${fieldBorder} solid;
  border-color: ${props =>
    props.invalid ? props.theme.color.alert : getBorderDefaultColor(props)};

  :focus {
    border-width: 2px;
    border-color: ${(props: FieldProps) => getBorderFocusColor(props)};
    /* Box-shadow instead of border-width, so the text won't dance */
    /* box-shadow: inset 0 -1px 0 0 ${(props: FieldProps) => getBorderFocusColor(props)}; */
  }

  @media all and (min-width: 48em) {
    :hover {
      border-color: ${(props: FieldProps) => getBorderHoverColor(props)};
      border: inset 0 -1px 0 0 ${(props: FieldProps) => getBorderHoverColor(props)};
    }
  }
`

export const fieldTypographyCss = css<FieldProps>`
  ${props => (props.disabled ? InputDisabledCss : InputValueCss)}
  font-family: ${props => props.theme.typography.primary};
  font-size: ${FontSize.Small};
`

// fieldHeight measured on run-time
export const fieldHeight = '16px'

export const fieldPadding = '12px'
export const fieldCss = css<FieldProps>`
  ${fieldTypographyCss}
  width: 100%;
  padding: ${fieldPadding} 0;

  align-self: stretch;
  background-color: transparent;

  :focus {
    outline: none;
  }

  /* https://stackoverflow.com/a/38573257/3670829 */
  -webkit-appearance: none;

  transition: all 0.3s;
`

export const fieldCssNew = css<FieldProps>`
  ${fieldTypographyCss}
  width: 100%;
  padding: ${fieldPadding};

  align-self: stretch;
  background-color: transparent;

  :focus {
    outline: none;
  }

  /* https://stackoverflow.com/a/38573257/3670829 */
  -webkit-appearance: none;

  transition: all 0.3s;
`
