import { Body, Button, Col, H1, H2, H4, Hbox, Row } from 'atomic'
import SvgIcChat from 'atomic/atm.svg-icon/ic-chat'
import SvgIcTelefone from 'atomic/atm.svg-icon/ic-telefone'
import SvgIcWhatsapp from 'atomic/atm.svg-icon/ic-whatsapp'
import { Modal } from 'atomic/legacy/obj.modal'
import { graphql, StaticQuery } from 'gatsby'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { getTelLink, removeTelAreaCode } from 'utils/number'
import { externalPaths } from 'utils/path'
import {
  AttendanceHours,
  ContactUsIconWrapper,
  ContactUsModalBody,
  ContactUsModalDetails,
  ContactUsModalStyled
} from './contact-us-modal.component.style'
import {
  ContactTextCustom,
  LineSeparator,
  SchedulingButton,
  ContactText,
  SchedulingLinkButton,
  ContactsWrapper,
  ContactIcon,
  ContactLabel
} from '../mol.scheduling-modal/scheduling-modal.component.style'
import SvgIcCalendarioAgendar from 'atomic/atm.svg-icon/ic-calendario-agendar'
import SvgIcPhoneMobile from 'atomic/atm.svg-icon/ic-phone-mobile'
// import { Grid } from 'react-styled-flexboxgrid'
// import { CosmicjsInformacoesEstaticasFieldsEnum } from '@root/src/data/graphql/graphql-types'

interface ContactUsModalProps {
  open: boolean
  onClose: () => void
  isDoctor: boolean
}

export const ContactUsModal: React.FunctionComponent<ContactUsModalProps> = props => {
  const themeContext = useContext(ThemeContext)
  const isFleury = process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'fleury'

  return (
    <StaticQuery
      query={graphql`
        {
          cosmicjsInformacoesEstaticas(slug: { eq: "informacoes-de-contato" }) {
            metadata {
              telefoneContato
              chatOnlineLink
              agendamentoWhatsappLink
              telefoneContatoMedico
              chatOnlineLinkMedico
              agendamentoWhatsappLinkMedico
              texto_fale_conosco
              show_whatsapp_contactus
              horarioAtendimento
              agende_link
            }
          }
        }
      `}
      render={schedulingData => {
        const {
          chatOnlineLinkMedico,
          telefoneContatoMedico,
          chatOnlineLink,
          agendamentoWhatsappLink,
          telefoneContato,
          show_whatsapp_contactus,
          horarioAtendimento,
          texto_fale_conosco,
          agende_link
        } = schedulingData.cosmicjsInformacoesEstaticas.metadata
        const links = props.isDoctor
          ? {
              onlineChat: {
                name: 'Chat Online',
                externalLink: chatOnlineLinkMedico
              },
              onlineWhats: {
                name: 'Whatsapp',
                externalLink: agendamentoWhatsappLink
              },
              // form: {
              //   name: 'Formulário Online',
              //   link: appPaths.contact.path.doctorUrl
              // },
              form: {
                name: 'Formulário Online',
                link: externalPaths.maisservicos.find(
                  x => x.brand === process.env.GATSBY_COSMIC_BUCKET.toLowerCase()
                ).path
              },

              phone: {
                //name: telefoneContatoMedico && removeTelAreaCode(telefoneContatoMedico),
                name: telefoneContatoMedico && telefoneContatoMedico,
                externalLink: getTelLink(telefoneContatoMedico)
              }
            }
          : {
              onlineChat: {
                name: 'Chat Online',
                externalLink: chatOnlineLink
              },
              onlineWhats: {
                name: 'Whatsapp',
                externalLink: agendamentoWhatsappLink
              },
              // form: {
              //   name: 'Formulário Online',
              //   link: appPaths.contact.path.clientUrl
              // },
              // form: {
              //   name: 'Formulário Online',
              //   link: externalPaths.maisservicos.find(
              //     x => x.brand === process.env.GATSBY_COSMIC_BUCKET.toLowerCase()
              //   ).path
              // },
              phone: {
                //name: telefoneContato && removeTelAreaCode(telefoneContato),
                name: telefoneContato && telefoneContato,
                externalLink: getTelLink(telefoneContato)
              }
            }

        if (isFleury) {
          return (
            <Modal xSmall onClose={props.onClose} opened={props.open}>
              <ContactUsModalStyled>
                <Row>
                  <Col xs={12}>
                    <H1 style={{ color: '#302A38', fontWeight: 'bold' }}>Fale Conosco</H1>
                    <ContactUsModalDetails
                      dangerouslySetInnerHTML={{
                        __html: texto_fale_conosco
                      }}
                    />

                    {agende_link && (
                      <ContactUsModalBody style={{ display: 'flex', justifyContent: 'center' }}>
                        <SchedulingButton
                          style={{ backgroundColor: '#D31B50' }}
                          onClick={() => window.open(agende_link, '_blank')}
                        >
                          <SvgIcCalendarioAgendar />
                          <ContactTextCustom style={{ marginLeft: '10px' }}>
                            Agendamento Digital
                          </ContactTextCustom>
                        </SchedulingButton>

                        <LineSeparator />

                        {agendamentoWhatsappLink && (
                          <SchedulingButton
                            style={{ backgroundColor: '#FFFFFF' }}
                            onClick={() => window.open(agendamentoWhatsappLink, '_blank')}
                          >
                            <SvgIcWhatsapp fill={'#D31B50'} width={22} />
                            <ContactText style={{ marginLeft: '10px' }}>WhatsApp</ContactText>
                          </SchedulingButton>
                        )}

                        <ContactsWrapper>
                          <SvgIcPhoneMobile width={22} />
                          <div style={{ flexDirection: 'column' }}>
                            <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                              <ContactText>(11) 3179-0822</ContactText>
                              <ContactLabel>Grande São Paulo</ContactLabel>
                            </Row>
                            <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                              <ContactText>0800 704 0822</ContactText>
                              <ContactLabel>Outras localidades</ContactLabel>
                            </Row>
                          </div>
                        </ContactsWrapper>

                        <ContactLabel
                          style={{
                            fontSize: '12px',
                            textAlign: 'center',
                            marginTop: '16px',
                            color: '#343838'
                          }}
                        >
                          Todos os dias, das 6h às 22h
                        </ContactLabel>
                      </ContactUsModalBody>
                    )}
                  </Col>
                </Row>
              </ContactUsModalStyled>
            </Modal>
          )
        }

        return (
          <Modal small onClose={props.onClose} opened={props.open}>
            <ContactUsModalStyled>
              <Row mb>
                <Col xs={12}>
                  <H2>Fale Conosco</H2>
                  <Body>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          schedulingData.cosmicjsInformacoesEstaticas.metadata.texto_fale_conosco
                      }}
                    />
                  </Body>
                </Col>
              </Row>
              {links.phone.name && (
                <ContactRow
                  target="_blank"
                  id="telefone"
                  title={links.phone.name}
                  icon={<SvgIcTelefone fill={themeContext.color.primary} width={24} />}
                  to={links.phone.externalLink}
                  external
                />
              )}

              {links.onlineChat.externalLink && (
                <ContactRow
                  target="_blank"
                  id="telefone"
                  title={links.onlineChat.name}
                  icon={<SvgIcChat fill={themeContext.color.primary} width={24} />}
                  to={links.onlineChat.externalLink}
                  external
                />
              )}

              {show_whatsapp_contactus && (
                <ContactRow
                  target="_blank"
                  title="Abrir WhatsApp"
                  id="whatsapp"
                  icon={<SvgIcWhatsapp fill={themeContext.color.primary} width={24} />}
                  to={links.onlineWhats.externalLink}
                  external
                />
              )}

              <AttendanceHours
                dangerouslySetInnerHTML={{
                  __html: horarioAtendimento
                }}
              />

              {/* PARA ADICIONAR O FORM, descomentar path.ts > linha 'contact: createClientDoctorPath' */}
              {/* {links.form.link && (
                <ContactRow
                  title={links.form.name}
                  target="_blank"
                  icon={<SvgIcFormulario fill={themeContext.color.primary} width={24} />}
                  to={links.form.link}
                  external
                />
              )} */}
            </ContactUsModalStyled>
          </Modal>
        )
      }}
    />
  )
}

interface ContactRowProps {
  title: string
  icon: JSX.Element
  to: string
  id: string
  external?: boolean
  target?: '_self' | '_parent' | '_blank' | '_top'
}

const ContactRow: React.FunctionComponent<ContactRowProps> = React.memo(props => {
  const brand = process.env.GATSBY_COSMIC_BUCKET.toLowerCase()
  return (
    <Row mb>
      <Col xs>
        {props.id === 'telefone' && brand === 'felippe-mattoso' ? (
          <Hbox vAlign="center">
            <Hbox.Item wrap>
              <ContactUsIconWrapper>{props.icon}</ContactUsIconWrapper>
            </Hbox.Item>
            <Hbox.Separator />
            <Hbox.Item vAlign="center">
              <H4 kind={'default'} cell>
                {props.title}
              </H4>
            </Hbox.Item>
          </Hbox>
        ) : (
          <Button
            id="button-contact-us-modal"
            kind="link"
            to={props.to}
            external={props.external}
            target={props.target}
          >
            <Hbox vAlign="center">
              <Hbox.Item wrap>
                <ContactUsIconWrapper>{props.icon}</ContactUsIconWrapper>
              </Hbox.Item>
              <Hbox.Separator />
              <Hbox.Item vAlign="center">
                <H4 kind={'default'} cell>
                  {props.title}
                </H4>
              </Hbox.Item>
            </Hbox>
          </Button>
        )}
      </Col>
    </Row>
  )
})

export default ContactRow
