import { Border, Color, FontSize, FontWeight, Spacing } from 'atomic/legacy/obj.constants'
import { highlightStyle } from 'atomic/legacy/obj.mixin'
import ColorFunc from 'color'
import styled, { DefaultTheme } from 'styled-components'
import { FlashMessageProps, FlashMessageType } from './flash-message.component'

export const FlashMessageContentStyled = styled.div`
  flex-grow: 99;
  width: 100%;
  text-align: center;
  margin: 0 ${Spacing.Small};
`

export const FlashMessageCloseStyled = styled.div<FlashMessageProps>`
  ${highlightStyle}
  margin-left: ${Spacing.Medium};
  margin-right: ${Spacing.Medium};
  margin-top: auto;

  &:hover > span {
    color: ${props =>
      ColorFunc(getFlashMessageTypesColors(props.type, props.theme))
        .darken(0.5)
        .string()};
  }
`

export const FlashIconStyled = styled.div<FlashMessageProps>`
  ${highlightStyle}
  margin-left: ${Spacing.Medium};
  margin-top: auto;

  &:hover > span {
    color: ${props =>
      ColorFunc(getFlashMessageTypesColors(props.type, props.theme))
        .darken(0.5)
        .string()};
  }
`

export const FlashMessageStyled = styled.div<FlashMessageProps>`
  background-color: ${props =>
    ColorFunc(getFlashMessageTypesColors(props.type, props.theme))
      .alpha(0.05)
      .hsl()
      .string()};

  margin-top: auto;
  padding: ${Spacing.Medium} 0 12px;
  border-radius: ${Border.Radius};
  line-height: 1.33;
  border: ${props =>
    `${Border.Width} solid ${getFlashMessageTypesColors(props.type, props.theme)}`};
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.Normal};
  font-size: ${FontSize.Small};
  transition: opacity 0.3s ease-in-out;

  svg {
    color: ${props => getFlashMessageTypesColors(props.type, props.theme)};
  }
`

export const getFlashMessageTypesColors = (type: FlashMessageType, theme: DefaultTheme) => {
  switch (type) {
    case 'warning':
      return theme.color.warning
    case 'success':
      return theme.color.success
    case 'info':
      return theme.color.accessory
    case 'info-secondary':
      return Color.GrayXDark
    case 'alert':
      return theme.color.alert
    default:
      return theme.color.accessory
  }
}
