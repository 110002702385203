import React from 'react'

const SvgIOS = props => (
	<svg width='160' height='50' viewBox='0 0 160 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M48.1834 17.288V9.92505H51.1447C52.5278 9.92505 53.3596 10.6289 53.3596 11.7616C53.3598 12.1337 53.2314 12.4944 52.9962 12.7827C52.7609 13.0709 52.4332 13.269 52.0686 13.3433V13.4309C52.5211 13.4521 52.9476 13.6489 53.2573 13.9794C53.5671 14.31 53.7359 14.7482 53.7278 15.2012C53.7278 16.4966 52.7837 17.2892 51.211 17.2892L48.1834 17.288ZM49.3273 13.0413H50.6845C51.6948 13.0413 52.2213 12.6686 52.2213 11.9704C52.2213 11.2968 51.7251 10.8927 50.8742 10.8927H49.3262L49.3273 13.0413ZM50.9809 16.3181C52.0114 16.3181 52.5525 15.8994 52.5525 15.1181C52.5525 14.3368 51.9912 13.9439 50.9101 13.9439H49.3262V16.317L50.9809 16.3181Z'
			fill='#626262'
		/>
		<path
			d='M54.9008 15.7162C54.9008 14.716 55.6451 14.139 56.9686 14.057L58.4728 13.9706V13.4901C58.4728 12.903 58.0855 12.5719 57.3345 12.5719C56.7227 12.5719 56.2995 12.7964 56.1772 13.1893H55.1152C55.2275 12.2351 56.1255 11.6177 57.385 11.6177C58.7782 11.6177 59.5651 12.3114 59.5651 13.4845V17.2878H58.5099V16.502H58.4223C58.2459 16.7817 57.9987 17.0097 57.7057 17.1628C57.4127 17.316 57.0844 17.389 56.7542 17.3743C56.5341 17.3966 56.3119 17.3754 56.1 17.3119C55.8882 17.2483 55.691 17.1436 55.5196 17.0038C55.3482 16.8641 55.206 16.6919 55.1012 16.4971C54.9964 16.3024 54.931 16.0889 54.9086 15.8689C54.9042 15.815 54.9008 15.7667 54.9008 15.7162ZM58.4728 15.2414V14.7777L57.1168 14.8642C56.3512 14.9158 56.0032 15.1763 56.0032 15.6668C56.0032 16.1574 56.4376 16.4526 57.0337 16.4526C57.3796 16.4874 57.7252 16.3834 57.9945 16.1634C58.2637 15.9434 58.4346 15.6255 58.4695 15.2795V15.2402L58.4728 15.2414Z'
			fill='#626262'
		/>
		<path
			d='M61.1426 10.0878C61.1376 9.99451 61.1511 9.90116 61.1823 9.8131C61.2134 9.72503 61.2617 9.64399 61.3243 9.57463C61.3869 9.50527 61.4625 9.44895 61.5469 9.40891C61.6313 9.36886 61.7228 9.34588 61.8161 9.34129C61.9094 9.33645 62.0027 9.35003 62.0907 9.38127C62.1787 9.41251 62.2597 9.46078 62.329 9.52334C62.3983 9.58589 62.4546 9.6615 62.4947 9.74583C62.5348 9.83017 62.5579 9.92158 62.5626 10.0148C62.5675 10.1081 62.5539 10.2014 62.5227 10.2894C62.4914 10.3774 62.4431 10.4584 62.3806 10.5277C62.318 10.597 62.2424 10.6533 62.1581 10.6934C62.0738 10.7335 61.9824 10.7566 61.8891 10.7613H61.8532C61.6748 10.7711 61.4999 10.7097 61.3669 10.5906C61.2338 10.4714 61.1536 10.3043 61.1437 10.126C61.1426 10.1136 61.1426 10.1035 61.1426 10.0878ZM61.3054 11.7313H62.3987V17.288H61.3054V11.7313Z'
			fill='#626262'
		/>
		<path
			d='M66.1592 15.3183L64.9435 17.2885H63.7401L65.5822 14.5213L63.7244 11.7317H65.0052L66.203 13.6906H66.2895L67.4727 11.7317H68.6929L66.8597 14.482L68.712 17.2952H67.4513L66.2412 15.3228L66.1592 15.3183Z'
			fill='#626262'
		/>
		<path
			d='M69.6785 15.7162C69.6785 14.716 70.4239 14.139 71.7463 14.057L73.2528 13.9706V13.4901C73.2528 12.903 72.8644 12.5719 72.1145 12.5719C71.5027 12.5719 71.0772 12.7964 70.9571 13.1893H69.894C70.0063 12.2351 70.9043 11.6177 72.165 11.6177C73.5581 11.6177 74.345 12.3114 74.345 13.4845V17.2878H73.2887V16.502H73.2023C73.0258 16.7821 72.7782 17.0104 72.4848 17.1636C72.1913 17.3168 71.8625 17.3895 71.5319 17.3743C71.3118 17.3966 71.0896 17.3754 70.8777 17.3119C70.6659 17.2483 70.4686 17.1436 70.2973 17.0038C70.1259 16.8641 69.9837 16.6919 69.8789 16.4971C69.7741 16.3024 69.7086 16.0889 69.6863 15.8689C69.6818 15.815 69.6785 15.7667 69.6785 15.7162ZM73.2516 15.2414V14.7777L71.8944 14.8642C71.1288 14.9158 70.782 15.1763 70.782 15.6668C70.782 16.1574 71.2153 16.4526 71.8125 16.4526C72.1584 16.4874 72.504 16.3836 72.7734 16.1639C73.0428 15.9441 73.214 15.6265 73.2494 15.2806V15.2402L73.2516 15.2414Z'
			fill='#626262'
		/>
		<path
			d='M76.0571 11.7314H77.1135V12.5835H77.1943C77.3126 12.2877 77.5216 12.0371 77.7913 11.8675C78.0609 11.6979 78.3773 11.618 78.6952 11.6394C78.8591 11.6373 79.0228 11.6528 79.1835 11.6854V12.7676C78.9823 12.7248 78.7773 12.7027 78.5717 12.7013C78.4013 12.6861 78.2297 12.7045 78.0664 12.7556C77.9032 12.8067 77.7517 12.8894 77.6204 12.9991C77.4892 13.1087 77.3808 13.2432 77.3015 13.3947C77.2223 13.5463 77.1736 13.7119 77.1584 13.8823C77.1561 13.9242 77.1561 13.9661 77.1584 14.008V17.2882H76.0571V11.7314Z'
			fill='#626262'
		/>
		<path
			d='M83.4145 11.7322H84.4709V12.6134H84.554C84.6926 12.2961 84.9269 12.03 85.2242 11.8525C85.5215 11.675 85.8669 11.5948 86.212 11.6233C86.6898 11.5874 87.1623 11.7427 87.5257 12.0551C87.889 12.3675 88.1134 12.8114 88.1496 13.2892C88.1597 13.4225 88.1552 13.5565 88.1361 13.6888V17.2878H87.0393V13.965C87.0393 13.0669 86.6521 12.6269 85.8404 12.6269C85.5027 12.611 85.1725 12.73 84.9224 12.9575C84.6723 13.1851 84.5228 13.5026 84.5068 13.8404C84.5018 13.905 84.5018 13.97 84.5068 14.0346V17.2901H83.4101L83.4145 11.7322Z'
			fill='#626262'
		/>
		<path
			d='M89.5157 15.7162C89.5157 14.716 90.2611 14.139 91.5835 14.057L93.0889 13.9706V13.4901C93.0889 12.903 92.7005 12.5719 91.9506 12.5719C91.3388 12.5719 90.9133 12.7964 90.7921 13.1893H89.7301C89.8424 12.2351 90.7405 11.6177 92.0011 11.6177C93.3942 11.6177 94.1789 12.3114 94.1789 13.4845V17.2878H93.1203V16.502H93.0339C92.8574 16.7821 92.6098 17.0104 92.3164 17.1636C92.023 17.3168 91.6942 17.3895 91.3635 17.3743C90.919 17.4165 90.4759 17.2804 90.1317 16.996C89.7876 16.7116 89.5704 16.3021 89.5281 15.8577C89.5191 15.8116 89.5157 15.7645 89.5157 15.7162ZM93.0889 15.2414V14.7777L91.7317 14.8642C90.9661 14.9158 90.6192 15.1763 90.6192 15.6668C90.6192 16.1574 91.0525 16.4526 91.6497 16.4526C91.9957 16.4874 92.3413 16.3834 92.6105 16.1634C92.8798 15.9434 93.0506 15.6255 93.0855 15.2795V15.2402L93.0889 15.2414Z'
			fill='#626262'
		/>
		<path
			d='M57.9015 32.6494H52.0641L50.653 36.7929H48.1834L53.7177 21.4663H56.2884L61.8216 36.7929H59.3036L57.9015 32.6494ZM52.6647 30.7411H57.2953L55.0131 24.0179H54.9491L52.6647 30.7411Z'
			fill='#626262'
		/>
		<path
			d='M73.7704 31.2076C73.7704 34.6808 71.9114 36.9103 69.1061 36.9103C68.3959 36.9476 67.6896 36.7842 67.068 36.4387C66.4464 36.0933 65.9346 35.5798 65.5913 34.957H65.5385V40.4913H63.2406V25.6171H65.4611V27.4761H65.5026C65.8616 26.8558 66.3822 26.3444 67.0088 25.9965C67.6355 25.6485 68.3448 25.4771 69.0612 25.5004C71.9035 25.5004 73.7704 27.7433 73.7704 31.2076ZM71.413 31.2076C71.413 28.9456 70.2433 27.4582 68.4595 27.4582C66.7071 27.4582 65.5284 28.977 65.5284 31.2076C65.5284 33.4595 66.7071 34.9671 68.4595 34.9671C70.2433 34.9637 71.413 33.4875 71.413 31.2076Z'
			fill='#626262'
		/>
		<path
			d='M86.0693 31.2076C86.0693 34.6809 84.2104 36.9103 81.405 36.9103C80.6949 36.9476 79.9885 36.7842 79.3669 36.4388C78.7453 36.0933 78.2336 35.5798 77.8902 34.957H77.8375V40.4913H75.5429V25.6172H77.7634V27.4761H77.8049C78.1639 26.8557 78.6844 26.3442 79.3111 25.9963C79.9378 25.6484 80.6471 25.477 81.3635 25.5004C84.1991 25.5004 86.0693 27.7433 86.0693 31.2076ZM83.7119 31.2076C83.7119 28.9456 82.5433 27.4582 80.7596 27.4582C79.0072 27.4582 77.8285 28.977 77.8285 31.2076C77.8285 33.4595 79.0072 34.9671 80.7596 34.9671C82.5388 34.9671 83.7108 33.4898 83.7108 31.2076H83.7119Z'
			fill='#626262'
		/>
		<path
			d='M94.1968 32.5213C94.3674 34.0402 95.8436 35.0392 97.8609 35.0392C99.7939 35.0392 101.185 34.0413 101.185 32.6706C101.185 31.4807 100.345 30.769 98.3593 30.2795L96.3734 29.8024C93.558 29.1221 92.2525 27.8054 92.2525 25.6702C92.2525 23.0254 94.5571 21.2102 97.8272 21.2102C101.067 21.2102 103.287 23.0254 103.363 25.6702H101.047C100.909 24.1413 99.6435 23.2174 97.7913 23.2174C95.939 23.2174 94.6795 24.1514 94.6795 25.5108C94.6795 26.5941 95.4866 27.2328 97.4612 27.72L99.1451 28.1343C102.288 28.8785 103.594 30.1414 103.594 32.3821C103.599 35.2492 101.315 37.0453 97.6835 37.0453C94.2843 37.0453 91.9898 35.2918 91.8461 32.5191L94.1968 32.5213Z'
			fill='#626262'
		/>
		<path
			d='M108.559 22.9722V25.617H110.683V27.4333H108.559V33.5929C108.559 34.5493 108.984 34.9961 109.917 34.9961C110.17 34.9914 110.422 34.9738 110.673 34.9433V36.7485C110.253 36.8289 109.825 36.8666 109.398 36.8607C107.136 36.8607 106.254 36.0098 106.254 33.8432V27.4445H104.629V25.617H106.254V22.9722H108.559Z'
			fill='#626262'
		/>
		<path
			d='M111.913 31.2081C111.913 27.6933 113.983 25.4829 117.213 25.4829C120.443 25.4829 122.513 27.6921 122.513 31.2081C122.513 34.724 120.463 36.9332 117.213 36.9332C113.963 36.9332 111.913 34.7307 111.913 31.2081ZM120.177 31.2081C120.177 28.7968 119.071 27.3733 117.213 27.3733C115.355 27.3733 114.248 28.808 114.248 31.2081C114.248 33.6295 115.354 35.0428 117.213 35.0428C119.072 35.0428 120.174 33.6238 120.174 31.2081H120.177Z'
			fill='#626262'
		/>
		<path
			d='M124.405 25.617H126.592V27.5187H126.645C126.793 26.9232 127.141 26.397 127.632 26.0288C128.123 25.6607 128.726 25.4732 129.339 25.498C129.603 25.4971 129.867 25.5261 130.125 25.5845V27.7275C129.791 27.6257 129.443 27.5791 129.094 27.5894C128.79 27.5765 128.487 27.6236 128.201 27.7283C127.915 27.8329 127.653 27.9928 127.429 28.199C127.205 28.4052 127.024 28.6535 126.897 28.9297C126.769 29.206 126.697 29.5047 126.685 29.8087C126.681 29.9275 126.685 30.0465 126.699 30.1646V36.7878H124.405V25.617Z'
			fill='#626262'
		/>
		<path
			d='M140.694 33.5114C140.385 35.5388 138.409 36.9319 135.881 36.9319C132.626 36.9319 130.614 34.7541 130.614 31.2595C130.614 27.7649 132.647 25.4805 135.79 25.4805C138.882 25.4805 140.826 27.6033 140.826 30.9923V31.7781H132.935V31.9173C132.901 32.2981 132.942 32.6819 133.057 33.0466C133.172 33.4113 133.357 33.7499 133.603 34.043C133.848 34.336 134.149 34.5778 134.488 34.7545C134.827 34.9313 135.197 35.0394 135.578 35.0729C135.698 35.0832 135.819 35.0862 135.94 35.0819C136.482 35.133 137.026 35.0076 137.491 34.7244C137.957 34.4413 138.318 34.0154 138.522 33.5103L140.694 33.5114ZM132.94 30.1751H138.526C138.565 29.4638 138.32 28.7663 137.845 28.2351C137.37 27.7039 136.705 27.3823 135.994 27.3406C135.925 27.3406 135.858 27.3406 135.79 27.3406C135.419 27.3373 135.05 27.4074 134.706 27.5467C134.362 27.6861 134.048 27.892 133.783 28.1526C133.519 28.4133 133.308 28.7237 133.163 29.0659C133.018 29.4081 132.943 29.7755 132.94 30.147V30.1807V30.1751Z'
			fill='#626262'
		/>
		<path
			d='M38.1666 23.8624C38.1803 22.7912 38.4648 21.7409 38.9935 20.8091C39.5223 19.8774 40.2782 19.0946 41.1909 18.5336C40.6111 17.7055 39.8463 17.024 38.9571 16.5433C38.0679 16.0625 37.0789 15.7958 36.0685 15.7642C33.9132 15.5396 31.8241 17.054 30.7262 17.054C29.6036 17.054 27.9197 15.7866 26.0955 15.8192C24.9176 15.8566 23.7694 16.1987 22.763 16.8122C21.7567 17.4256 20.9265 18.2894 20.3535 19.3194C17.8715 23.6222 19.7227 29.9367 22.098 33.4167C23.288 35.1185 24.68 37.0179 26.4963 36.9506C28.2756 36.8776 28.9413 35.8168 31.0899 35.8168C33.2385 35.8168 33.8436 36.9506 35.6992 36.9091C37.6076 36.8787 38.8121 35.1994 39.965 33.4829C40.819 32.2703 41.4766 30.9308 41.9138 29.5135C40.803 29.044 39.8552 28.2576 39.1887 27.2526C38.5223 26.2475 38.1668 25.0684 38.1666 23.8624Z'
			fill='#1A1A1A'
		/>
		<path
			d='M34.6597 13.4802C35.7027 12.2304 36.217 10.6231 36.0932 9C34.5015 9.16731 33.0314 9.929 31.9767 11.1329C31.4607 11.7201 31.0653 12.4032 30.8134 13.1433C30.5615 13.8833 30.4578 14.6657 30.5084 15.4458C31.304 15.4537 32.091 15.2809 32.8101 14.9404C33.5292 14.5999 34.1616 14.1006 34.6597 13.4802V13.4802Z'
			fill='#1A1A1A'
		/>
	</svg>
)

export default SvgIOS
