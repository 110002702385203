let SvgIcLogo
if (process.env.GATSBY_MARCA === 'FLEURY') {
  SvgIcLogo = require('fleury/components/atm.svg-icon/ic-logo.component').default
} else if (process.env.GATSBY_MARCA === 'WEINMANN') {
  SvgIcLogo = require('weinmann/components/atm.svg-icon/ic-logo.component').default
} else if (process.env.GATSBY_MARCA === 'FM') {
  SvgIcLogo = require('felippe-mattoso/components/atm.svg-icon/ic-logo.component').default
}

export default SvgIcLogo
