import { Color, Spacing } from 'atomic'
import { highlightStyle, onlyMobileStyle } from 'atomic/legacy/obj.mixin'
import styled, { css } from 'styled-components'
import { headerBreakpoint } from '../../legacy/org.header/header.component.style'
import { ClientDoctorSelect } from 'utils/model/client-doctor-select'

export const DrawerWrapperSyled = styled.div`
  padding-left: 48px;
  height: 100%;
  width: 99%;
`

export const DrawerLogoWrapperSyled = styled.div`
  & > svg {
    max-width: 25vw;
    overflow: visible;
  }
`

const searchStyle = css<{ area }>`
  @media all and (min-width: ${headerBreakpoint}) {
    display: none;
  }
  background: ${props =>
    props.area === ClientDoctorSelect.client ? props.theme.color.primary : Color.GrayDark};
  width: 100%;
  z-index: 99;
  padding: ${Spacing.Small} ${Spacing.Large};
  box-shadow: 0px 2px 2px ${Color.GrayXDark};
`

export const SearchWrapperStyled = styled.div`
  ${searchStyle}
`

export const FixedSearchWrapperStyled = styled.div`
  ${searchStyle}
  position: fixed;
`

export const FlashMessageWrapperStyled = styled.div`
  ${highlightStyle}
  ${onlyMobileStyle}
`

export const ButtonContainer = styled.div`
  min-width: 80px;
`