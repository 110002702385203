import React from 'react'

const SvgComoPreparar = props => (
	<svg width='29' height='32' viewBox='0 0 29 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M12.3346 5.62741C12.0996 5.39243 11.7304 5.39243 11.4954 5.62741L8.40709 8.71569L6.99721 7.30582C6.76224 7.07085 6.39298 7.07085 6.15801 7.30582L5.58734 7.87649C5.38593 8.0779 5.35236 8.44715 5.55377 8.68213L7.9707 11.6697C8.20568 11.9718 8.64207 11.9718 8.87704 11.6697L12.9052 7.00371C13.1067 6.76873 13.1067 6.43305 12.8717 6.19807L12.3346 5.62741Z'
			fill='#D31B50'
		/>
		<path
			d='M23.2778 8.07715H14.785C14.5836 8.07715 14.4158 8.24499 14.4158 8.4464V9.92341C14.4158 10.1248 14.5836 10.2927 14.785 10.2927H23.2443C23.4457 10.2927 23.6135 10.1248 23.6135 9.92341V8.4464C23.6135 8.21142 23.4792 8.07715 23.2778 8.07715Z'
			fill='#D31B50'
		/>
		<path
			d='M12.3347 12.979C12.0997 12.744 11.7305 12.744 11.4955 12.979L8.40721 16.0673L6.96377 14.6574C6.72879 14.4224 6.35954 14.4224 6.12456 14.6574L5.5539 15.2281C5.35249 15.4295 5.31892 15.7987 5.52033 16.0337L7.93725 19.0213C8.17223 19.3234 8.60862 19.3234 8.8436 19.0213L12.8718 14.3553C13.0732 14.1203 13.0732 13.7846 12.8382 13.5496L12.3347 12.979Z'
			fill='#D31B50'
		/>
		<path
			d='M23.2778 15.3955H14.785C14.5836 15.3955 14.4158 15.5633 14.4158 15.7648V17.2418C14.4158 17.4432 14.5836 17.611 14.785 17.611H23.2443C23.4457 17.611 23.6135 17.4432 23.6135 17.2418V15.7648C23.6135 15.5633 23.4792 15.3955 23.2778 15.3955Z'
			fill='#D31B50'
		/>
		<path
			d='M12.3346 20.2964C12.0996 20.0614 11.7304 20.0614 11.4954 20.2964L8.40709 23.3846L6.99721 21.9748C6.76224 21.7398 6.39298 21.7398 6.15801 21.9748L5.58734 22.5454C5.38593 22.7468 5.35236 23.1161 5.55377 23.3511L7.9707 26.3387C8.20568 26.6408 8.64207 26.6408 8.87704 26.3387L12.9052 21.6727C13.1067 21.4377 13.1067 21.102 12.8717 20.867L12.3346 20.2964Z'
			fill='#D31B50'
		/>
		<path
			d='M23.2778 22.7471H14.785C14.5836 22.7471 14.4158 22.9149 14.4158 23.1163V24.5933C14.4158 24.7947 14.5836 24.9626 14.785 24.9626H23.2443C23.4457 24.9626 23.6135 24.7947 23.6135 24.5933V23.1163C23.6135 22.9149 23.4792 22.7471 23.2778 22.7471Z'
			fill='#D31B50'
		/>
		<path
			d='M24.6878 0.0546875H4.34534C2.09626 0.0546875 0.25 1.90095 0.25 4.15003V27.8493C0.25 30.0984 2.09626 31.9446 4.34534 31.9446H24.6542C26.9033 31.9446 28.7495 30.0984 28.7495 27.8493V4.15003C28.7831 1.90095 26.9369 0.0546875 24.6878 0.0546875ZM26.5005 27.8493C26.5005 28.8563 25.6613 29.6956 24.6542 29.6956H4.34534C3.33829 29.6956 2.49908 28.8563 2.49908 27.8493V4.15003C2.49908 3.14298 3.33829 2.30377 4.34534 2.30377H24.6542C25.6613 2.30377 26.5005 3.14298 26.5005 4.15003V27.8493Z'
			fill='#D31B50'
		/>
	</svg>
)

export default SvgComoPreparar
