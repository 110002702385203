import styled from 'styled-components'

export const StretchyBoxStyled = styled.div`
  display: table;
  width: 100%;
`

export const StretchyBoxCellContractedStyled = styled.div`
  display: table-cell;
  width: 0;
  vertical-align: top;
  white-space: nowrap;
  vertical-align: ${(props: StretchyBoxCellProps) => (props.verticalAlign ? props.verticalAlign : 'top')};
`

export interface StretchyBoxCellProps {
  verticalAlign?: 'top' | 'middle' | 'bottom'
}
export const StretchyBoxCellExpandedStyled = styled.div`
  display: table-cell;
  width: 100%;
  vertical-align: ${(props: StretchyBoxCellProps) => (props.verticalAlign ? props.verticalAlign : 'top')};
`
