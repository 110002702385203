import * as React from 'react'
import {
  SegmentedItemStyled,
  SegmentedStyled
} from './segmented.component.style'

const SegmentedItem = props => <SegmentedItemStyled {...props} />
SegmentedItem.displayName = 'Segmented.Item'

export type SegmentedKind = 'default' | 'light'
export interface SegmentedProps {
  onChange?: (index) => any
  defaultIndex?: number
  kind?: SegmentedKind
  animated?: boolean
}

export interface SegmentedState {
  currentIndex: number
}

export class Segmented extends React.Component<SegmentedProps, SegmentedState> {
  static defaultProps = {
    defaultIndex: 0
  }

  static Item = SegmentedItem

  constructor(props: SegmentedProps) {
    super(props)
    this.state = { currentIndex: props.defaultIndex }
  }

  onPress(index) {
    return () => {
      this.setState({ currentIndex: index })

      if (this.props.onChange) {
        setTimeout(
          () => this.props.onChange(index),
          this.props.animated ? 300 : 0
        )
      }
    }
  }

  render() {
    return <SegmentedStyled>{this.renderChildren()}</SegmentedStyled>
  }

  private renderChildren() {
    return React.Children.map(
      this.props.children,
      (child: any, index: number) => {
        return child
          ? React.cloneElement(child, {
              selected: this.state.currentIndex === index,
              kind: this.props.kind,
              onClick: this.onPress(index),
              animated: this.props.animated
            })
          : null
      }
    )
  }
}
