import { Color, Spacing, HeaderHeight } from 'atomic'
import { highlightStyle, onlyMobileStyle } from 'atomic/legacy/obj.mixin'
import styled, { css } from 'styled-components'
import { headerBreakpoint } from '../../legacy/org.header/header.component.style'
import { ClientDoctorSelect } from 'utils/model/client-doctor-select'

export const NewHeaderMobileContainer = styled.div`
  @media (min-width: 767px) {
    display: none;
  }
`
export const NewHeaderMobile = styled.div`
  height: ${HeaderHeight.Mobile}px;
  position: fixed;
  background: ${Color.White};
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .MuiGrid-container {
    height: 64px;

    .MuiGrid-item {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .ContainerMenuButton {
      display: flex;
      justify-content: end;
      padding: ${Spacing.Medium};

      p {
        font-size: 14px;
        font-weight: unset;
        margin-right: 8px;
      }
    }
  }

  #logo-mobile {
    height: 100%;
    display: flex;
    align-items: center;
  }
`

export const ButtonContainer = styled.div`
  min-width: 100px;
`

export const NewHamburguerButtonContainer = styled.div`
  display: flex;
  justify-content: end;

  span {
    height: auto;
  }

  .hamburguerInner {
    &,
    &:before,
    &:after {
      display: block;
      position: absolute;
      width: 18px;
      height: 2px;
      border-radius: 4px;
      background-color: ${Color.GrayDark};
    }
  }
`

export const MenuMobileOpen = styled.div`
  .hamburguerInner {
    &,
    &:before,
    &:after {
      display: block;
      position: absolute;
      width: 18px;
      height: 2px;
      border-radius: 4px;
      background-color: white !important;
    }
  }

  span {
    button {
      text-transform: uppercase;
    }
  }

  #form-search-everything {
    display: flex;
    flex-direction: row-reverse;
    padding: 0 22px;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    background-color: white;
    border: 1px solid #77787b;
    border-radius: 8px;

    input {
      color: #77787b;
      padding: 0;
      ::placeholder {
        color: #77787b;
      }
    }

    svg {
      position: relative;
      width: 100%;
      display: flex;
      top: unset;
      left: unset;
      color: ${props => props.theme.color.tertiary};
    }
  }
`

export const DrawerWrapperSyled = styled.div`
  height: 100%;
  width: 99%;

  > div {
    margin-top: 0;
  }
`

export const DrawerLogoWrapperSyled = styled.div`
  padding: 0 ${Spacing.Large};
  background: ${props => props.theme.color.primary};
  height: 100%;

  & > svg {
    max-width: 25vw;
    height: 100%;
    overflow: visible;
  }
`
export const DrawerLogoWrapperDoctorSyled = styled.div`
  padding: 0 ${Spacing.Large};
  background: ${props => props.theme.color.tertiary};
  height: 100%;

  & > svg {
    max-width: 25vw;
    height: 100%;
    overflow: visible;
  }
`

export const DrawerLogoWrapperClientSyled = styled.div`
  padding: 0 ${Spacing.Large};
  background: ${props => props.theme.color.primary};
  height: 100%;

  & > svg {
    max-width: 25vw;
    height: 100%;
    overflow: visible;
  }
`
const searchStyle = css<{ area }>`
  @media all and (min-width: ${headerBreakpoint}) {
    display: none;
  }
  /* background: ${props =>
    props.area === ClientDoctorSelect.client ? props.theme.color.primary : Color.GrayDark}; */
  width: 100%;
  z-index: 99;
  color: ${Color.GrayDark}!important;
  border: 1px solid white;
  border-radius: 8px;

  #mobile-global-search{
    border: none!important;
  }
`

export const SearchWrapperStyled = styled.div`
  ${searchStyle}
`

export const FixedSearchWrapperStyled = styled.div`
  ${searchStyle}
  position: fixed;
`

export const FlashMessageWrapperStyled = styled.div`
  ${highlightStyle}
  ${onlyMobileStyle}
`
export const ButtonExamResults = styled.div`
  margin-top: 0;
  background: #e6f3f5;
  display: flex;
  justify-content: center;
  position: fixed;
  top: ${HeaderHeight.Mobile}px;
  z-index: 90;
  width: 100%;
  padding: 8px 0;

  button {
    width: 90%;
    min-width: 280px;
    min-height: 47px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
  }

  &.doctorStyled {
    a {
      width: 90%;
      background: ${props => props.theme.color.tertiary};
      min-width: 280px;
      min-height: 47px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
    }
  }
`
