import React from 'react'

const SvgChevronDown = props => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.41 15.66L12 11.08l4.59 4.58L18 14.25l-6-6-6 6 1.41 1.41z" fill="#757575" />
    <mask id="chevron_down_svg__a" maskUnits="userSpaceOnUse" x={6} y={8} width={12} height={8}>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.41 15.66L12 11.08l4.59 4.58L18 14.25l-6-6-6 6 1.41 1.41z" fill="#fff" />
    </mask>
    <g mask="url(#chevron_down_svg__a)">
      <path fill="#fff" d="M0 24h24V0H0z" />
    </g>
  </svg>
)

export default SvgChevronDown
