import { Typography } from '@material-ui/core'
import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-bottom: 15px;

  .noMargin {
    margin: 0px !important;
  }
`

export const Content = styled(Typography)`
  font-family: 'Signika' !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  &.newLayout {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    text-align:start;
  }

  &.title-light{
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color:white;
  }
`

export const Line = styled.div`
  background-color: ${props => props.theme.color.primary};
  margin-top: 5px;
  width: 76.28px;
  height: 3.92px;
  border-radius: 3px;

  .title-light{
     background-color: white;

  }
`
