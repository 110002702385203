import { GlobalSearchContext } from '@root/src/context/global-search'
import { buildGlobalElasticAutocompleteRequest } from '@root/src/data/http/request-builder/global-elastic-search-autocomplete.request'
import {
  GlobalAutocompleteResponse,
  mapToGlobalSearchResponse
} from '@root/src/data/http/dto/global-elastic-search-autocomplete.response'
import { mapElasticSearchIndexToString } from '@root/src/data/http/utils/elastic-search-index'
import { appPaths, getPathUrl } from 'utils/path'
import { getPathForElasticSearchIndex } from '@root/src/utils/elastic-search-path'
import { getSearchQueryParam } from 'utils/url'
import { graphql, navigate, StaticQuery } from 'gatsby'
import React, { useContext } from 'react'
import { FetchContainer } from '../../legacy/obj.http-container/fetch-container.component'

export interface Suggestion {
  title: string
  subtitle: string
  to: string
}
export interface SearchChildrenProps {
  handleFocus: () => void
  handleSubmit: (ev) => void
  handleTextChange: (search: string) => void
  handleOverlayClick: () => void

  suggestions?: Suggestion[]
}

export interface SearchContainerProps {
  isDoctor: boolean
  children: (props: SearchChildrenProps, state: SearchContainerState) => JSX.Element
}

interface SearchContainerState {
  searchValue?: string
  autocompleteOpen: boolean
}

export const SearchContainer: React.FunctionComponent<SearchContainerProps> = props => {
  const value = useContext(GlobalSearchContext)
  const [searchValue, setSearchValue] = React.useState(value.search)
  const [autocompleteOpen, setAutocompleteOpen] = React.useState(false)

  const handleSubmit = ev => {
    ev.preventDefault()
    if (!searchValue.trim()) {
      return
    }
    setAutocompleteOpen(false)

    navigate(
      `${getPathUrl(appPaths.search.path, props.isDoctor)}${getSearchQueryParam(searchValue)}`
    )
  }

  const handleTextChange = React.useCallback(
    (search: string) => {
      setSearchValue(search)
      setAutocompleteOpen(search.length > 0)
    },
    [setSearchValue, setAutocompleteOpen]
  )

  const handleOverlayClick = React.useCallback(() => {
    setAutocompleteOpen(false)
  }, [setAutocompleteOpen])

  const handleFocus = React.useCallback(() => {
    setAutocompleteOpen(true)
  }, [setAutocompleteOpen])

  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              fleuryElasticSearchUrl
            }
          }
        }
      `}
      render={env => (
        <FetchContainer
          input={buildGlobalElasticAutocompleteRequest(searchValue)}
          mapper={mapToGlobalSearchResponse}
          defaultBaseUrl={env.site.siteMetadata.fleuryElasticSearchUrl}
        >
          {fetchState => {
            const data: GlobalAutocompleteResponse = fetchState.data
            const childProps: SearchChildrenProps = {
              handleSubmit,
              handleTextChange,
              handleFocus,
              handleOverlayClick,
              suggestions: searchValue ? mapToSuggestions(data, props.isDoctor) : []
            }
            return <>{props.children(childProps, { searchValue, autocompleteOpen })}</>
          }}
        </FetchContainer>
      )}
    />
  )
}

const mapToSuggestions = (input: GlobalAutocompleteResponse, isDoctor: boolean) => {
  if (!input) {
    return []
  }

  const someSuggestions = input.suggestions ? input.suggestions.slice(0, 2) : []
  const someResults = input.results ? input.results.slice(0, 4) : []
  return [
    ...someSuggestions.map(suggestion => ({
      title: suggestion,
      subtitle: '',
      to: `${getPathUrl(appPaths.search.path, isDoctor)}${getSearchQueryParam(suggestion)}`
    })),
    ...someResults.map(result => {
      const subtitleSuffix = mapElasticSearchIndexToString(result.index, isDoctor)
      return {
        title: result.title,
        subtitle: subtitleSuffix ? `em ${subtitleSuffix}` : '',
        to: getPathForElasticSearchIndex(
          isDoctor,
          result.slug ? result.slug : result.id,
          result.index
        )
      }
    })
  ] as Suggestion[]
}
