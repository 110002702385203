import styled from 'styled-components'
import { Spacing } from 'atomic'

export const ContactUsIconWrapper = styled.div`
  margin-right: ${Spacing.Medium};
  text-align: center;
  font-size: 14px;
`

export const PhoneIconWrapper = styled.div`
  font-size: 12px;
`

export const ContactUsModalStyled = styled.div`
  margin: ${Spacing.Medium};
  padding: ${Spacing.Small};
`
export const ContactUsModalDetails = styled.div`
  & p {
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    margin-top: 16px;
    margin-bottom: 8px;
  }
`
export const ContactUsModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const AttendanceHours = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
  height: unset;
  background: #e5f3f1;
  width: fit-content;
  align-items: start;
  border-radius: 8px;
  line-height: normal;
  padding: 15px 15px 0px 15px;
  font-size: 12px !important;

  p {
    font-size: 12px !important;
  }

  ul {
    padding-left: 16px;
  }
  li {
    margin-left: 0;

    font-size: 12px !important;
  }
`
