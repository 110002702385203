import styled from 'styled-components'
import { Spacing } from '..'

// Useful to make elements which are "display: block" stay in the same line
export interface InlineBlockStyledProps {
  verticalAlign: 'bottom' | 'middle'
}

export const InlineBlockStyled = styled.div`
  display: inline-block;
  vertical-align: ${(props: InlineBlockStyledProps) => props.verticalAlign};

  & + & {
    margin-left: ${Spacing.Small};
  }
`
