import { Button, Col, Grid, Hbox, Row } from 'atomic'
import React from 'react'
import { DesktopBottomHeaderStyled } from '../client-doctor-header.component.style'
import { ClientDoctorSelect } from 'utils/model/client-doctor-select'
import { DesktopSubmenu, DesktopSubmenuProps } from './desktop-submenu.component'

export interface DesktopBottomHeaderProps {
  area: ClientDoctorSelect
  items: {
    title: string
    to?: string
    onClick?: () => any
    external?: boolean
    target?: '_self' | '_parent' | '_blank' | '_top'
  }[]
  otherItems: DesktopSubmenuProps
}

export const DesktopBottomHeader = React.memo(
  (props: DesktopBottomHeaderProps) => {
    return (
      <DesktopBottomHeaderStyled area={props.area}>
        <Grid>
          <Row middle="xs">
            <Col>
              <Hbox>
                {console.log(props.items)}
                {props.items.map((item, index) => (
                  <React.Fragment key={index}>
                    {/* Verifique se não é 'FLEURY' e 'Corpo Clínico' */}
                    {!(process.env.GATSBY_MARCA === 'FLEURY' && item.title === 'Corpo Clínico') && (
                      <>
                        {index !== 0 && <DesktopBottomHeaderItemSeparator />}
                        <Hbox.Item vAlign="center" wrap>
                          <Button
                            id="button-title-desktop-header"
                            kind="link"
                            light
                            to={item.to}
                            onClick={item.onClick}
                            external={item.external}
                            target={item.target}
                            showActiveClass
                          >
                            {item.title}
                          </Button>
                        </Hbox.Item>
                      </>
                    )}
                  </React.Fragment>
                ))}
                <Hbox.Separator />
                <Hbox.Separator />
                <Hbox.Separator />
                {props.otherItems && props.otherItems.items.length > 0 && (
                  <Hbox.Item vAlign="center" wrap>
                    <DesktopSubmenu {...props.otherItems}>
                      <Button id="button-more-options" kind="link" light>
                        Mais opções
                      </Button>
                    </DesktopSubmenu>
                  </Hbox.Item>
                )}
              </Hbox>
            </Col>
          </Row>
        </Grid>
      </DesktopBottomHeaderStyled>
    )
  },
  // The desktop bottom header props will never change, not needing to be redraw
  () => true
)

const DesktopBottomHeaderItemSeparator = () => (
  <>
    <Hbox.Separator />
    <Hbox.Separator />
    <Hbox.Separator />
    <Hbox.Separator />
  </>
)
