import { Border, Color, Spacing } from 'atomic'
import styled from 'styled-components'

export const FooterStyled = styled.div`
  background-color: ${props => props.theme.color.footer};
  padding-bottom: ${Spacing.XLarge};
  position: relative;
  overflow: hidden;

  &.weinmann {
    background-color: #373A3A;
  }
`

export const BackgroundWrapper = styled.div`
  position: absolute;
  left: -40px;
  top: 4px;
  width: 488px;
  @media (max-width: 64em) {
    display: none;
  }
`
export const ContactWrapper = styled.div`
  width: 106px;
  text-align: center;
  white-space: nowrap;
`

export const FooterLinkWrapper = styled.div`
  margin-top: ${Spacing.Small};
`

export const FooterLinkWrapperWeinmann = styled.div`
  margin-bottom: ${Spacing.Small};
`

export const ContentWrapperStyled = styled.div`
  position: relative;
`
export const FooterBottomTextWrapperStyled = styled.div`
  text-align: center;
`

export const SocialMediaLogoWrapperStyled = styled.div`
  border-radius: ${Border.RadiusLarge};
  background-color: ${Color.GrayXDark};
  width: 36px;
  height: 36px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: ${Spacing.Small};
`
export const SocialMediaSvgWrapper = styled.div``

export const FooterDownloadButtonWrapper = styled.div`
  position: relative;
  height: 100%;
`

interface FooterVerticalDivisorProps {
  color: Color
}

export const FooterVerticalDivisorStyled = styled.div<FooterVerticalDivisorProps>`
  position: absolute;
  height: 100%;
  width: 1px;
  background-color: ${props => props.color};
`
export const FooterHorizontallDivisorStyled = styled.div`
  margin: 20px 0px 10px 0px;
  width: auto;
  height: 2px;
  left: 32px;
  top: 570px;

  background: #77787B;
`

export const FooterAppAlignStyled = styled.div`
text-align: center;
`

export const FooterLink = styled.a`
  color: ${Color.White};
`

export const FooterInformation = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 13px;
  font-weight: 300;

`

export const WhatsAppFloatButton = styled.div`
  position: fixed;
  display: flex;
  width: 45px;
  height: 46px;
  right: 32px;
  bottom: 30px;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  &:hover {
    img {
      width: 74px;
      height: 75px;
    }    
  }
`