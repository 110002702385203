import { Button, Col, Row, Separator } from 'atomic'
import { useBooleanState } from 'atomic/obj.boolean-state/boolean-state.hook'
import React from 'react'
import { ContactUsModal } from '../../mol.contact-us-modal/contact-us-modal.component'
import { ClientDoctorSegmentedControl } from './client-doctor-segmented-control.component'
import { ClientDoctorSelect } from 'utils/model/client-doctor-select'
import { getDoctorMenuItems } from './menu-items'
// import { MenuMobile } from '../client-doctor-header.component.style'

import styled from 'styled-components'

const SubMenuTitle = styled.div`
  margin-left: 20px;
`

export const DoctorMenuContentRows: React.FunctionComponent<any> = React.memo(_props => {
  const { value, setFalse, setTrue } = useBooleanState(false)

  const menuItems = getDoctorMenuItems(setTrue)

  return (
    <>
      <Row mb={true} mt={true}>
        <Col>
          <ClientDoctorSegmentedControl
            id="mobile-menu"
            kind="light"
            area={ClientDoctorSelect.doctor}
          />
        </Col>
      </Row>
      <ContactUsModal open={value} onClose={setFalse} isDoctor={true} />
      <Separator />
      {menuItems.menus.map((item, index) => {
        return item.items ? (
          <>
            <Row mb={true} key={index}>
              <Col xs={12}>
                <Button
                  id={`button-title-client-menu-content-${index}`}
                  kind="link"
                  light
                  to={item.to ? item.to : '#'}
                >
                  {item.title}
                </Button>
              </Col>
            </Row>
            {item.items.map(subItem => (
              <Row mb={true} key={subItem.title}>
                <Col xs={12}>
                  <Button
                    id={`button-title-client-menu-content-${index}`}
                    kind="link"
                    light
                    external={subItem.external}
                    onClick={subItem.onClick}
                    to={subItem.to}
                  >
                    <SubMenuTitle>{subItem.title}</SubMenuTitle>
                  </Button>
                </Col>
              </Row>
            ))}
          </>
        ) : (
          <Row mb={true} key={item.title}>
            <Col xs={12}>
              <Button
                id={`button-title-client-menu-content-${index}`}
                kind="link"
                light
                external={item.external}
                onClick={item.onClick}
                to={item.to}
              >
                {item.title}
              </Button>
            </Col>
          </Row>
        )
      })}

      {/* {menuItems.items.map((item, index) => (
        <Row mb={true} key={item.title}>
          <Col xs={12}>
            <Button
              id={`button-title-doctor-menu-${index}`}
              kind="link"
              light
              external={item.external}
              onClick={item.onClick}
              to={item.to}
            >
              {item.title}
            </Button>
          </Col>
        </Row>
      ))}
      {menuItems.otherItems.items.map((item, index) => (
        <Row mb={true} key={item.title}>
          <Col xs={12}>
            <Button
              id={`button-title-other-doctor-menu-${index}`}
              kind="link"
              light
              external={item.external}
              to={item.to}
            >
              {item.title}
            </Button>
          </Col>
        </Row>
      ))} */}
    </>
  )
})
