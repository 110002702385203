/** https://developers.google.com/search/docs/data-types/corporate-contact */
export const getCorporateContactJsonLd = (data: {
  url: string
  logoUrl: string
  phone: string
}) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: data.url,
    logo: data.logoUrl,
    contactPoint: [
      {
        '@type': 'ContactPoint',
        // An internationalized version of the phone number, starting with the "+" symbol and country code (+1 in the US and Canada).
        telephone: data.phone,
        contactType: 'customer service'
      }
    ]
  }
}
