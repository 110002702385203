import { Button, ButtonNew, Color, Hbox } from 'atomic'
import * as React from 'react'
import { IconLinkTextCellStyled, IconLinkTextCellStyledWeinmann } from './icon-link-cell.style'

interface IconLinkCellProps {
  icon: JSX.Element
  text: string
  light?: boolean
  to: string
  external?: boolean
  id: string
  target?: '_self' | '_parent' | '_blank' | '_top'
}

export const IconLinkCell: React.FunctionComponent<IconLinkCellProps> = ({ id, ...props }) => (
  <>
  {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() === 'weinmann' && (
    <>
      <IconLinkTextCellStyledWeinmann actionColor={Color.White}>
        <ButtonNew id={id} kind="link" light={props.light} to={props.to} external={props.external} target={props.target}>
          <Hbox vAlign="center">
            <Hbox.Item vAlign="center" wrap>
              {props.icon}
            </Hbox.Item>
            <Hbox.Separator />
            <Hbox.Item vAlign="center">{props.text}</Hbox.Item>
          </Hbox>
        </ButtonNew>
      </IconLinkTextCellStyledWeinmann>
    </>
  )}
  {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() !== 'weinmann' && (
    <>
      <IconLinkTextCellStyled actionColor={Color.White}>
        <Button id={id} kind="link" light={props.light} to={props.to} external={props.external} target={props.target}>
          <Hbox vAlign="center">
            <Hbox.Item vAlign="center" wrap>
              {props.icon}
            </Hbox.Item>
            <Hbox.Separator />
            <Hbox.Item vAlign="center">{props.text}</Hbox.Item>
          </Hbox>
        </Button>
      </IconLinkTextCellStyled>
    </>
  )}
  </>
)
