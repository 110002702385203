import { useLayoutEffect, useState } from 'react'

export const useLockBodyScroll = (isLocked: boolean) => {
  const [pos, setPos] = useState(0)
  useLayoutEffect(() => {
    if (typeof window !== undefined) {
      const position = window.scrollY
      setPos(position)
      if (isLocked) {
        document.body.style.position = 'fixed'
        document.body.style.width = `100%`
        document.body.style.top = `-${position}px`
      } else {
        document.body.style.top = ''
        document.body.style.position = ''
        window.scrollTo(0, pos)
        setPos(position)
      }
    }
  }, [isLocked])
}
