import * as React from 'react'

import { FormFieldContext, FormFieldContextState } from 'atomic/legacy/obj.form'
import { TextAreaStyled } from './text-area-field.component.style'

export interface TextAreaFieldProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  onValueChange?: (value: string | number | string[]) => void
  initialValue?: string | number | string[]
}

export interface TextAreaFieldState {
  rows: number
  value?: string | number | string[]
}

export class TextAreaField extends React.Component<TextAreaFieldProps, TextAreaFieldState> {
  private formFieldConsumer: FormFieldContextState
  private textarea: HTMLTextAreaElement

  constructor(props: TextAreaFieldProps) {
    super(props)
    this.state = {
      value: props.initialValue || props.value,
      rows: 1
    }
  }

  componentDidMount() {
    if (this.isControlled() && this.props.initialValue !== undefined) {
      throw new Error('Use either the initialValue prop, or the value prop, but not both')
    }

    if (this.formFieldConsumer) {
      if (this.isControlled() || this.props.initialValue !== undefined) {
        if (this.formFieldConsumer.value) {
          throw new Error('Please, use either value props in <TextField> or <Form.Field> component.')
        }

        this.formFieldConsumer.onValueChange(this.state.value, null)
      }
    }
  }

  componentDidUpdate(prevProps: TextAreaFieldProps) {
    if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
      if (this.props.onValueChange) {
        this.props.onValueChange(this.props.value)
      }

      if (this.formFieldConsumer) {
        this.formFieldConsumer.onValueChange(this.props.value, null)
      }

      this.setState({ value: this.props.value })
    }
  }

  render() {
    const { onValueChange, onChange, value, initialValue, ...other } = this.props

    return (
      <FormFieldContext.Consumer>
        {(formFieldConsumer: FormFieldContextState) => {
          this.formFieldConsumer = formFieldConsumer
          const val = formFieldConsumer && formFieldConsumer.value ? formFieldConsumer.value : this.state.value
          return (
            <TextAreaStyled
              value={val || ''}
              onChange={this.handleTextChange}
              ref={ref => (this.textarea = ref)}
              rows={this.state.rows}
              invalid={formFieldConsumer && formFieldConsumer.errors.length > 0}
              {...other}
            />
          )
        }}
      </FormFieldContext.Consumer>
    )
  }

  private handleTextChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
    if (this.isControlled()) {
      return
    }

    const value: string = event.currentTarget.value

    if (this.formFieldConsumer && value === this.formFieldConsumer.value) {
      return
    }

    if (this.props.onValueChange) {
      this.props.onValueChange(value)
    }

    // https://stackoverflow.com/a/2036424/3670829
    const style = window.getComputedStyle ? window.getComputedStyle(this.textarea) : { lineHeight: '' }
    const lineHeight = parseInt(style.lineHeight, 10)
    const scrollHeight = this.textarea.scrollHeight
    const rows = scrollHeight / lineHeight
    this.setState({ rows })

    if (this.formFieldConsumer) {
      this.formFieldConsumer.onValueChange(value, null)
      return
    }
    this.setState({ value })
  }

  private isControlled = () => this.props.value !== undefined
}
