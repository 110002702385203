import * as React from 'react'

export interface JsonLdProps {
  jsonld?: object
}

// src: https://blog.haroen.me/json-ld-with-react#comment-3255424415
// You can test it here: https://search.google.com/structured-data/testing-tool/u/0/
export class JsonLd extends React.Component<JsonLdProps, undefined> {
  constructor(props: JsonLdProps) {
    super(props)
  }

  render() {
    return this.props.jsonld ? (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(this.props.jsonld) }}
      />
    ) : null
  }
}
