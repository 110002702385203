import { Button, Col, Row } from 'atomic'
import { ForcedFade } from 'atomic/legacy/obj.animation/animation.component.style'
import { useBooleanState } from 'atomic/obj.boolean-state/boolean-state.hook'
import React from 'react'
import {
  DesktopSubmenuListStyled,
  DesktopSubmenuOriginStyled,
  DesktopSubmenuStyled
} from './desktop-submenu.component.style'

export interface DesktopSubmenuProps {
  items: {
    title: string
    to: string
    external: boolean
  }[]
  /** color hex to be used on :hover and :active */
  actionColor: string
}

export const DesktopSubmenu: React.FunctionComponent<DesktopSubmenuProps> = props => {
  const { value, setFalse, setTrue } = useBooleanState(false)
  return (
    <DesktopSubmenuStyled
      onMouseEnter={setTrue}
      onMouseLeave={setFalse}
      actionColor={props.actionColor}
    >
      <DesktopSubmenuOriginStyled>{props.children}</DesktopSubmenuOriginStyled>
      {value && (
        <ForcedFade show={true}>
          <DesktopSubmenuListStyled actionColor={props.actionColor}>
            {props.items.map(item => (
              <Row mb mt key={item.title}>
                <Col xs={12}>
                  <Button
                    id="button-title-desktop-submenu"
                    kind="link"
                    light={true}
                    to={item.to}
                    external={item.external}
                    className="submenu"
                  >
                    {item.title}
                  </Button>
                </Col>
              </Row>
            ))}
          </DesktopSubmenuListStyled>
        </ForcedFade>
      )}
    </DesktopSubmenuStyled>
  )
}
