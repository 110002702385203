import React from 'react'

const SvgExpandIcon = props => (
  <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.49994 6.49994C5.36833 6.5007 5.23787 6.47548 5.11603 6.42571C4.9942 6.37595 4.88338 6.30262 4.78994 6.20994L0.789941 2.20994C0.696703 2.1167 0.622742 2.00601 0.572282 1.88419C0.521822 1.76237 0.49585 1.6318 0.49585 1.49994C0.49585 1.36808 0.521822 1.23751 0.572282 1.11569C0.622742 0.993869 0.696703 0.883179 0.789941 0.789941C0.88318 0.696702 0.99387 0.622741 1.11569 0.572281C1.23751 0.521821 1.36808 0.49585 1.49994 0.49585C1.6318 0.49585 1.76237 0.521821 1.88419 0.572281C2.00601 0.622741 2.1167 0.696702 2.20994 0.789941L5.49994 4.09994L8.79994 0.919941C8.89193 0.817651 9.00404 0.735442 9.12925 0.678456C9.25447 0.62147 9.39009 0.590933 9.52764 0.588754C9.6652 0.586574 9.80172 0.6128 9.92868 0.665791C10.0556 0.718782 10.1703 0.797398 10.2655 0.896723C10.3607 0.996047 10.4343 1.11394 10.4819 1.24304C10.5294 1.37213 10.5498 1.50965 10.5418 1.64698C10.5338 1.78432 10.4975 1.91852 10.4352 2.0412C10.373 2.16387 10.2861 2.27238 10.1799 2.35994L6.17994 6.21994C5.99707 6.39626 5.75394 6.49637 5.49994 6.49994Z"
      fill={props.fill} />
  </svg>

)

export default SvgExpandIcon
