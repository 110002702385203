import { Button, Col, Row, Separator } from 'atomic'
import { useBooleanState } from 'atomic/obj.boolean-state/boolean-state.hook'
import React from 'react'
import { ContactUsModal } from '../../mol.contact-us-modal/contact-us-modal.component'
import { ClientDoctorSegmentedControl } from './client-doctor-segmented-control.component'
import { ClientDoctorSelect } from 'utils/model/client-doctor-select'
import { getDoctorMenuItems } from './menu-items'

export const DoctorMenuContentRows: React.FunctionComponent<any> = React.memo(_props => {
  const { value, setFalse, setTrue } = useBooleanState(false)

  const menuItems = getDoctorMenuItems(setTrue)

  return (
    <>
      <Row mb={true} mt={true}>
        <Col>
          <ClientDoctorSegmentedControl
            id="mobile-menu"
            kind="light"
            area={ClientDoctorSelect.doctor}
          />
        </Col>
      </Row>
      <ContactUsModal open={value} onClose={setFalse} isDoctor={true} />
      <Separator />
      {menuItems.items.map((item, index) => (
        <Row mb={true} key={item.title}>
          <Col xs={12}>
            <Button
              id={`button-title-doctor-menu-${index}`}
              kind="link"
              light
              external={item.external}
              onClick={item.onClick}
              to={item.to}
            >
              {item.title}
            </Button>
          </Col>
        </Row>
      ))}
      {menuItems.otherItems.items.map((item, index) => (
        <Row mb={true} key={item.title}>
          <Col xs={12}>
            <Button
              id={`button-title-other-doctor-menu-${index}`}
              kind="link"
              light
              external={item.external}
              to={item.to}
            >
              {item.title}
            </Button>
          </Col>
        </Row>
      ))}
    </>
  )
})
