import { BodySecondary, Col, Row } from 'atomic'
import * as React from 'react'
import { FooterBottomTextWrapperStyled } from '../footer.component.style'

interface IBottomTextRowProps {}

const BottomTextRow: React.FunctionComponent<IBottomTextRowProps> = props => {
  return (
    <Row center="xs">
      <Col xs>
        <FooterBottomTextWrapperStyled>
          <BodySecondary className={`${process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() === 'weinmann' ? 'weinmann' : ''}`} kind="light">{props.children}</BodySecondary>
        </FooterBottomTextWrapperStyled>
      </Col>
    </Row>
  )
}

export default BottomTextRow
