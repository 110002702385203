import * as React from 'react'
import { AutocompleteOverlayStyled, AutocompleteStyled } from './autocomplete.component.style'
import { SearchCell } from './search-cell.component'
import { Suggestion } from './search.container'

export interface DesktopAutocompleteChildrenProps {
  placeholder: string
  onOpenClick: () => void
  onVoiceSearchClick: () => void
}

export interface DesktopAutocompleteProps {
  suggestions?: Suggestion[]
  opened: boolean
  search: string

  onCellClick: (value: string, substring?: string) => void
  onOverlayClick: () => void
}

// The contentDisplayed state is responsible for keeping the data on screen
// while the transition, triggered by opened change, is not finished
interface DesktopAutocompleteState {
  voiceSearchInitiallyOpen?: boolean
  voiceSearchResult?: string
}

export class DesktopAutocomplete extends React.Component<
  DesktopAutocompleteProps,
  DesktopAutocompleteState
> {
  constructor(props: DesktopAutocompleteProps) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <>
        <AutocompleteOverlayStyled onClick={this.props.onOverlayClick} opened={this.props.opened} />
        {this.props.opened && (
          <AutocompleteStyled opened={this.props.opened}>
            {this.props.suggestions &&
              this.props.suggestions.length > 0 &&
              this.props.suggestions.map((element, index) => {
                return (
                  <SearchCell
                    key={element.title + index}
                    search={this.props.search}
                    title={element.title}
                    subtitle={element.subtitle}
                    to={element.to}
                    {...this.props}
                  />
                )
              })}
          </AutocompleteStyled>
        )}
      </>
    )
  }
}
