/** generated using: https://www.elastic.co/guide/en/elasticsearch/reference/6.1/_list_all_indices.html#_list_all_indices */
export type ElasticSearchIndex =
  | '.kibana_1'
  | '1233'
  | 'amais-df-boletim-medicos'
  | 'amais-df-convenio-empresa-planos'
  | 'amais-df-convenios'
  | 'amais-df-exames'
  | 'amais-df-pages'
  | 'amais-df-unidades'
  | 'amaispe-convenio-empresa-planos'
  | 'amaispe-convenios'
  | 'amaispe-exames'
  | 'amaispe-pages'
  | 'amaispe-unidades'
  | 'amaispr-convenio-empresa-planos'
  | 'amaispr-convenios'
  | 'amaispr-exames'
  | 'amaispr-langing-pages'
  | 'amaispr-pages'
  | 'amaispr-unidades'
  | 'amaissp-convenio-empresa-planos'
  | 'amaissp-exames'
  | 'amaissp-pages'
  | 'amaissp-unidades'
  | 'felippe-mattoso-algoritmos-diagnsticos'
  | 'felippe-mattoso-artigos-cientificos'
  | 'felippe-mattoso-convenio-empresa-planos'
  | 'felippe-mattoso-corpo-clinico'
  | 'felippe-mattoso-detalhe-de-convenios'
  | 'felippe-mattoso-diferenciais'
  | 'felippe-mattoso-educacao-medica'
  | 'felippe-mattoso-especialidades'
  | 'felippe-mattoso-exames'
  | 'felippe-mattoso-manuais-diagnosticos'
  | 'felippe-mattoso-manual-de-doencas'
  | 'felippe-mattoso-noticias'
  | 'felippe-mattoso-novos-servicos'
  | 'felippe-mattoso-palavras-chave'
  | 'felippe-mattoso-perguntas-frequentes'
  | 'felippe-mattoso-receptivos-de-campanhas'
  | 'felippe-mattoso-revistas-fleury'
  | 'felippe-mattoso-revistas-medicas'
  | 'felippe-mattoso-roteiros-diagnosticos'
  | 'felippe-mattoso-servicos-diferenciados'
  | 'felippe-mattoso-sobre-fleury-historia'
  | 'felippe-mattoso-unidades'
  | 'fleury-algoritmos-diagnsticos'
  | 'fleury-artigos-cientificos'
  | 'fleury-convenio-empresa-planos'
  | 'fleury-corpo-clinico'
  | 'fleury-detalhe-de-convenios'
  | 'fleury-diferenciais'
  | 'fleury-educacao-medica'
  | 'fleury-especialidades'
  | 'fleury-exames'
  | 'fleury-facilidades'
  | 'fleury-fale-conosco'
  | 'fleury-grupo-de-exames'
  | 'fleury-horarios'
  | 'fleury-informacoes-estaticas'
  | 'fleury-institucional'
  | 'fleury-manuais-diagnosticos'
  | 'fleury-manual-de-doencas'
  | 'fleury-noticias'
  | 'fleury-novos-servicos'
  | 'fleury-palavras-chave'
  | 'fleury-perguntas-frequentes'
  | 'fleury-receptivos-de-campanhas'
  | 'fleury-revistas-fleury'
  | 'fleury-revistas-medicas'
  | 'fleury-roteiros-diagnosticos'
  | 'fleury-servicos-diferenciados'
  | 'fleury-sobre-fleury-historia'
  | 'fleury-unidades'
  | 'index'
  | 'index3'
  | 'index3434'
  | 'labs-amais-convenio-empresa-planos'
  | 'labs-amais-convenios'
  | 'labs-amais-exames'
  | 'labs-amais-pages'
  | 'labs-amais-unidades'
  | 'test'
  | 'weinmann-algoritmos-diagnsticos'
  | 'weinmann-artigos-cientificos'
  | 'weinmann-convenio-empresa-planos'
  | 'weinmann-corpo-clinico'
  | 'weinmann-detalhe-de-convenios'
  | 'weinmann-diferenciais'
  | 'weinmann-educacao-medica'
  | 'weinmann-especialidades'
  | 'weinmann-exames'
  | 'weinmann-manuais-diagnosticos'
  | 'weinmann-manual-de-doencas'
  | 'weinmann-noticias'
  | 'weinmann-novos-servicos'
  | 'weinmann-palavras-chave'
  | 'weinmann-perguntas-frequentes'
  | 'weinmann-receptivos-de-campanhas'
  | 'weinmann-revistas-fleury'
  | 'weinmann-revistas-medicas'
  | 'weinmann-roteiros-diagnosticos'
  | 'weinmann-servicos-diferenciados'
  | 'weinmann-sobre-fleury-historia'

export const mapElasticSearchIndexToString = (input: ElasticSearchIndex, isDoctor: boolean) => {
  let inputWithoutBrand = input.replace(/(fleury|weinmann|felippe-mattoso|felippe_mattoso)-/, '')
  inputWithoutBrand = inputWithoutBrand.replace(
    /(fleury|weinmann|felippe-mattoso|felippe_mattoso)_/,
    ''
  )
  inputWithoutBrand = inputWithoutBrand.replace(
    inputWithoutBrand.substring(inputWithoutBrand.length - 14),
    ''
  )

  switch (inputWithoutBrand) {
    case 'algoritmos-diagnsticos':
      return 'Roteiros Diagnósticos'
    case 'artigos':
      return 'Artigos Científicos'
    case 'convenios':
      return 'Convênios'
    case 'corpo-clinico':
      return 'Corpo Clínico'
    case 'detalhe-de-convenios':
      return ''
    case 'diferenciais':
      return ''
    case 'fleury-med':
      return 'Fleury Med'
    case 'especialidades':
      return ''
    case 'exames':
      return isDoctor ? 'Manual de Exames' : 'Exames'
    case 'facilidades':
      return ''
    case 'fale-conosco':
      return 'Fale Conosco'
    case 'grupo-de-exames':
      return ''
    case 'horarios':
      return 'Unidades'
    case 'informacoes-estaticas':
      return ''
    case 'institucional':
      return 'Institucional'
    case 'manuais-diagnosticos':
      return 'Manuais Diagnósticos'
    case 'manual-de-doencas':
      return 'Manual de Doenças'
    case 'noticias':
      return 'Notícias'
    case 'novos-servicos':
      return 'Outros Serviços'
    case 'palavras-chave':
      return ''
    case 'perguntas-frequentes':
      return 'FAQ'
    case 'receptivos-de-campanhas':
      return 'Receptivo de Campanha'
    case 'revistas-fleury':
      return 'Revistas Fleury'
    case 'revistas-medicas':
      return 'Revistas Médicas'
    case 'roteiros-diagnosticos':
      return 'Roteiros Diagnósticos'
    case 'servicos-diferenciados':
      return 'Serviços Diferenciados'
    case 'sobre-fleury-historia':
      return 'História'
    case 'unidades':
      return 'Unidades'
    case 'pages':
      return 'Páginas'
    default:
      console.error(
        'ERROR: mapElasticSearchIndexToString: Not mapped elastic search index: ',
        input
      )
      return ''
  }
}
