import React from 'react'

const SvgCancelar = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='36' height='28' viewBox='0 0 36 28' fill='none'>
		<path
			d='M12.225 19.775C12.5458 20.0958 12.9542 20.2563 13.45 20.2563C13.9458 20.2563 14.3542 20.0958 14.675 19.775L18 16.45L21.3687 19.8188C21.6896 20.1396 22.0833 20.3 22.55 20.3C23.0167 20.3 23.425 20.125 23.775 19.775C24.0958 19.4542 24.2563 19.0458 24.2563 18.55C24.2563 18.0542 24.0958 17.6458 23.775 17.325L20.45 14L23.8188 10.6312C24.1396 10.3104 24.3 9.91667 24.3 9.45C24.3 8.98333 24.125 8.575 23.775 8.225C23.4542 7.90417 23.0458 7.74375 22.55 7.74375C22.0542 7.74375 21.6458 7.90417 21.325 8.225L18 11.55L14.6312 8.18125C14.3104 7.86042 13.9167 7.7 13.45 7.7C12.9833 7.7 12.575 7.875 12.225 8.225C11.9042 8.54583 11.7438 8.95417 11.7438 9.45C11.7438 9.94583 11.9042 10.3542 12.225 10.675L15.55 14L12.1813 17.3687C11.8604 17.6896 11.7 18.0833 11.7 18.55C11.7 19.0167 11.875 19.425 12.225 19.775ZM4 28C3.0375 28 2.21383 27.6576 1.529 26.9727C0.843 26.2868 0.5 25.4625 0.5 24.5V3.5C0.5 2.5375 0.843 1.71383 1.529 1.029C2.21383 0.343 3.0375 0 4 0H32C32.9625 0 33.7868 0.343 34.4728 1.029C35.1576 1.71383 35.5 2.5375 35.5 3.5V24.5C35.5 25.4625 35.1576 26.2868 34.4728 26.9727C33.7868 27.6576 32.9625 28 32 28H4ZM4 24.5V3.5V24.5ZM4 24.5H32V3.5H4V24.5Z'
			fill='#D31B50'
		/>
	</svg>
)

export default SvgCancelar
