import { Segmented, SegmentedKind } from 'atomic/legacy/atm.segmented'
import { ClientDoctorSelectContext } from '@root/src/context/client-doctor-select'
import { pagesPaths } from 'utils/path'
import { navigate } from 'gatsby'
import React, { useContext } from 'react'
import { ClientDoctorSelect } from 'utils/model/client-doctor-select'

export const getHomePathForClientDoctorSelect = (option: ClientDoctorSelect) =>
  option === ClientDoctorSelect.client ? pagesPaths.homeClient.path : pagesPaths.homeDoctor.path

interface ClientDoctorSegmentedControlProps {
  area?: ClientDoctorSelect
  kind: SegmentedKind
  id?: string
}

export const ClientDoctorSegmentedControl: React.FunctionComponent<ClientDoctorSegmentedControlProps> = ({
  kind,
  area,
  id = ''
}) => {
  const value = useContext(ClientDoctorSelectContext)

  const handleChange = (index: number) => {
    if (index === ClientDoctorSelect.client) {
      navigate(value.clientUrl)
    } else {
      navigate(value.doctorUrl)
    }
  }
  return (
    <Segmented kind={kind} onChange={handleChange} defaultIndex={area} animated>
      <Segmented.Item id={`button-${id}-client`}>Cliente</Segmented.Item>
      <Segmented.Item id={`button-${id}-medic`}>Médico</Segmented.Item>
    </Segmented>
  )
}
