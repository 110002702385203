import React from 'react'

const SvgIcOutrosExames = props => (
  <svg 
    width="29" 
    height="40" 
    viewBox="0 0 29 25" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg" 
    {...props}
  >
    <path 
      d="M20.9999 0C25.0506 0 28.3333 3.33333 28.3333 8C28.3333 17.3333 18.3333 22.6667 14.9999 24.6667C12.3639 23.0853 5.55592 19.4173 2.82525 13.3347L0.333252 13.3333V10.6667H1.94659C1.76525 9.81867 1.66659 8.93067 1.66659 8C1.66659 3.33333 4.99992 0 8.99992 0C11.4799 0 13.6666 1.33333 14.9999 2.66667C16.3333 1.33333 18.5199 0 20.9999 0ZM20.9999 2.66667C19.5653 2.66667 18.0133 3.42667 16.8853 4.552L14.9999 6.43733L13.1146 4.552C11.9866 3.42667 10.4346 2.66667 8.99992 2.66667C6.41325 2.66667 4.33325 4.87467 4.33325 8C4.33325 8.91333 4.45325 9.80267 4.68925 10.6667H7.57859L10.3333 6.07467L14.3333 12.7413L15.5786 10.6667H21.6666V13.3333H17.0879L14.3333 17.9253L10.3333 11.2587L9.08792 13.3333H5.81059C6.86392 15.1653 8.45725 16.8907 10.5266 18.5373C11.5199 19.3267 12.5799 20.064 13.7546 20.804C14.1533 21.056 14.5479 21.2973 14.9999 21.5667C15.4519 21.2973 15.8466 21.056 16.2453 20.8053C17.4199 20.064 18.4799 19.3267 19.4719 18.5373C23.4466 15.3773 25.6666 11.924 25.6666 8C25.6666 4.85333 23.6173 2.66667 20.9999 2.66667Z"
    />
  </svg>

)

export default SvgIcOutrosExames
