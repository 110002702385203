import * as React from 'react'
import { InputCaption, InputCaptionError } from 'atomic/legacy/atm.typography'
import { ValidationError } from './validators'

export interface FormFieldCaptionProps {
  errors?: ValidationError[]
  showAll?: boolean
  validationPlaceholder?: string
}

export class FormFieldCaption extends React.PureComponent<FormFieldCaptionProps, any> {
  render() {
    const wrap = this.props.errors.map((error: ValidationError, index: number) => (
      <InputCaptionError key={error.name + index}>{error.message}</InputCaptionError>
    ))

    return this.props.showAll ? (
      wrap
    ) : (
      <React.Fragment>
        {this.props.errors && this.props.errors.length > 0 ? (
          <InputCaptionError key={this.props.errors[0].name}>{this.props.errors[0].message}</InputCaptionError>
        ) : (
          this.props.validationPlaceholder && <InputCaption>{this.props.validationPlaceholder}</InputCaption>
        )}
      </React.Fragment>
    )
  }
}
