import { Color, Spacing, FontSize } from 'atomic'
import styled from 'styled-components'

export const SearchCellPlaceholderStyled = styled.div`
  height: 48px;
  background-color: transparent;
`

export const SearchCellStyled = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 0px;
  padding: ${Spacing.Small} 48px;
  position: relative;
  text-decoration: none;
  background-color: ${Color.White};

  pointer-events: auto;
  cursor: pointer;
  font-size: ${FontSize.XSmall};

  :hover {
    background-color: ${Color.GrayLight};
  }
`

export const SearchCellSubtitleStyled = styled.span`
  margin-left: ${Spacing.Small};
  color: ${props => props.theme.color.primary};
  font-size: ${FontSize.XXSmall};
`
