import { useEffect } from 'react'

interface ScriptProps {
  url?: string
  where?: string
  type?: string
  children?: any
  insertOnTop?: boolean
}

const useScript = ({
  url,
  where = 'head',
  type = 'script',
  children,
  insertOnTop
}: ScriptProps): void => {
  useEffect(() => {
    const script = document.createElement(type)

    if (url) script.src = url
    script.async = true
    if (children) script.innerHTML = children.trim()

    if (insertOnTop) document[where].insertBefore(script, document[where].childNodes[0])
    else document[where].appendChild(script)

    return () => {
      document[where].removeChild(script)
    }
  }, [url, where, type, children, insertOnTop])
}

export default useScript
